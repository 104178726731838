import {Component} from '@angular/core';
import {JsonService} from "../../../services/json.service";
import {Router} from "@angular/router"
import {ToastrService} from "ngx-toastr"
import {AssetService} from "../../../services/asset.service"

@Component({
  selector: 'ns-admin-documents-review',
  templateUrl: './admin-documents-review.component.html'
})

export class AdminDocumentsReviewComponent {

  title = 'angularfront'

  public documents: Array<any>
  public ready: boolean

  constructor (private api: JsonService, private router: Router, private toastr: ToastrService, public assetService: AssetService) {
  }


  ngAfterViewInit(): void {
    this.getDocumentsReview()
  }

  getDocumentsReview() {
    this.api.getDocumentsReview().subscribe(content => {
      if (typeof content['documents'] !== 'undefined') {
        this.documents = content['documents']
        this.ready = true
      }

    });
  }

  deleteDocument(id: number, index: number) {
    if (!confirm('Supprimer le document ?')) {
      return
    }

    this.api.deleteDocument(id).subscribe(content => {
      if (typeof content['state'] !== 'undefined' && content['state'] === 'success') {
        this.documents.splice(index, 1)
        this.toastr.success('Document supprimé', 'Succès')
      } else {
        this.toastr.error(content['error'], 'Erreur');
      }

    });
  }

  reviewDocument(id: number, decision: string, index: number) {
    this.api.reviewDocuments(id, decision).subscribe(content => {
      if (typeof content['state'] !== 'undefined' && content['state'] === 'success') {
        this.documents.splice(index, 1)
        this.toastr.success('Votre décision à été prise en compte', 'Succès')
      } else {
        this.toastr.error(content['error'], 'Erreur');
      }

    });
  }
}
