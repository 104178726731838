import {Injectable} from '@angular/core';
import { JsonService } from './json.service';
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class AdnService {
  private adn: object = null;
  private loaded = false;

  constructor(private jsonService: JsonService) {}

  isLoaded(): boolean {
    return this.loaded;
  }

  getStoredAdn() {
    return this.adn;
  }

  getRefreshedAdn() {
    return this.jsonService.getAdn().pipe(
      map(adnResponse => {
        this.loaded = true;
        this.adn = adnResponse.adn;

        return adnResponse;
      })
    );
  }
}
