import { Component, OnInit, ViewChild } from '@angular/core';
import {JsonService} from "../services/json.service";
import {UserService} from "../services/user.service";
import {User} from "../models/user.model";
import {Marque} from "../models/marque.model";
import {MarqueService} from "../services/marque.service";

@Component({
  selector: 'ns-good-pratices',
  templateUrl: '../templates/backend/good_practices.component.html'
})
export class GoodPracticesComponent {
  public hasLoaded: boolean;
  public goodPractices: Array<any>;
  public user: User;
  public ready: boolean = false;
  public marque: Marque;

  constructor(private api: JsonService,  private userService : UserService, private marqueService: MarqueService) { }

  ngOnInit(): void {
    this.ready = false;
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response;
        this.ready = true;
      }
    });
  }

  ngAfterViewInit(): void {
    this.getData();
    this.userService.getUser().subscribe(response => {
      if (response instanceof User) {
        this.user = response;
        return this.user;
      }
    })
  }

  getData() {
    this.api.getGoodPractices().subscribe(data => {
      /**
       * TODO avoir les liens des images dans la réponse ainsi que le chemin
       */

      // let goodPracticesWithoutImage = data.goodPractices;
      // this.goodPractices = goodPracticesWithoutImage;
      this.goodPractices = [
        {image: 'tourisme-responsable.png', temps: 5, title: 'Le tourisme responsable'},
        {image: 'risques-chimiques.png', temps: 5, title: 'Les risques chimiques'},
        {image: 'numerique-responsable.png', temps: 5, title: 'Le numérique responsable'},
        {image: 'entreprise-biodiversite.png', temps: 5, title: 'L\'entreprise et la biodiversité'},
        {image: 'management-horizontal.png', temps: 5, title: 'Le management horizontal'},
        {image: 'devoir-vigilance.png', temps: 5, title: 'Le devoir de vigilance'},
        {image: 'economie-circulaire.png', temps: 5, title: 'L\'économie circulaire'},
        {image: 'biomimetisme.png', temps: 5, title: 'Le biomimétisme'},
        {image: 'bilan-carbone-entreprise.png', temps: 5, title: 'Le Bilan Carbone Entreprise'},
        {image: 'bienetre-travail.png', temps: 5, title: 'Le bien-être au travail'},
        {image: 'mode-responsable.png', temps: 5, title: 'La mode responsable'},
        {image: 'mobilite-durable.png', temps: 5, title: 'La mobilité durable'},
        {image: 'mixite-diversite-entreprise.png', temps: 5, title: 'La mixité et la diversité en entreprise'},
        {image: 'logistique-dernier-kilometre.png', temps: 5, title: 'La logistique du dernier kilomètre'},
        {image: 'alimentation-durable.png', temps: 5, title: 'L\'alimentation durable'},
        {image: 'gestion-eau.png', temps: 5, title: 'La gestion de l\'eau'},
        {image: 'formation-pro-continue.png', temps: 5, title: 'La formation professionnelle continue'},
        {image: 'finance-responsable.png', temps: 5, title: 'La finance responsable'}
    ];
      this.hasLoaded = this.goodPractices.length > 0;

    });
  }

  scrollToElement($element): void {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

}
