<ns-navbar></ns-navbar>
<div id="body-container">
  <ns-sidebar sidebarType="admin"></ns-sidebar>
  <div id="content-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8 welcome-dashboard" style="text-align: center">
          <form (ngSubmit)="postForm(memberForm.value)" #memberForm="ngForm">
            <div class="survey identification container">
              <div class="survey-header">
                <h1>
                  <i class="fa-solid fa-city me-3"></i>
                  {{ 'Nouvel Administrateur'| titlecase }}
                </h1>
                <div class="survey-header-bottom">
                  <p>Renseignez les informations de l'administrateur, Gen'éthic s'occupe ensuite de tout.</p>
                </div>
              </div>

              <div class="survey-content">
                <div class="form-group" *ngFor="let field of form">
                  <label [attr.for]="field.id" class="col-sm-2 col-form-label">{{field.label}}
                    <span *ngIf="field.id !== 'projectName'">&nbsp;*</span>
                  </label>
                  <div class="col-sm-10" [ngClass]="[field.type === 'checkbox' ? 'text-left' : '']">
                    <input *ngIf="field.type == 'text'" ngModel type="text" [id]="field.id"
                      [name]="'add_administrator_form['+field.id+']'" maxlength="255" class="form-control ap-input"
                      [placeholder]="field.label" autocomplete="off" spellcheck="false" role="combobox"
                      aria-autocomplete="both" aria-expanded="false" aria-owns="algolia-places-listbox-0" dir="auto"
                      style="position: relative; vertical-align: top;">

                    <select *ngIf="field.id == 'investmentFund'" ngModel [id]="field.id"
                      [name]="'add_administrator_form['+field.id+']'" class="form-control">
                      <option *ngFor="let choice of field.choices" [value]="choice.value" selected="selected">
                        {{choice.label}}</option>
                    </select>
                    <input *ngIf="field.type === 'checkbox'" ngModel="true" type="checkbox" [id]="field.id"
                      [name]="'add_administrator_form['+field.id+']'" style="height: 100%; width: 1.1em;"
                      ngbTooltip="Si cette option est décochée, l'utilisateur ne recevra pas l'email avec ses informations de connexion">
                  </div>
                </div>
              </div>

              <div class="survey-content-submit text-center">
                <button type="submit" class="btn btn-lg">{{ 'Valider' | uppercase }}</button>
              </div>
            </div>

          </form>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
  </div>
</div>