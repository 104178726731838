import { Component } from '@angular/core';
import { JsonService } from "../services/json.service";
import { ActivatedRoute, Router } from "@angular/router"
import { ToastrService } from "ngx-toastr"
import { Marque } from "../models/marque.model";
import { AssetService } from "../services/asset.service"
import { MarqueService } from "../services/marque.service"
import { environment } from "../../environments/environment"

@Component({
  selector: 'ns-registration',
  templateUrl: '../templates/security/register.component.html',
  styleUrls: ['../../assets/scss/custom/grandlyon-register.scss']
})

export class RegistrationComponent {


  title = 'angularfront'

  public lastUsername: string
  public errorMessage: string
  public form: object
  public email: string
  public processing: boolean;
  public ready: boolean
  public recaptchaToken: string = null
  public googleRecaptchaSiteKey: string
  public cguFilePath: string
  public showPassword1: boolean = false
  public showPassword2: boolean = false
  public hasCustomRegister: boolean = false;
  public marque: Marque;

  hasSpecialPasswordPolicy: boolean = false;
  pwdHasFocus: boolean = false;
  pwd2HasFocus: boolean = false;
  passwordMinLength: number = 6;
  passwordChecks = {
    minLength: false,
    specialChar: false,
    lowerChar: false,
    upperChar: false,
    number: false
  }
  isPwdValid: boolean = false;
  

  constructor (
    private api: JsonService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public assetService: AssetService,
    private marqueService: MarqueService
  ) {
  }


  ngOnInit() {
    this.email = this.route.snapshot.queryParamMap.get("email")
    this.route.queryParamMap.subscribe(queryParams => {
      this.email = queryParams.get("email")
    })
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.ready = false
      this.googleRecaptchaSiteKey = environment.googleRecaptchaSiteKey
      this.marqueService.getMarque().subscribe(response => {
        if (response instanceof Marque) {
          this.marque = response
          this.initCguFilePath();
          this.hasSpecialPasswordPolicy = ['lille', 'bordeaux', 'rennes', 'paca'].includes(this.marque.slug);
          this.passwordMinLength = this.marqueService.getMinPasswordLength(this.marque.slug);
          this.hasCustomRegister = ['grandlyon', 'lille'].includes(this.marque.slug);
          this.ready = true
          return this.marque
        }
      })
    })
  }

  togglePassword1() {
    this.showPassword1 = !this.showPassword1;
  }

  togglePassword2() {
    this.showPassword2 = !this.showPassword2;
  }

  initCguFilePath() {
    this.assetService.getCguFilePath().subscribe((filePath: string) => {
      this.cguFilePath = filePath;
    });
  }

  
  onPasswordChanged(formValues: any) {
    this.passwordChecks.minLength = formValues['registration_form[plainPassword][first]']?.length >= this.passwordMinLength;
    this.passwordChecks.specialChar = !this.hasSpecialPasswordPolicy || (/[^\w]/g).test(formValues['registration_form[plainPassword][first]']);
    this.passwordChecks.lowerChar = !this.hasSpecialPasswordPolicy || (/[a-z]/g).test(formValues['registration_form[plainPassword][first]']);
    this.passwordChecks.upperChar = !this.hasSpecialPasswordPolicy || (/[A-Z]/g).test(formValues['registration_form[plainPassword][first]']);
    this.passwordChecks.number = !this.hasSpecialPasswordPolicy || (/[0-9]/g).test(formValues['registration_form[plainPassword][first]']);
    this.isPwdValid = Object.values(this.passwordChecks).every(b => b)
  }

  onPwdFocus() {
    this.pwdHasFocus = true;
  }
  onPwd2Focus() {
    this.pwd2HasFocus = true;
  }

  postForm(form) {
    if (null == this.recaptchaToken) {
      this.toastr.error("Vous devez valider le captcha", 'Erreur')
      return
    }
    this.processing = true;
    this.api.postRegistration(form).subscribe(content => {
      if (typeof content['state'] !== 'undefined' && content['state'] === 'success') {
        this.toastr.success('Votre inscription à été prise en compte, vous pouvez maintenant vous connecter', 'Merci !')
        this.router.navigate(['/login'])
      } else {
        this.toastr.error(content['error'], 'Erreur');
      }
      this.processing = false;
    });
  }

  recaptchaSuccessResponse(response) {
    this.recaptchaToken = response
  }


  /**
  * Custom design 
  */
  getCustomLeftImg() {
    if (this.marque.slug === 'grandlyon') return '/assets/images/medias/register-grandlyon-right.png'
    if (this.marque.slug === 'lille') return '/assets/images/medias/register-grandlyon-right.png'
  }

  getCustomMainText() {
    if (this.marque.slug === 'grandlyon') return 'Bienvenue sur KELIMPACT, la plateforme de mesure et de pilotage de vos impacts territoriaux.'
    if (this.marque.slug === 'lille') return 'Bienvenue sur la plateforme d\'auto-évaluation RSE de la Métropole Européenne de Lille.'
  }

  getCustomStep1() {
    if (this.marque.slug === 'grandlyon') return '1. Mesurez votre performance sur chacun des impacts et leviers'
    if (this.marque.slug === 'lille') return '1. J’évalue mon impact environnemental et sociétal'
  }
  getCustomStep2() {
    if (this.marque.slug === 'grandlyon') return '2. Identifiez vos axes d\'amélioration'
    if (this.marque.slug === 'lille') return '2. J’identifie mes axes d’amélioration'
  }
  getCustomStep3() {
    if (this.marque.slug === 'grandlyon') return '3. Repérez rapidement des solutions concrètes et personnalisées'
    if (this.marque.slug === 'lille') return '3. Je bénéficie de préconisations concrètes'
  }
  getCustomStep4() {
    if (this.marque.slug === 'grandlyon') return '4. Suivez votre progression dans le temps'
    if (this.marque.slug === 'lille') return '4. Je suis mes progrès sur la durée'
  }
  getAccelerateLink() {
    if (this.marque.slug === 'grandlyon') return 'https://business.onlylyon.com/nos-services/transformer-son-entreprise-transition-ecologique#c11400'
    if (this.marque.slug === 'lille') return 'https://transitiondurable.lillemetropole.fr/passer-laction'
  }
  getMadeByCreditLink() {
    if (this.marque.slug === 'grandlyon') return 'https://www.grandlyon.com/'
    if (this.marque.slug === 'lille') return 'https://www.lillemetropole.fr/'
  }
  getMadeByCreditsTitle() {
    if (this.marque.slug === 'grandlyon') return 'Métropole de Lyon'
    if (this.marque.slug === 'lille') return 'Métropole Européenne de Lille'
  }
  getFooterAccelerateLinkText() {
    if (this.marque.slug === 'grandlyon') return 'Nos solutions pour accélérer la transformation des entreprises'
    if (this.marque.slug === 'lille') return 'Nos solutions pour accélérer la transition durable des entreprises'
  }
  getPlusIconPath() {
    if (this.marque.slug === 'grandlyon') return '/assets/images/medias/grandlyon-img-plus.png'
    if (this.marque.slug === 'lille') return '/assets/images/medias/lille-img-plus.png'
  }
}
