import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Marque } from 'src/app/models/marque.model';
import { User } from 'src/app/models/user.model';

export enum NetworkDeleteResultType {
  User = 'USER',
  Questionnaire = 'QUESTIONNAIRE'
}

@Component({
  selector: 'ns-network-delete-modal',
  templateUrl: '../../templates/backend/network/network-delete-modal.component.html'
})
export class NetworkDeleteModalComponent {
  NetworkDeleteResultType = NetworkDeleteResultType;
  @Input() marque: Marque;
  @Input() user: User;
  @Input() questionnaireResponseId: number;
  @Input() isReady: boolean;
  selectedOption: string = NetworkDeleteResultType.User;
  private _canDeleteProject: boolean;
  get canDeleteProject() {
    return this._canDeleteProject;
  }
  set canDeleteProject(canDeleteProject: boolean) {
    this._canDeleteProject = canDeleteProject;
    this.selectedOption = canDeleteProject ? NetworkDeleteResultType.Questionnaire : NetworkDeleteResultType.User;
  }


  constructor(
    public activeModal: NgbActiveModal
  ) { }


  delete() {
    this.activeModal.close(this.selectedOption)
  }

  close() {
    this.activeModal.close();
  }
}
