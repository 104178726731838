import { Component, Input } from '@angular/core';
import { Marque } from '../../../../models/marque.model';
import { EnterpriseDirectory } from 'src/app/models/enterprise_directory.model';

@Component({
  selector: 'ns-enterprise-directory-item',
  templateUrl: './enterprise-directory-item.component.html',
  styleUrls: ['./enterprise-directory-item.component.scss']
})
export class EnterpriseDirectoryItemComponent {
  @Input() marque: Marque;
  @Input() enterpriseDirectory: EnterpriseDirectory;

  isHovered: boolean = false;

  constructor() { }

  onClick() {
    window.open(this.enterpriseDirectory.link, '_blank');
  }

  mouseEnter() {
    this.isHovered = true;
  }

  mouseLeave() {
    this.isHovered = false;
  }
  
}
