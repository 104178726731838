<nav class="navbar navbar-expand-lg navbar-light bg-light static-top bg-white rounded">
    <a class="navbar-brand col-md-4" [routerLink]="[user?.isReseau() ? '/network' : '/']">
      <img *ngIf="ready" [src]="assetService.getLogo(marque.slug)" class="logo" alt="logo">
    </a>
    <div *ngIf="ready" class="container-nav">
        <div [ngClass]="[marque.slug, 'container-second', 'navbar-actions']" (click)="myDrop.toggle()">
            <div *ngIf="marque.slug !== 'grandlyon'" class="rounded-circle display-inline">
                <i class="fa-regular fa-user"></i>
            </div>
            <div *ngIf="marque.slug === 'grandlyon'"> <img src="/assets/images/medias/logo-grandlyon-left.png" class="logo" alt="logo" style="min-height:40px;"></div>
            <div class="content-menu-lg-menu" #myDrop="ngbDropdown" ngbDropdown>
                <span class="chevron_menu" id="clik_menu">
                    <i class="fas fa-chevron-down fa-xs"></i>
                </span>
                <div ngbDropdownMenu class="menu-lg-menu p-3 mb-5 bg-white bg-white" aria-labelledby="navbarDropdown" id="click-menu">
                        <a ngbDropdownItem *ngIf="!user?.isReseau() && !user?.isAdmin()" class="dropdown-item border-bottom" routerLink="/questionnaire">
                          Mon questionnaire
                        </a>

                        <a ngbDropdownItem *ngIf="user?.isReseau()" class="dropdown-item border-bottom" href="/network">Mon réseau d'entreprise</a>

                    <a ngbDropdownItem class="dropdown-item border-bottom" [href]="privacyPolicyFilePath" target="_blank">Voir la politique de confidentialité</a>
                    <a ngbDropdownItem *ngIf="!user?.isReseau() && !user?.isAdmin()" class="dropdown-item border-bottom" routerLink="/parameters">Paramètres</a>
                    <a *ngIf="marque?.slug === 'genethic'" ngbDropdownItem class="dropdown-item border-bottom" target="_blank" href="https://gen-ethic.com">Retour vers site</a>
                    <a ngbDropdownItem class="dropdown-item" routerLink="/logout">Déconnexion</a>
                </div>
            </div>
        </div>
        <button class="navbar-toggler ms-3" type="button" (click)="toggleMenu()" aria-expanded="true" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>
</nav>
