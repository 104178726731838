import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {JsonService} from "../../services/json.service";
import {Router} from "@angular/router"
import {ToastrService} from "ngx-toastr"
import {IdentificationField} from "../../models/identificationField";

@Component({
  templateUrl: '../../templates/backend/network/new_member_reseau.component.html'
})

export class NetworkNewMemberComponent {

  title = 'angularfront'

  public form: Array<IdentificationField>
  public ready: boolean = false

  constructor (private api: JsonService, private router: Router, private toastr: ToastrService) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getForm()
  })
  }

  getForm() {
    this.api.getNetworkNewMemberForm().subscribe(content => {
      this.form = content['form']

      this.ready = true
    })

  }
  postForm(form) {

    // let investmentFund = form['add_membre_reseau_form[investmentFund]'];
    //
    // if (typeof investmentFund !== 'undefined') {
    //   delete form['add_membre_reseau_form[investmentFund]']
    //   for (let i = 0; i <  investmentFund.length-1; i++) {
    //     let value = investmentFund[i]
    //     form['add_membre_reseau_form[investmentFund]['+value+']'] = value
    //   }
    // }

    this.api.postNetworkNewMember(form).subscribe(content => {
      if (typeof content['redirect_to'] !== 'undefined') {
          this.toastr.success('Membre ajouté avec succès', 'Merci !')
          this.router.navigate(['/'+content['redirect_to']])
      } else {
        this.toastr.error(content['error'], '');
      }

    });
  }
}
