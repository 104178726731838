import { Routes } from '@angular/router';
import { QuestionnaireComponent } from './components/questionnaire.component';
import { UpgradesComponent } from './components/upgrades.component';
import { HistoryComponent } from 'src/app/components/history.component';
import { HomeComponent } from './components/home.component';
import { LoginComponent } from './components/login.component';
import { AdnComponent } from './components/adn.component';
import { GoodPracticesComponent } from './components/good-practices.component';
import { LogoutComponent } from './components/logout.component';
import { OffersComponent } from './components/offers.component';
import { OfferComponent } from './components/offer.component';
import { IdentificationComponent } from './components/identification.component';
import { DocumentsComponent } from './components/documents.component';
import { NetworkHomeComponent } from './components/network/network-home.component';
import { AdminHomeComponent } from './components/admin/admin-home/admin-home.component';
import { AdminDocumentsReviewComponent } from './components/admin/admin-documents-review/admin-documents-review.component';
import { AdminModifyLogoComponent } from 'src/app/components/admin/admin-modify-logo/admin-modify-logo.component';
import { RegistrationComponent } from 'src/app/components/registration.component';
import { ParametersComponent } from './components/parameters.component';
import { PasswordForgottenComponent } from 'src/app/components/password-forgotten.component';
import { PasswordForgottenDefineComponent } from 'src/app/components/password-forgotten-define.component';
import { NetworkNewMemberComponent } from './components/network/network-new-member.component';
import { NetworkDocumentsComponent } from './components/network/network-documents.component';
import { AdnNetworkComponent } from './components/network/adn-network.component';
import { AdminTestMappingComponent } from './components/admin/admin-test-mapping/admin-test-mapping.component';
import { AssistanceSystemsComponent } from './components/assistance-systems.component';
import { AdminExportComponent } from "./components/admin/admin-export/admin-export.component";
import { AdminAdnComponent } from './components/admin/admin-adn/admin-adn.component';
import { AdminAssistanceSystemsComponent } from './components/admin/admin-assistance-systems/admin-assistance-systems.component';
import { NetworkMemberNewProjectComponent } from './components/network/network-member-new-project.component';
import { JwtGuard } from './guards/jwt.guard';
import { QuestionnaireGuard } from './guards/questionnaire.guard';
import { RegisterGuard } from './guards/register.guard';
import { IsSuperAdminGuard } from './guards/is-super-admin.guard';
import { SuperAdminUsersComponent } from './components/admin/super-admin-users/super-admin-users.component';
import { SuperAdminCreateAdminComponent } from './components/admin/super-admin-create-admin/super-admin-create-admin.component';
import { EnterpriseDirectoryComponent } from './components/enterprise/enterprise-directory/enterprise-directory.component';
import { EnterpriseCreateDirectoryComponent } from './components/enterprise/enterprise-create-directory/enterprise-create-directory.component';
import { AdminEnterpriseDirectoryComponent } from './components/admin/admin-enterprise-directory/admin-enterprise-directory.component';
import { AdminStatsComponent } from './components/admin/admin-stats/admin-stats.component';

export const ROUTES: Routes = [
  // Public routes
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'register', canActivate: [RegisterGuard], component: RegistrationComponent },
  { path: 'password-forgotten', component: PasswordForgottenComponent },
  { path: 'password-forgotten/:hash', component: PasswordForgottenDefineComponent },
  // Protected routes
  {
    path: '', canActivate: [JwtGuard], children: [
      { path: '', component: HomeComponent },
      { path: 'adn', component: AdnComponent },
      { path: 'identification', component: IdentificationComponent },
      { path: 'documents', component: DocumentsComponent },
      { path: 'good-practices', component: GoodPracticesComponent },
      { path: 'questionnaire', canActivate: [QuestionnaireGuard], component: QuestionnaireComponent },
      { path: 'upgrades', component: UpgradesComponent },
      { path: 'history', component: HistoryComponent },
      { path: 'offers', component: OffersComponent },
      { path: 'offer/:name', component: OfferComponent },
      { path: 'assistance-systems', component: AssistanceSystemsComponent },
      { path: 'directory', component: EnterpriseDirectoryComponent },
      { path: 'directory/inscription', component: EnterpriseCreateDirectoryComponent },
      { path: 'parameters', component: ParametersComponent },
      { path: 'network', component: NetworkHomeComponent },
      { path: 'network/network-adn/:membreReseauId/:questionnaireResponseId', component: AdnNetworkComponent },
      { path: 'network/new-member', component: NetworkNewMemberComponent },
      { path: 'network/member/:membreReseauId/new-project', component: NetworkMemberNewProjectComponent },
      { path: 'network/documents', component: NetworkDocumentsComponent },
      { path: 'admin', component: AdminHomeComponent },
      { path: 'admin/documents-review', component: AdminDocumentsReviewComponent },
      { path: 'admin/modify-logo', component: AdminModifyLogoComponent },
      { path: 'admin/test-mapping', component: AdminTestMappingComponent },
      { path: 'admin/export', component: AdminExportComponent },
      { path: 'admin/adn/:userId/:questionnaireResponseId', component: AdminAdnComponent },
      { path: 'admin/assistance-systems', component: AdminAssistanceSystemsComponent },
      { path: 'admin/users', component: SuperAdminUsersComponent, canActivate: [IsSuperAdminGuard] },
      { path: 'admin/new-administrator', component: SuperAdminCreateAdminComponent, canActivate: [IsSuperAdminGuard] },
      { path: 'admin/directory', component: AdminEnterpriseDirectoryComponent, canActivate: [IsSuperAdminGuard] },
      { path: 'admin/statistics', component: AdminStatsComponent },
    ]
  }
];
