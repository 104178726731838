import { AfterViewInit, Component } from '@angular/core';
import { JsonService } from '../../../services/json.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MarqueService } from '../../../services/marque.service';
import { Marque } from '../../../models/marque.model';
import { Select2Data } from 'ng-select2-component';
import { IdentificationField } from '../../../models/identificationField';
import { UserService } from '../../../services/user.service';
import { IdentificationStateService } from '../../../services/identificationstate.service';
import { InseeService } from '../../../services/insee.service';
import { finalize, take } from 'rxjs/operators'

@Component({
  selector: 'ns-identification',
  templateUrl: './identification.component.html'
})

export class IdentificationComponent implements AfterViewInit {
  public lastUsername: string;
  public errorMessage: string;
  public form: Array<IdentificationField>;
  public ready: boolean = false;
  public marque: Marque;
  public dropdownList = [];
  public selectedItems = [];
  public dropdownSettings = {};
  public formStructure: Array<any>;
  public showCompanyNameInput: boolean;
  public expectedSiretLength = '14';
  public userHasOptionalSiret: 'true' | 'false' = 'false';
  public isSiretOptional: boolean = false;

  public data: Select2Data = [
    { value: 'iso_26000', label: 'ISO 26000' },
    { value: 'bcorp', label: 'B Corp' },
    { value: 'label_lucie', label: 'Label Lucie' },
    { value: 'ecovadis', label: 'ECOVADIS' },
    { value: 'sd21000', label: 'SD 21000' },
    { value: 'ecocert_26000', label: 'ECOCERT 26000' },
    { value: 'sa_8000', label: 'SA 8000' },
    { value: 'adhesion_global_compact', label: 'Adhésion au Global Compact' },
    { value: 'label_rfar', label: 'Label RFAR (Relations fournisseurs et Achats responsables)' },
  ];



  testData: Select2Data = [
    {
      label: "Pas d'accompagnement",
      options: [
        {
          value: "Aucun",
          label: "Aucun"
        },
      ]
    },
    {
      label: "Chambre de commerce et d’industrie (CCI)",
      options: [
        {
          value: "CCI des Alpes de Haute-Provence",
          label: "CCI des Alpes de Haute-Provence"
        },
        {
          value: "CCI des Hautes-Alpes",
          label: "CCI des Hautes-Alpes",
        },
        {
          value: "CCI Nice Côte d'Azur",
          label: "CCI Nice Côte d'Azur",
        },
        {
          value: "CCI Aix-Marseille Provence",
          label: "CCI Aix-Marseille Provence",
        },
        {
          value: "CCI Pays d'Arles",
          label: "CCI Pays d'Arles",
        },
        {
          value: "CCI du Var",
          label: "CCI du Var",
        },
        {
          value: "CCI du Vaucluse",
          label: "CCI du Vaucluse",
        },
      ]
    },
    {
      label: "Réseau Initiative Sud",
      options: [
        {
          value: "INITIATIVE ALPES PROVENCE",
          label: "INITIATIVE ALPES PROVENCE"
        },
        {
          value: "INITIATIVE MENTON RIVIERA",
          label: "INITIATIVE MENTON RIVIERA"
        },
        {
          value: "INITIATIVE TERRES D'AZUR",
          label: "INITIATIVE TERRES D'AZUR"
        },
        {
          value: "INITIATIVE AGGLOMERATION SOPHIA ANTIPOLIS",
          label: "INITIATIVE AGGLOMERATION SOPHIA ANTIPOLIS"
        },
        {
          value: "INITIATIVE NICE COTE D'AZUR",
          label: "INITIATIVE NICE COTE D'AZUR"
        },
        {
          value: "INITIATIVE MARSEILLE METROPOLE",
          label: "INITIATIVE MARSEILLE METROPOLE"
        },
        {
          value: "INITIATIVE PAYS D'AIX",
          label: "INITIATIVE PAYS D'AIX"
        },
        {
          value: "INITIATIVE PAYS SALONAIS",
          label: "INITIATIVE PAYS SALONAIS"
        },
        {
          value: "INITIATIVE PAYS D'ARLES",
          label: "INITIATIVE PAYS D'ARLES"
        },
        {
          value: "INITIATIVE PAYS D'AUBAGNE LA CIOTAT",
          label: "INITIATIVE PAYS D'AUBAGNE LA CIOTAT"
        },
        {
          value: "INITIATIVE OUEST PROVENCE",
          label: "INITIATIVE OUEST PROVENCE"
        },
        {
          value: "INITIATIVE PAYS DE MARTIGUES COTE BLEUE",
          label: "INITIATIVE PAYS DE MARTIGUES COTE BLEUE"
        },
        {
          value: "INITIATIVE VAR",
          label: "INITIATIVE VAR"
        },
        {
          value: "INITIATIVE TERRES DE VAUCLUSE",
          label: "INITIATIVE TERRES DE VAUCLUSE"
        },
        {
          value: "INITIATIVE VENTOUX",
          label: "INITIATIVE VENTOUX"
        },
        {
          value: "INITIATIVE SEUIL DE PROVENCE ARDECHE MERIDIONALE",
          label: "INITIATIVE SEUIL DE PROVENCE ARDECHE MERIDIONALE"
        },
      ]
    },
    {
      label: "Couveuse",
      options: [
        {
          value: "ACTIVIE",
          label: "ACTIVIE"
        },
        {
          value: "COUVEUSE INNOVATION",
          label: "COUVEUSE INNOVATION"
        },
        {
          value: "COSENS",
          label: "COSENS"
        },
        {
          value: "CREACTIVE 06",
          label: "CREACTIVE 06"
        },
        {
          value: "INTERFACE",
          label: "INTERFACE"
        },
        {
          value: "PCE",
          label: "PCE"
        },
      ]
    },
    {
      label: "Autres Réseaux",
      options: [
        {
          value: "ADIE",
          label: "ADIE"
        },
        {
          value: "BGE",
          label: "BGE"
        },
        {
          value: "Chambre de métiers et de l’artisanat (CMA)",
          label: "Chambre de métiers et de l’artisanat (CMA)"
        },
        {
          value: "Chambre régionale de l’économie sociale et solidaire (CRESS)",
          label: "Chambre régionale de l’économie sociale et solidaire (CRESS)"
        },
        {
          value: "France active",
          label: "France active"
        },
        {
          value: "HEC Paris",
          label: "HEC Paris"
        },
        {
          value: "Institut régional des chefs d’entreprises (IRCE)",
          label: "Institut régional des chefs d’entreprises (IRCE)"
        },
        {
          value: "Les Premières Sud",
          label: "Les Premières Sud"
        },
        {
          value: "Second Souffle",
          label: "Second Souffle"
        },{
          value: "Union régionale des SCOP et SCIC (URSCOP)rovence-Alpes-Côte d’Azur",
          label: "Union régionale des SCOP et SCIC (URSCOP)rovence-Alpes-Côte d’Azur"
        },
        {
          value: "60 000 Rebonds",
          label: "60 000 Rebonds"
        },
      ]
    },
  ]

  constructor(
    private api: JsonService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private marqueService: MarqueService,
    private userService: UserService,
    private identificationStateService: IdentificationStateService,
    private inseeService: InseeService
  ) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.showCompanyNameInput = this.route.snapshot.paramMap.get('editmode') === 'true';
      this.getForm();

      this.dropdownList = [];

      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Tout sélectionner',
        unSelectAllText: 'Tout déselectionner',
        itemsShowLimit: 5,
        allowSearchFilter: false
      };
    })
  }

  getForm() {
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response
      }

      this.isSiretOptional = [
        'paca',
        'lille'
      ].includes(this.marque.slug)

      this.api.getIdentification().subscribe(content => {
        if (typeof content['form'] !== 'undefined') {
          content = this.hideSomeActivitySectors(content);
          this.form = content['form'];
          this.ready = true
        } else {
          this.errorMessage = content['errorMessage'];
        }
      });
    });
  }

  /** Remove some sector activity (since 2024-01-03) */
  private hideSomeActivitySectors(identificationFormResponse: any): any {
    for (let identificationField of identificationFormResponse['form'] as IdentificationField[]) {
      if (identificationField.id === 'businessSegment') {
        identificationField.choices = identificationField.choices.filter(c => ![
          'tic',
          'electronique',
        ].includes(c.value))
      }
    }
    return identificationFormResponse;
  }

  postForm(form) {
    const certification = form.value['identification_form[certificationsRse]'];

    if (typeof certification !== 'undefined') {
      delete form.value['identification_form[certificationsRse]']
      for (let i = 0; i < certification.length - 1; i++) {
        const value = certification[i];
        form.value['identification_form[certificationsRse][' + value + ']'] = value;
      }
    }


    this.api.postIdentification(form.value).subscribe(content => {
      if (typeof content['redirect_to'] !== 'undefined') {
        this.userService.refreshUser().subscribe(user => {
          this.identificationStateService.isIdentificationCompleted = true;
          this.toastr.success('Vous pouvez maintenant remplir votre questionnaire', 'Merci !');
          this.router.navigate(['/' + content['redirect_to']]);
        });
      } else {
        this.toastr.error(content['error'], '');
      }
    });
  }

  onItemSelect(item: object) {
    this.selectedItems.push(item['item_id']);
  }

  onItemUnselect(item: object) {
    for (let i = 0; i < this.selectedItems.length; i++) {
      if (this.selectedItems[i] === item['item_id']) {
        this.selectedItems.splice(i, 1);
      }
    }
  }

  formFieldHasError(identificationForm, fieldId): boolean {
    if (`identification_form[${fieldId}]` in identificationForm?.controls) {
      return identificationForm?.controls[`identification_form[${fieldId}]`]?.errors !== null;
    }
    return false;
  }

  isSiren() {
    return this.expectedSiretLength === "9";
  }

  getSiretErrorMessage(identificationForm): string | undefined {
    const isSiren = this.isSiren();
    if ('identification_form[siret]' in identificationForm?.controls) {
      let formControl = identificationForm?.controls['identification_form[siret]'];
      if (formControl?.errors?.required === true) {
        return isSiren ?
          '* Le SIREN doit être renseigné' :
          '* Le SIRET doit être renseigné';
      } else if (formControl?.errors?.minlength) {
        return isSiren ?
          '* Le SIREN doit contenir 9 charactères' :
          '* Le SIRET doit contenir 14 charactères';
      }
    }
  }


  /** Request company info from SIRET/SIREN */
  onSiretKeyup(identificationForm) {
    if ('identification_form[siret]' in identificationForm?.controls) {
      let formControl = identificationForm?.controls['identification_form[siret]'];
      const siretValue = formControl?.value
      if (siretValue.length !== +this.expectedSiretLength) {
        return;
      }

      if (this.isSiren()) {
        this.getSirenAndPatchForm(siretValue, identificationForm);
      } else {
        this.getSiretAndPatchForm(siretValue, identificationForm);
      }
    }
  }


  private _isLoadingSiren = false;
  getSirenAndPatchForm(siren: string, identificationForm) {
    if (this._isLoadingSiren) {
      return;
    }
    this._isLoadingSiren = true;
    this.inseeService.searchSiren(siren).pipe(
      take(1),
      finalize(() => { this._isLoadingSiren = false; })
    ).subscribe((sirenResponse: any) => {
      if (sirenResponse?.header?.total === 1) {
        this.toastr.info(`Le SIREN a été validé`)
        const legalUnit = sirenResponse?.unitesLegales?.[0];
        this.patchValues(
          identificationForm,
          legalUnit
        );
      } else if (sirenResponse?.header?.total) {
        this.toastr.info(`${sirenResponse.header.total} établissements ont été trouvés pour ce SIREN`)
      } else {
        this.toastr.warning('Aucun établissement n\'a été trouvé pour ce SIREN')
      }
    });
  }


  private _isLoadingSiret = false;
  getSiretAndPatchForm(siret: string, identificationForm) {
    if (this._isLoadingSiret) {
      return;
    }
    this._isLoadingSiret = true;
    this.inseeService.searchSiret(siret).pipe(
      take(1),
      finalize(() => { this._isLoadingSiret = false; })
    ).subscribe((siretResponse: any) => {
      if (siretResponse?.header?.total === 1) {
        this.toastr.info(`Le SIRET a été validé`)
        const legalUnit = siretResponse?.etablissements?.[0]?.uniteLegale;
        this.patchValues(
          identificationForm,
          legalUnit,
          siretResponse?.etablissements?.[0]
        );
      } else if (siretResponse?.header?.total) {
        this.toastr.info(`${siretResponse.header.total} établissements ont été trouvés pour ce SIRET`)
      } else {
        this.toastr.warning('Aucun établissement n\'a été trouvé pour ce SIRET')
      }
    });
  }


  patchValues(
    identificationForm,
    legalUnit,
    establishment?
  ) {
    identificationForm?.form?.patchValue({
      'identification_form[companyName]': this.inseeService.getCompanyNameFromUniteLegale(legalUnit),
      'identification_form[nbEmployee]': this.inseeService.getNbEmployeeFromUniteLegale(legalUnit),
      'identification_form[status]': this.inseeService.getStatusFromUniteLegale(legalUnit),
    })
    if (this.hasAddress(identificationForm) && establishment) {
      identificationForm?.form?.patchValue({
        'identification_form[addressStreet]': this.inseeService.getAddressStreetFromEstablishment(establishment),
        'identification_form[addressPostalCode]': this.inseeService.getAddressPostalCodeFromEstablishment(establishment),
        'identification_form[addressCity]': this.inseeService.getAddressCityFromEstablishment(establishment),
      })
    }
  }

  hasSiretOrSirenFeature(identificationForm): boolean {
    return !!('identification_form[siret]' in identificationForm?.controls);
  }

  isOptionalSiretSelected(): boolean {
    return this.isSiretOptional ? this.userHasOptionalSiret === 'true' : true;
  }

  hasAddress(identificationForm): boolean {
    return !!('identification_form[addressPostalCode]' in identificationForm?.controls);
  }
}
