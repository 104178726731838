import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {JsonService} from "../../../services/json.service"
import {UserService} from "../../../services/user.service"
import {User} from "../../../models/user.model"
import { MarqueService } from '../../../services/marque.service';
import { Marque } from '../../../models/marque.model';
import { EnterpriseDirectory } from 'src/app/models/enterprise_directory.model';
import { Subscription, forkJoin, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ns-enterprise-directory',
  templateUrl: './enterprise-directory.component.html',
  styleUrls: ['./enterprise-directory.component.scss']
})
export class EnterpriseDirectoryComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly MAX_DESCRIPTION_LENGTH = 260;
  readonly MAX_SHORT_DESCRIPTION_LENGTH = 140;

  public user: User;
  public ready: boolean = false;
  public isReferenced: boolean = false;
  public marque: Marque;
  public enterpriseDirectories: EnterpriseDirectory[] = [];
  public filteredData: EnterpriseDirectory[] = [];

  public searchFormControl = new UntypedFormControl();
  private searchSubscription: Subscription;
  public currentOrderIdentifier = 'id';
  public currentOrderWording = 'Date';

  public currentBusinessSegment: string = null;
  public businessSegments: string[] = [];

  constructor(
    private api: JsonService,
    private userService: UserService,
    private marqueService: MarqueService,
    
  ) { }

  ngOnInit() {
    forkJoin({
      marque: this.marqueService.getMarque(),
      user: this.userService.getUser(),
      enterpriseDirectories: this.api.getEnterpriseDirectories(this.currentOrderIdentifier)
    }).subscribe((res) => {
      this.marque = res.marque as Marque;
      this.user = res.user as User;
      this.enterpriseDirectories = res.enterpriseDirectories;
      this.isReferenced = this.enterpriseDirectories.some(ed => ed.user?.id === this.user.id);
      this.businessSegments = Array.from(new Set(this.enterpriseDirectories.map(ed => ed.user.businessSegment)));
      this.updateDisplayedData();
      this.ready = true
    })
  }

  ngAfterViewInit(): void {
    this.searchSubscription = this.searchFormControl.valueChanges
      .pipe(debounceTime(600))
      .subscribe(searchTerm => {
        this.updateDisplayedData();
        if (searchTerm) {
          this.filteredData = this.filteredData.filter((ed: EnterpriseDirectory) => {
            return ed.user.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
              ed.description.toLowerCase().includes(searchTerm.toLowerCase());
          })
        }
      });
  }

  private truncate(text: string, maxLength: number): string {
    let result;
    if (text.length > maxLength) {
      let short = text.substring(0, maxLength);
      result = short.substring(0, short.lastIndexOf(' ')) + '...'
    }
    return result || text;
  }

  private updateData() {
    this.api.getEnterpriseDirectories(this.currentOrderIdentifier).subscribe(res => {
      this.enterpriseDirectories = res;
      this.updateDisplayedData();
    });
  }

  private updateDisplayedData() {
    const filtered = [];
    this.enterpriseDirectories.filter(ed => {
      const businessSegmentMatch = this.currentBusinessSegment ? ed.user.businessSegment === this.currentBusinessSegment : true;
      return businessSegmentMatch;
    }).forEach(ed => {
      filtered.push({
        ...ed,
        shortDescription: this.truncate(ed.description, this.MAX_SHORT_DESCRIPTION_LENGTH),
        description: this.truncate(ed.description, this.MAX_DESCRIPTION_LENGTH),
        logoUrl: `${environment.apiUrl}/${ed.logoUrl}`,
      })
    });
    this.filteredData = filtered;
  }

  updateOrdering(identifier, wording) {
    this.currentOrderIdentifier = identifier;
    this.currentOrderWording = wording;
    this.searchFormControl.setValue('');

    this.updateData();
  }

  updateBusinessSegmentFilter(businessSegment) {
    this.currentBusinessSegment = businessSegment;
    this.searchFormControl.setValue('');

    this.updateDisplayedData();
  }


  ngOnDestroy(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe()
    }
  }
}
