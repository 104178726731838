<!--{% extends 'backend/backend_base.html.twig' %}

{% block title %}Mon tableau de bord{% endblock %}

{% block body %}-->
<ns-navbar></ns-navbar>
<div *ngIf="ready" id="body-container">
  <ns-sidebar></ns-sidebar>
    <div id="content-wrapper">
        <div class="container-sm welcome">
                <div [ngClass]="[marque.slug, 'welcome-dashboard']">
                  <div [innerHTML]="(introText[marque?.slug] || '') | keepHtml"></div>
                    <a [ngClass]="[marque.slug, 'typeform-share', 'button', 'btn', 'btn-primary', 'btn-lg']" routerLink="/questionnaire" style="border: none ; color: white; text-decoration: none;">
                     <span>
                       MON QUESTIONNAIRE
                     </span>
                    </a>
                </div>
        </div>
    </div>
</div>

<ng-template #firstLoginModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ popinText[marque?.slug].title }}</h4>
    <button type="button" class="btn-close" style="outline: none;box-shadow: none;" aria-label="Close" (click)="modal.dismiss('cancel')"></button>
  </div>
  <div class="modal-body">
    <div [innerHTML]="popinText[marque?.slug].content | keepHtml"></div>
  </div>
</ng-template>
