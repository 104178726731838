<div class="edi-container" (click)="onClick()" (mouseenter) ="mouseEnter()"  (mouseleave) ="mouseLeave()">

  <div *ngIf="!enterpriseDirectory.isValidated" class="edi-status-badge" ngbTooltip="En attente de validation"><i class="far fa-clock"></i></div>

  <div class="edi-logo-container">
    <img [src]="enterpriseDirectory.logoUrl" alt="logo" class="edi-logo">
  </div>

  <hr class="line-spacer">

  <div class="edi-info-container">
    <div class="edi-info-item">
      <span class="edi-info-label"><i class="far fa-building edi-info-icon" style="margin-left:1px; margin-right:9px"></i> Nom: </span>
      <span class="edi-info-value">{{enterpriseDirectory.user.companyName | titlecase}}</span>
    </div>
    <div class="edi-info-item">
      <span class="edi-info-label"><i class="fas fa-chart-pie edi-info-icon" style="margin-right:8px"></i> Secteur: </span>
      <span class="edi-info-value">{{enterpriseDirectory.user.businessSegment}}</span>
    </div>
    <div class="edi-info-item">
      <span class="edi-info-label"><i class="fas fa-users edi-info-icon"></i> Effectif: </span>
      <span class="edi-info-value">{{enterpriseDirectory.user.nbEmployee}}</span>
    </div>
  </div>

  <hr class="line-spacer">

  <div class="edi-description-container">
    <span class="edi-description-text">{{ isHovered ? enterpriseDirectory.description : enterpriseDirectory.shortDescription}}</span>

    <div>
      <div class="edi-info-container" style="margin-top: 10px;">
        <div class="edi-info-item">
          <span class="edi-info-label"><i class="far fa-address-card edi-info-icon"></i> Contact: </span>
          <span class="edi-info-value">{{enterpriseDirectory.contact}}</span>
        </div>
      </div>
    </div>
  </div>

</div>
