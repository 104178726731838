import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { JsonService } from './services/json.service';
import { EscapeHtmlPipe } from './pipes/keep-html.pipe';
import { FormsModule } from '@angular/forms';
import {DefaultUrlSerializer, RouterModule} from '@angular/router';
import { ROUTES} from './app.routes';
import {
  NgbAccordionModule,
  NgbDropdownModule,
  NgbPaginationModule,
  NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgChartsModule } from 'ng2-charts';
import { TreeModule } from '@ali-hm/angular-tree-component';

import { AssistanceSystemsComponent } from './components/assistance-systems.component';
import { QuestionnaireComponent } from './components/questionnaire.component';
import { QuestionnaireHelperModalComponent } from './components/questionnaireHelperModal.component';
import { NetworkDeleteModalComponent } from './components/network/network-delete-modal.component';
import { NetworkNewProjectModalComponent } from './components/network/network-newproject-modal.component';
import { UpgradesComponent } from './components/upgrades.component';
import { HistoryComponent} from 'src/app/components/history.component';
import {AppComponent} from './components/app.component';
import {SidebarComponent} from './components/sidebar/sidebar.component';
import {HomeComponent} from './components/home.component';
import {NavbarComponent} from './components/navbar.component';
import {LoginComponent} from './components/login.component';
import {JwtInterceptor} from './interceptors/jwtinterceptor';
import {AdnComponent} from './components/adn.component';
import {GoodPracticesComponent} from './components/good-practices.component';
import {SlugifyPipe} from './pipes/slugify.pipe';
import {LogoutComponent} from './components/logout.component';
import {OffersComponent} from './components/offers.component';
import {OffersBlocksComponent} from './components/offers-blocks.component';
import {OfferComponent} from './components/offer.component';
import {IdentificationComponent} from './components/identification.component';
import {DocumentsComponent} from './components/documents.component';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NetworkHomeComponent} from './components/network/network-home.component';
import {NetworkDocumentsComponent} from './components/network/network-documents.component';
import {ParametersComponent} from './components/parameters.component';
import {AssetService} from './services/asset.service';
import {AdminHomeComponent} from './components/admin/admin-home/admin-home.component';
import {AdminAssistanceSystemsComponent} from './components/admin/admin-assistance-systems/admin-assistance-systems.component';
import {AdminDocumentsReviewComponent} from './components/admin/admin-documents-review/admin-documents-review.component';
import {AdminModifyLogoComponent} from 'src/app/components/admin/admin-modify-logo/admin-modify-logo.component';
import {RegistrationComponent} from 'src/app/components/registration.component';
import {PasswordForgottenComponent} from 'src/app/components/password-forgotten.component';
import {PasswordForgottenDefineComponent} from 'src/app/components/password-forgotten-define.component';
import {SpinnerComponent} from 'src/app/components/spinner.component';
import {NetworkNewMemberComponent} from './components/network/network-new-member.component';
import {AdnNetworkComponent} from './components/network/adn-network.component';
import {MarqueService} from './services/marque.service';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {Select2Module} from 'ng-select2-component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import {AdminTestMappingComponent} from './components/admin/admin-test-mapping/admin-test-mapping.component';
import {AdminExportComponent} from "./components/admin/admin-export/admin-export.component"
import {AdminAdnComponent} from './components/admin/admin-adn/admin-adn.component';
import {SupportBubbleComponent} from './components/support-bubble.component';
import {NetworkMemberNewProjectComponent} from './components/network/network-member-new-project.component';
import {JwtGuard} from './guards/jwt.guard';
import {QuestionnaireGuard} from './guards/questionnaire.guard';
import {IsSuperAdminGuard} from './guards/is-super-admin.guard';
import {RegisterGuard} from './guards/register.guard';
import {MenuService} from './services/menu.service';
import {CguFooterComponent} from './components/cgu-footer/cgu-footer.component';
import {SuperAdminUsersComponent} from './components/admin/super-admin-users/super-admin-users.component';
import {SuperAdminCreateAdminComponent} from './components/admin/super-admin-create-admin/super-admin-create-admin.component';
import {EnterpriseDirectoryComponent} from './components/enterprise/enterprise-directory/enterprise-directory.component';
import {EnterpriseDirectoryItemComponent} from './components/enterprise/enterprise-directory/enterprise-directory-item/enterprise-directory-item.component';
import {EnterpriseCreateDirectoryComponent} from './components/enterprise/enterprise-create-directory/enterprise-create-directory.component';
import { AdminEnterpriseDirectoryComponent } from './components/admin/admin-enterprise-directory/admin-enterprise-directory.component';
import { AdminStatsComponent } from './components/admin/admin-stats/admin-stats.component';


@NgModule({
  declarations: [
    AssistanceSystemsComponent,
    LoginComponent,
    LogoutComponent,
    HomeComponent,
    NavbarComponent,
    SidebarComponent,
    QuestionnaireComponent,
    QuestionnaireHelperModalComponent,
    NetworkDeleteModalComponent,
    NetworkNewProjectModalComponent,
    UpgradesComponent,
    AppComponent,
    HistoryComponent,
    AdnComponent,
    AdnNetworkComponent,
    GoodPracticesComponent,
    OffersComponent,
    OffersBlocksComponent,
    OfferComponent,
    IdentificationComponent,
    DocumentsComponent,
    NetworkHomeComponent,
    NetworkDocumentsComponent,
    ParametersComponent,
    AdminHomeComponent,
    AdminDocumentsReviewComponent,
    AdminModifyLogoComponent,
    AdminTestMappingComponent,
    AdminAssistanceSystemsComponent,
    RegistrationComponent,
    PasswordForgottenComponent,
    PasswordForgottenDefineComponent,
    NetworkNewMemberComponent,
    EscapeHtmlPipe,
    SlugifyPipe,
    SpinnerComponent,
    AdminExportComponent,
    AdminAdnComponent,
    SupportBubbleComponent,
    NetworkMemberNewProjectComponent,
    CguFooterComponent,
    SuperAdminUsersComponent,
    SuperAdminCreateAdminComponent,
    EnterpriseDirectoryComponent,
    EnterpriseDirectoryItemComponent,
    EnterpriseCreateDirectoryComponent,
    AdminEnterpriseDirectoryComponent,
    AdminStatsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(ROUTES),
    NgbModule,
    NgChartsModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbPaginationModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      easeTime: 300
    }),
    NgMultiSelectDropDownModule.forRoot(),
    Select2Module,
    RecaptchaModule,
    RecaptchaFormsModule,
    TreeModule
  ],
  providers: [
    { provide: JsonService, useClass: JsonService },
    { provide: AssetService, useClass: AssetService },
    { provide: MarqueService, useClass: MarqueService },
    {provide: DefaultUrlSerializer, useClass: DefaultUrlSerializer},
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    JwtGuard,
    IsSuperAdminGuard,
    QuestionnaireGuard,
    RegisterGuard,
    SlugifyPipe,
    MenuService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
