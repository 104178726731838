import {Injectable} from '@angular/core';

/**
 * Only used for grandlyon
 * User has to (re)complete the identification form everytime he wants to complete a questionnaire
 */
@Injectable({
    providedIn: 'root'
})
export class IdentificationStateService {
  public isIdentificationCompleted: boolean = false;
  public isHomePageVisited: boolean = false;
}
