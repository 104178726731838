<!doctype html>
<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1">
</head>
<body [ngClass]="[marque?.slug ? marque.slug : '', 'app-body']">
<router-outlet></router-outlet>
<ns-support-bubble></ns-support-bubble>
</body>
</html>
