import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {ToastrService} from "ngx-toastr"
import {Select2Data} from "ng-select2-component"
import {JsonService} from "../../../services/json.service"
import {Router} from "@angular/router"
import {TestMappingField} from "../../../models/testMappingField"

@Component({
  selector: 'ns-test-mapping',
  templateUrl: './admin-test-mapping.component.html'
})

export class AdminTestMappingComponent {

  @Input() q: string;
  @ViewChild('autocomplete') qElementRef: ElementRef;

  title = 'angularfront'

  public lastUsername: string
  public errorMessage : string
  public form: Array<TestMappingField>
  public ready: boolean = false
  public dropdownList = [];
  public selectedItems = [];
  public dropdownSettings = {};
  public formStructure: Array<any>
  public result: Array<any>
  public data: Select2Data = [
    { value: 'iso_26000', label: 'ISO 26000' },
    { value: 'bcorp', label: 'B Corp' },
    { value: 'label_lucie', label: 'Label Lucie' },
    { value: 'ecovadis', label: 'ECOVADIS' },
    { value: 'sd21000', label: 'SD 21000' },
    { value: 'ecocert_26000', label: 'ECOCERT 26000' },
    { value: 'sa_8000', label: 'SA 8000' },
    { value: 'adhesion_global_compact', label: 'Adhésion au Global Compact' },
    { value: 'label_rfar', label: 'Label RFAR (Relations fournisseurs et Achats responsables)' },
  ]

  constructor (
    private api: JsonService,
    private router: Router,
    private toastr: ToastrService,
  ) {
  }

  ngAfterViewInit(): void {
    this.ready = false
    this.result = []
    setTimeout(() => {
      this.getForm()

      this.dropdownList = [

      ];

      this.dropdownSettings = {
        singleSelection: false,
        idField: 'item_id',
        textField: 'item_text',
        selectAllText: 'Tout sélectionner',
        unSelectAllText: 'Tout déselectionner',
        itemsShowLimit: 5,
        allowSearchFilter: false
      };
    })
  }

  getForm() {
    this.api.getTestMapping().subscribe(content => {
      if (typeof content['form'] !== 'undefined') {
        this.form = content['form']
        this.ready = true
      } else {
        this.errorMessage = content['errorMessage']
      }

    });
  }

  postForm(form) {
    let certification = form.value['test_mapping[certificationsRse]'];

    if (typeof certification !== 'undefined') {
      delete form.value['test_mapping[certificationsRse]']
      for (let i = 0; i <  certification.length-1; i++) {
        let value = certification[i]
        form.value['test_mapping[certificationsRse]['+value+']'] = value
      }
    }


    this.api.postTestMapping(form.value).subscribe(content => {
      if (typeof content['result'] !== 'undefined') {
        this.result = content['result']
      } else {
        this.toastr.error(content['error'], '');
      }

    });
  }

  onItemSelect(item: object) {
    this.selectedItems.push(item['item_id'])
  }

  onItemUnselect(item: object) {
    for( var i = 0; i < this.selectedItems.length; i++){
      if ( this.selectedItems[i] === item['item_id']) {
        this.selectedItems.splice(i, 1);
      }
    }
  }
}
