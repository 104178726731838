<ns-navbar></ns-navbar>
<div id="body-container">
  <ns-sidebar ></ns-sidebar>
  <div id="content-wrapper" class="page-suivi">
    <ns-spinner *ngIf="!ready"></ns-spinner>
    <div *ngIf="ready">
      <div class="px-5" >
        <div class="row">
          <div class="col-md-6 mb-4" *ngIf="lineChartReady">
            <h1 class="titre_backend">Progression</h1>
            <div class="card card-suivi">
              <div style="position:relative;height:300px;width:100%;padding:20px;">
                <canvas baseChart
                  class="line-chart-canvas"
                  [data]="lineChartData"
                  [options]="lineChartOptions"
                  type="line"
                ></canvas>
              </div>
            </div>
          </div>
          <div class="col-md-6 mb-4" *ngIf="radarChartReady">
            <h1 class="titre_backend">Positionnement</h1>
            <div class="card card-suivi">
              <div style="position:relative;height:300px;width:100%;padding:20px;">
                <canvas baseChart
                  class="radar-chart-canvas"
                  [data]="radarChartData"
                  [options]="radarChartOptions"
                  type="radar"
                ></canvas>
              </div>
            </div>
          </div>
          
        </div>
        <div class="cards-container" [ngClass]="marque?.slug" id="adn-diag" >
          <div class="cards-container__element follow__card" *ngFor="let category of lastScore?.categories">
            <div class="card card-suivi mt-3 bg-white o-hidden h-100" [ngClass]="[category.family ? 'domain-family-'+category.family : '']">
              <div class="card-body">
                <div class="mb-2 container-title-progress-bar">
                  <div [ngClass]="'me-6 titre-suivi h2-bold'">{{ category.name }}</div>
                  <div>
                        <!-- <div [ngClass]="[marque?.slug, 'upgrades__upgrade__progress_bar__point__filled']">
                        </div> -->
                        <span class="legend">{{ category.pourcentage |number: '1.0-0' }}/100</span>
                  </div>
                </div>
                <div class=" row card-suivi__body">
                  <div class="col-12 mt-4 subcategory" *ngFor="let subCategory of category?.subCategories">
                    <span class="legend">{{ subCategory.name }}</span>
                    <div class="progress subdomain-header-progress">
                      <div [ngClass]="['progress-bar', 'plain-progress-bar']" role="progressbar"  [ngStyle]="calculateWidth(subCategory.pourcentage)"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      </div>
    </div>
</div>
