export class Marque {

    constructor(
      public slug: string,
      public label: string,
      public hasAssistanceSystems: boolean,
      public hasSupportEnabled: boolean
    ) {
    }
}
