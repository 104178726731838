import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Marque } from 'src/app/models/marque.model';
import { User } from 'src/app/models/user.model';
import { AssetService } from 'src/app/services/asset.service';
import { JsonService } from 'src/app/services/json.service';
import { MarqueService } from 'src/app/services/marque.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ns-admin-home',
  templateUrl: './admin-home.component.html'
})
export class AdminHomeComponent implements OnInit, AfterViewInit, OnDestroy {
  public readyMarque = false;
  public readyMembers = false;

  public membres: User[] = [];

  public canUserHasNewQuestionnaire: any = {};
  public adnCount: number = 0;
  public enterpriseCount: number = 0;
  public exportUrl: string;
  public marque: Marque;

  public currentOrderIdentifier = 'id';
  public currentOrderWording = 'Date';

  public isDownloadingXls: boolean = false;
  public baseUrl: string
  public token: string

  public searchFormControl = new UntypedFormControl();
  private searchSubscription: Subscription;

  public pageNumber: number = 1;
  public pageSize: number = 20;
  public memberSize: number = 0;


  constructor(
    private api: JsonService,
    public assetService: AssetService,
    private marqueService: MarqueService,
    private toasterService: ToastrService
  ) {
    this.baseUrl = environment.apiUrl;
    this.token = localStorage.getItem('token')
  }

  ngOnInit(): void {
    this.readyMarque = false;
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response;
        this.readyMarque = true;
      }
    });
  }

  ngAfterViewInit(): void {
    this.getData();
    this.searchSubscription = this.searchFormControl.valueChanges
      .pipe(debounceTime(600))
      .subscribe(searchTerm => {
        this.getData();
        this.pageNumber = 1;
      });
  }

  getData() {
    this.scrollToTop();
    this.readyMembers = false;
    this.api.getUserListAsAdmin(
      this.currentOrderIdentifier,
      this.pageNumber,
      this.pageSize,
      this.searchFormControl.value
    ).subscribe(data => {
      this.membres = data['membres'].map(m => {
        if (m.questionnaireResponses?.length === 0) {
          m.questionnaireResponses = [{completed: false}];
        }
        return m;
      });
      this.enterpriseCount = data['enterpriseCount'];
      this.adnCount = data['adnCount'];
      this.readyMembers = true;
    });
  }

  updateOrdering(identifier, wording) {
    this.currentOrderIdentifier = identifier;
    this.currentOrderWording = wording;
    this.getData();
  }

  isReady() {
    return this.readyMarque && this.readyMembers;
  }

  downloadExport(fullDownload: boolean = false) {
    if (this.isDownloadingXls) {
      return;
    }
    this.isDownloadingXls = true;

    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    this.api.getExportAdnAsAdmin(fullDownload).subscribe(
      (blobby) => {
        this.isDownloadingXls = false;
        const objectUrl = window.URL.createObjectURL(blobby);

        const filenameSuffix = fullDownload ? '-full' : '';
        anchor.href = objectUrl;
        anchor.download = `adn${filenameSuffix}.xlsx`;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      },
      (err) => {
        this.isDownloadingXls = false;
        this.toasterService.warning('Une erreur est survenue lors du téléchargement');
        console.error(err);
      });
  }

  private scrollToTop() {
    const element = document.getElementById('content-wrapper');
    element.scrollIntoView();
  }

  ngOnDestroy(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe()
    }
  }
}
