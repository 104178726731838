<ns-navbar></ns-navbar>
<div id="body-container">
  <!-- <ns-network-sidebar></ns-network-sidebar> -->
  <ns-sidebar sidebarType="private"></ns-sidebar>
  <div id="content-wrapper">
    <div *ngIf="readyMarque" class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="row justify-content-between m-2 mtopbot2">
            <div class="col-lg-8" [ngClass]="[marque.slug, 'reseau-resultat', 'text-colored']">
              <h1 class="titre_backend_reseau">Les évaluations de vos entreprises</h1>
              <p>{{ enterpriseCount }} entreprises trouvées.</p>
              <p>{{ adnCount }} évaluations au total.</p>
              <p><a [routerLink]="[]" (click)="downloadExportExcel()"
                  [style.cursor]="isDownloadingXls ? 'wait' : 'pointer'">Exporter au format Excel</a></p>
            </div>
            <div class="col-lg-4">
              <div class="d-flex justify-content-end">
                <span class="me-3 sort-style">Trier par </span>
                <div ngbDropdown class="d-inline-block">
                  <button [ngClass]="[marque.slug, 'btn btn-outline btn-inverted-white btn-reseau-2']"
                    id="dropdownMenuButton" ngbDropdownToggle>
                    <span id="ordering-title" class="ps-2 pe-3">{{ currentOrderWording }}</span>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="updateOrdering('id', 'Date')">Date</button>
                    <button ngbDropdownItem (click)="updateOrdering('companyName', 'Entreprise')">Nom</button>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mt-3">
                <span class="me-3 sort-style">Filtrer par fond</span>
                <div ngbDropdown class="d-inline-block">
                  <button [ngClass]="[marque.slug, 'btn btn-outline btn-inverted-white btn-reseau-2']"
                    id="dropdownMenuButton" ngbDropdownToggle>
                    <span *ngIf="currentFund === null" class="ps-2 pe-3">Tous</span>
                    <span *ngIf="currentFund !== null" class="ps-2 pe-3">{{ currentFund }}</span>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="updateFundFilter(null)">Tous</button>
                    <button *ngFor="let networkFund of networkFunds" ngbDropdownItem
                      (click)="updateFundFilter(networkFund)">{{ networkFund }}</button>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-end mt-3">
                <span class="me-3 sort-style">Filtrer par année</span>
                <div ngbDropdown class="d-inline-block">
                  <button [ngClass]="[marque.slug, 'btn btn-outline btn-inverted-white btn-reseau-2']"
                    id="dropdownMenuButton" ngbDropdownToggle>
                    <span *ngIf="currentYear === null" class="ps-2 pe-3">Tous</span>
                    <span *ngIf="currentYear !== null" class="ps-2 pe-3">{{ currentYear }}</span>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="updateYearFilter(null)">Tous</button>
                    <button *ngFor="let year of years" ngbDropdownItem (click)="updateYearFilter(year)">{{ year
                      }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-6">
              <a routerLink="/network/new-member"
                [ngClass]="[marque.slug, 'btn btn-outline btn-inverted-white btn-reseau-2 mx-4 shadow']"
                style="line-height: 36px;">
                <span class="btn-reseau-spec-span" style="position:relative;top: 2px;">&nbsp;+&nbsp;</span>
                <span class="pe-3" style="position:relative;top: -2px;">Ajouter une entreprise</span>
              </a>
            </div>
            <div class="col-6">
              <form style="width: 380px; float: right">
                <div class="input-group">
                  <div class="input-group-prepend network-home-searchbar-prepend">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                  <input type="text" class="form-control network-home-searchbar" placeholder="Rechercher un membre..."
                    [formControl]="searchFormControl">
                </div>
              </form>
            </div>
          </div>

          <div *ngIf="readyMembers">
            <div *ngFor="let membre of filteredData">
              <div *ngFor="let questionnaireResponse of membre.questionnaireResponses">
                <div class="card reseau-card m-4 shadow">
                  <div class="reseau-card__company">
                    {{ membre.companyName }}
                  </div>
                  <div class="reseau-card__fond">
                    {{ questionnaireResponse.investmentFund }}
                  </div>
                  <div class="reseau-card__project-name">
                    {{ questionnaireResponse.projectName }}
                  </div>

                  <div class="reseau-card__body">
                    <span *ngIf="questionnaireResponse.completed" [ngClass]="[marque.slug, 'text-colored']">{{
                      questionnaireResponse.adn.pourcentage | number: '1.0-0' }}/100</span>
                    <ng-container *ngIf="!questionnaireResponse.completed">
                      Questionnaire non validé
                    </ng-container>
                  </div>

                  <div class="reseau-card__buttons">
                    <div class="me-2" *ngIf="questionnaireResponse.completed">
                      <a [routerLink]="['network-adn', membre?.id, questionnaireResponse.id]"
                        [ngClass]="[marque.slug, 'btn', 'btn-outline btn-inverted btn-reseau-2']"
                        ngbTooltip="Voir le détail">
                        <i class="far fa-eye"></i>
                      </a>
                    </div>
                    <div class="me-2">
                      <a (click)="openNewModal(membre)"
                        [ngClass]="[marque.slug, 'btn', 'btn-outline btn-inverted btn-reseau-2']"
                        ngbTooltip="Relancer ou Démarrer un nouveau projet">
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                    <div class="">
                      <a (click)="openDeleteModal(membre, questionnaireResponse.id)"
                        [ngClass]="['btn-reseau-danger', 'btn', 'btn-outline btn-inverted btn-reseau-2']"
                        ngbTooltip="Supprimer">
                        <i class="fas fa-trash-alt"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row justify-content-md-center mt-5">
              <ngb-pagination [(collectionSize)]="enterpriseCount" [(page)]="pageNumber" [(pageSize)]="pageSize"
                (pageChange)="getData()" [maxSize]="5" [rotate]="true" [boundaryLinks]="true"
                aria-label="Pagination"></ngb-pagination>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>