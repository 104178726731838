import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private open: EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }

  getToggleSignalObservable() {
    return this.open.asObservable();
  }

  toggle() {
    this.open.emit(true);
  }
}
