import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Marque } from '../models/marque.model';
import { User } from '../models/user.model';
import { MarqueService } from '../services/marque.service';
import { UserService } from '../services/user.service';
import { JsonService } from '../services/json.service';
import { NavigationStart, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ns-support-bubble',
  templateUrl: '../templates/support-bubble.component.html',
  styleUrls: ['../../assets/scss/components/support-bubble.scss']
})
export class SupportBubbleComponent implements OnInit, OnDestroy {
  public marque?: Marque;
  public user?: User;
  private sbModalRef?: NgbModalRef;
  public sbFormGroup: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.email, Validators.required]),
    question: new UntypedFormControl('', [Validators.minLength(3), Validators.maxLength(2000), Validators.required])
  });

  public showButtonForCurrentUrl: boolean = false;
  public showButtonForUserType: boolean = false;

  private destroy$ = new Subject();

  constructor(
    private jsonService: JsonService,
    private marqueService: MarqueService,
    private userService: UserService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      takeUntil(this.destroy$))
    .subscribe((ev: NavigationStart) => {
      this.showButtonForCurrentUrl = ['/', '/identification', '/questionnaire', '/documents', '/assistance-systems'].includes(ev.url);
    });

    this.userService.user$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(u => {
      this.user = u;
      if (u) {
        this.showButtonForUserType = !['reseau', 'admin'].includes(u.type);
        this.refreshMarque();
      }
    });
  }

  refreshMarque() {
    this.marqueService.getMarque().pipe(
      take(1)
    ).subscribe(m => {
      this.marque = m as any;
    })
  }

  open(content) {
    this.sbModalRef = this.modalService.open(content, { centered: true, size: 'lg' });
  }

  submitForm() {
    if (this.sbFormGroup.valid) {
      const values = this.sbFormGroup.getRawValue();
      this.jsonService.sendSupportQuestion(values['email'], values['question']).subscribe(res => {
        if (res.sent) {
          this.toastr.success('Votre question a bien été transmise.');
          this.sbFormGroup.reset();
        } else {
          this.toastr.warning('Votre question n\'a pas pu être transmise. Veuillez réessayer dans quelques instants.');
        }
        this.sbModalRef?.close();
      })
    } else {
      this.toastr.warning('Veuillez remplir l\'email et le message');
    }
  }

  ngOnDestroy() {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
