<div class="offers">
   <!-- <div class="offers__header">
        {% if app.request.get('info') is not empty and app.request.get('info') == 'completed' %}
            <h2 class="offers__header__title">
                Félicitation !
            </h2>

            <span class="offers__header__subtitle">
                Vous pouvez désormais visualiser <a href="{{ path('backend_adn') }}">votre ADN</a> et opter pour une de nos offres.
            </span>
        {% endif %}
    </div>-->

    <div class="offers__cards row">
          <div *ngFor="let offer of offers">
            <div [class]="['card-deck', 'cardOffer-'+offer.name.replace('\'',''), 'col-xl-4' ,'justify-content-center']">
                <div class="card card-style">
                    <div class="card-header">
                        <h2>Offre {{ offer.name | titlecase }}</h2>
                        <p>à partir de <br/> <span class="price">{{ offer.price | number: '1.0-0' }}</span>HT/an</p>
                        <a class="btn" [routerLink]="['/offer', offer.name]">Sélectionner</a>
                    </div>
                    <!--          starter: Visualisation de votre ADN|Suivi de son évolution|Cibler ses axes d'améliorations|Dévoiler publiquement son ADN|Audit réalisé par un OTI|Tracking et analyse d'audience           #}
                    {#          up: Visualisation de votre ADN|Suivi de son évolution|Cibler ses axes d'améliorations|Dévoiler publiquement son ADN|Audit réalisé par un OTI|Tracking et analyse d'audience#}
                    {#          pro: Visualisation de votre ADN|Suivi de son évolution|Cibler ses axes d'améliorations|Dévoiler publiquement son ADN|Audit réalisé par un OTI|Tracking et analyse d'audience#}
                    -->
                    <div class="card-body">
                        <ul class="card-body__list">
                                <li *ngFor="let item of offer.description" class="card-body__list__elem">
                                    <span [class]="{'card-body__list__elem__icon': true, 'disabled': item.disabled}">
                                      <i class="gg-check"></i>
                                    </span>
                                    <span [class]="{'card-body__list__elem__text': true, 'disabled': item.disabled}">
                                        {{ item.text }}
                                    </span>
                                </li>

                        </ul>
                    </div>
                </div>
            </div>
          </div>
    </div>
</div>
