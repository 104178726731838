import { Injectable } from '@angular/core';
import { InseeNbEmployeeCodeMap, InseeStatusCodeMap } from '../models/insee_codes.model';
import { JsonService } from "./json.service"

@Injectable({
  providedIn: 'root'
})
export class InseeService {

  constructor(private jsonService: JsonService) {
  }

  /** Search company detail by SIREN */
  searchSiren(siren: string) {
    return this.jsonService.getSearchInseeSiren(siren);
  }

  /** Search company detail by SIRET */
  searchSiret(siret: string) {
    return this.jsonService.getSearchInseeSiret(siret);
  }

  /** Get nbEmloyee in gen-ethic format */
  getNbEmployeeFromUniteLegale(unitesLegale): string {
    const trancheEffectifsUniteLegale = unitesLegale?.trancheEffectifsUniteLegale || '00';
    if (trancheEffectifsUniteLegale in InseeNbEmployeeCodeMap) {
      return InseeNbEmployeeCodeMap[trancheEffectifsUniteLegale]
    }
    return InseeNbEmployeeCodeMap['00'];
  }

  /** Get status in gen-ethic format */
  getStatusFromUniteLegale(uniteLegale): string {
    const inseeStatus = uniteLegale?.periodesUniteLegale?.[0]?.categorieJuridiqueUniteLegale || uniteLegale?.categorieJuridiqueUniteLegale || '';
    if (inseeStatus in InseeStatusCodeMap) {
      return InseeStatusCodeMap[inseeStatus]
    }
    return '';
  }

  /** Get companyName in gen-ethic format */
  getCompanyNameFromUniteLegale(unitesLegale): string {
    // Most company will use the following format if using SIREN
    if (unitesLegale?.periodesUniteLegale?.[0]?.denominationUniteLegale) {
      return unitesLegale.periodesUniteLegale[0].denominationUniteLegale;
    }

    // Most company will use the following format if using SIRET
    if (unitesLegale?.denominationUniteLegale) {
      return unitesLegale.denominationUniteLegale;
    }

    // EI or EURL might use the following format
    let companyName = '';
    if (unitesLegale?.periodesUniteLegale?.[0]?.nomUniteLegale) {
      companyName = unitesLegale.periodesUniteLegale[0].nomUniteLegale + ' ';
    }

    if (unitesLegale?.prenom1UniteLegale) {
      companyName += unitesLegale.prenom1UniteLegale + ' ';
    }
    if (unitesLegale?.prenom2UniteLegale) {
      companyName += unitesLegale.prenom2UniteLegale + ' ';
    }
    if (unitesLegale?.prenom3UniteLegale) {
      companyName += unitesLegale.prenom3UniteLegale + ' ';
    }
    if (unitesLegale?.prenom4UniteLegale) {
      companyName += unitesLegale.prenom4UniteLegale + ' ';
    }

    return companyName.trim();
  }

  /** Returns address number type street. ex: 12 rue des xxx, Batiment DD */
  getAddressStreetFromEstablishment(establishment): string {
    let result = ''
    if (establishment?.adresseEtablissement?.numeroVoieEtablissement) {
      result += establishment.adresseEtablissement.numeroVoieEtablissement + ' ';
    }
    if (establishment?.adresseEtablissement?.typeVoieEtablissement) {
      result += establishment.adresseEtablissement.typeVoieEtablissement + ' ';
    }
    if (establishment?.adresseEtablissement?.libelleVoieEtablissement) {
      result += establishment.adresseEtablissement.libelleVoieEtablissement + ' ';
    }
    if (establishment?.adresseEtablissement?.complementAdresseEtablissement) {
      result = result.trim() + ', ' + establishment.adresseEtablissement.complementAdresseEtablissement + ' ';
    }
    return result.trim();
  }

  /** Returns address postal code */
  getAddressPostalCodeFromEstablishment(establishment): string {
    return establishment?.adresseEtablissement?.codePostalEtablissement || '';
  }

  /** Returns address city name */
  getAddressCityFromEstablishment(establishment): string {
    return establishment?.adresseEtablissement?.libelleCommuneEtablissement || '';
  }
}
