export class TestMappingField {

    constructor(public type?: string,
                public label?: string,
                public name?: string,
                public id?: string,
                public choices?: Array<any>,
                ) {
    }
}
