<ns-navbar></ns-navbar>
<div id="body-container">
  <!-- <ns-network-sidebar></ns-network-sidebar> -->
  <ns-sidebar sidebarType="admin"></ns-sidebar>
  <div id="content-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="row justify-content-between m-2 mtopbot2">
            <div class="col-lg-8" [ngClass]="[marque.slug, 'reseau-resultat', 'text-colored']">
              <h1 class="titre_backend_reseau">Annuaire | Les entreprises en attente de validation</h1>
              <p>{{ unvalidatedCount }} demande(s) en attente(s)</p>
              <p>{{ validatedCount }} demande(s) validée(s)</p>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="ready">
        <div *ngFor="let ed of enterpriseDirectories" class="row aed-item-container">

          <div class="aed-logo-container">
            <img [src]="ed.logoUrl" alt="logo" class="edi-logo">
          </div>

          <div class="col aed-info-container">
            <div class="aed-info-item"><b>Entreprise :</b> {{ed.user.companyName}}</div>
            <div class="aed-info-item"><b>Description :</b> {{ed.description}}</div>
            <div class="aed-info-item"><b>Email :</b> {{ed.user.email}}</div>
            <div class="aed-info-item"><b>Contact :</b> {{ed.contact}}</div>
            <div class="aed-info-item"><b>Lien :</b> {{ed.link}}</div>
          </div>

          <div class="aed-action-container">
            <button *ngIf="!ed.isValidated" [ngClass]="[marque.slug, 'btn', 'btn-outline btn-inverted btn-reseau-2']"
              ngbTooltip="Cette demande est en attente de validation. Cliquer pour valider." placement="top" 
              container="body" (click)="validate(ed.id)">
              <i class="fas fa-check"></i>
            </button>
            <button *ngIf="ed.isValidated" [ngClass]="[marque.slug, 'btn btn-outline btn-inverted btn-reseau-2 btn-danger']"
              ngbTooltip="Cette demande à été validée. Cliquer pour l'invalider." placement="top" 
              container="body" (click)="invalidate(ed.id)">
              <i class="fas fa-ban"></i>
            </button>

          </div>
        </div>

        <div *ngIf="!enterpriseDirectories?.length">
          Aucune demande en attente
        </div>
      </div>

    </div>
  </div>