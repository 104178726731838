import { Component, OnInit } from '@angular/core';
import { JsonService } from "../../services/json.service";
import { AssetService } from "../../services/asset.service"
import { DocumentsTreeNode, UserDocument } from 'src/app/models/user_document.model';
import { Marque } from 'src/app/models/marque.model';
import { MarqueService } from 'src/app/services/marque.service';

@Component({
  selector: 'ns-network-documents',
  templateUrl: '../../templates/backend/network/network_documents.component.html'
})

export class NetworkDocumentsComponent implements OnInit {

  public documents: Array<UserDocument> = [];
  public documentNodes: DocumentsTreeNode[];
  public hasDocument: boolean;
  public marque: Marque;
  public readyMarque: boolean;
  public ready: boolean;

  constructor(
    private api: JsonService,
    public assetService: AssetService,
    private marqueService: MarqueService
  ) { }

  ngOnInit(): void {
    this.getMarque();
    this.getNetworkDocuments();
  }

  getMarque() {
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response;
        this.readyMarque = true;
      }
    });
  }

  getNetworkDocuments() {
    this.api.getNetworkDocuments().subscribe(content => {
      if (typeof content['documents'] !== 'undefined') {
        this.documents = content['documents'];
        this.hasDocument = !!this.documents?.length;
        this.ready = true;
      }
      this.documentNodes = this.documentsToTreeNodes(this.documents);
    });
  }


  private documentsToTreeNodes(docs: UserDocument[]): DocumentsTreeNode[] {
    const res: any = {};

    for (let d of docs) {
      // Add investmentFund
      if (!(d.user.investmentFund in res)) {
        res[d.user.investmentFund] = {
          name: d.user.investmentFund,
          children: {},
          cpt: 0
        };
      }

      // Add company
      if (!(d.user.companyName in res[d.user.investmentFund].children)) {
        res[d.user.investmentFund].children[d.user.companyName] = {
          name: d.user.companyName,
          children: {},
          cpt: 0
        }
      }

      // Add dates
      const year = (new Date(d.date_uploaded)).getFullYear().toString();
      if (!(year in res[d.user.investmentFund].children[d.user.companyName].children)) {
        res[d.user.investmentFund].children[d.user.companyName].children[year] = {
          name: year,
          children: [],
          cpt: 0
        }
      }

      // Add document and update counters
      res[d.user.investmentFund].children[d.user.companyName].children[year].children.push({
        ...d,
        isDocument: true
      });
      res[d.user.investmentFund].children[d.user.companyName].children[year].cpt++;
      res[d.user.investmentFund].children[d.user.companyName].cpt++;
      res[d.user.investmentFund].cpt++;
    }


    // Restore array from hashmap
    return Object.values(res).map(companyNameNode => {
      companyNameNode['children'] = Object.values(companyNameNode['children']).map(yearNode => {
        yearNode['children'] = Object.values(yearNode['children']);
        return yearNode;
      });
      return companyNameNode;
    }) as any;
  }

  downloadDocument(userDoc: UserDocument) {
    const fileExtention = userDoc.path.split('.').pop();

    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    this.api.downloadNetworkDocument(userDoc.id).subscribe((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = userDoc.label + '.' + fileExtention;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
  }
}
