import { Component } from '@angular/core';
import {JsonService} from "../services/json.service";
import {Router} from "@angular/router"

@Component({
  selector: 'ns-offers',
  templateUrl: '../templates/backend/offers.component.html'
})

export class OffersComponent {
  title = 'angularfront'
}
