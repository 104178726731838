import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Marque } from "../models/marque.model";
import { IdentificationStateService } from "../services/identificationstate.service";
import { MarqueService } from "../services/marque.service";

/**
 * Guard applyed to '/questionnaire' route.
 * Send user to '/identification' for grandlyon before completing questionnaire
 */
@Injectable()
export class QuestionnaireGuard implements CanActivate {
  constructor(
    private router: Router,
    private marqueService: MarqueService,
    private identificationStateService: IdentificationStateService,
    private toastr: ToastrService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return this.marqueService.getMarque().pipe(
      map((marque: Marque) => {
        if (marque.slug === 'grandlyon' && !this.identificationStateService.isIdentificationCompleted) {
          this.toastr.info('Veuillez compléter le formulaire d\'identification avant d\'accéder au questionnaire');
          this.router.navigate(['/identification']);
          return false;
        }
        return true;
      })
    ).toPromise();
  }
}
