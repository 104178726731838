import {Offer} from './offer.model';
import { QuestionnaireResponse } from './questionnaire_response.model';

export class User {

    constructor(
      public id: number,
      public type: string,
      public email: string,
      public companyName: string,
      public fullName: string,
      public cardId: number,
      public customerId: number,
      public subscriptionId: number,
      public offer: Offer,
      public businessSegment: string,
      public status: string,
      public address: string,
      public logoUrl: string,
      public nbEmployee: string,
      public nomenclatures: any,
      public investmentFund: string,
      public projectName: string,
      public hasAlreadyLogin: boolean,
      public isSuperAdmin: boolean,
      public questionnaireResponses: QuestionnaireResponse[] = []
    ) {
    }

  isReseau() {
      return this.type === 'reseau';
  }

  isMembreReseau() {
    return this.type === 'membre_reseau';
  }

  isAdmin() {
    return this.type === 'admin';
  }
}
