import { Component, OnInit } from '@angular/core';
import { JsonService } from "../../../services/json.service"
import { UserService } from "../../../services/user.service"
import { User } from "../../../models/user.model"
import { Router } from "@angular/router"
import { MarqueService } from '../../../services/marque.service';
import { Marque } from '../../../models/marque.model';
import { ToastrService } from 'ngx-toastr';
import { EnterpriseDirectory } from 'src/app/models/enterprise_directory.model';
import { forkJoin, of } from 'rxjs';
import { IdentificationField } from 'src/app/models/identificationField';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

@Component({
  selector: 'ns-enterprise-create-directory',
  templateUrl: './enterprise-create-directory.component.html',
  styleUrls: ['./enterprise-create-directory.component.scss']
})
export class EnterpriseCreateDirectoryComponent implements OnInit {
  public user: User;
  public marqueReady: boolean = false;
  public formReady: boolean = false;
  public isEditionMode: boolean = false;
  public marque: Marque;
  public form: Array<IdentificationField>;
  public logoSelected: File;
  public errorMessage: string;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private api: JsonService,
    private userService: UserService,
    private marqueService: MarqueService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    forkJoin({
      marque: this.marqueService.getMarque(),
      user: this.userService.getUser(),
    }).subscribe((res) => {
      this.marque = res.marque as Marque;
      this.user = res.user as User;
      this.marqueReady = true
    })

    this.getForm();
  }


  private getForm() {
    this.api.getEnterpriseDirectoryForm().subscribe(content => {
      if (typeof content['form'] !== 'undefined') {
        this.form = content['form'];
        this.isEditionMode = this.form.some(field => field.value?.length > 0);
        this.formReady = true
      } else {
        this.errorMessage = content['errorMessage'];
      }
    },
      err => {
        const message = err?.error?.detail || 'Une erreur est survenue lors de la demande d\'ajout dans l\'annuaire.';
        this.toastr.error(message);
        this.router.navigate(['/directory']);
      });
  }

  postForm(form) {
    if (!this.logoSelected) {
      this.toastr.warning('Vous devez selectionner un logo.');
      return;
    }

    const formData = new FormData();
    formData.append('enterprise_directory_form[contact]', form.value['enterprise_directory_form[contact]'])
    formData.append('enterprise_directory_form[description]', form.value['enterprise_directory_form[description]'])
    formData.append('enterprise_directory_form[link]', form.value['enterprise_directory_form[link]'])
    formData.append('enterprise_directory_form[logo]', this.logoSelected, `${this.user.id}-${this.logoSelected.name.replace(/\s+/gm, '-')}`)

    this.api.postEnterpriseDirectoryForm(formData).subscribe(
      content => {
        if (typeof content['redirect_to'] !== 'undefined') {
          this.toastr.success('Votre demande à bien été soumise et sera prochainement validé par nos équipes.', 'Merci !');
          this.router.navigate(['/' + content['redirect_to']]);
        } else {
          this.toastr.error(content['error'], '');
        }
      },
      err => {
        const msg = err?.error?.detail || 'Une erreur est survenue.';
        this.toastr.error(msg);
      }
    );
  }

  formFieldHasError(enterpriseDirectoryForm, fieldId): boolean {
    if (`enterprise_directory_form[${fieldId}]` in enterpriseDirectoryForm?.controls) {
      return enterpriseDirectoryForm?.controls[`enterprise_directory_form[${fieldId}]`]?.errors !== null;
    }
    return false;
  }

  fileSelected(event) {
    this.logoSelected = event.target.files?.length ? event.target.files[0] : undefined;
  }

  openDeleteModal(modal) {
    const modalRef = this.modalService.open(modal, {ariaLabelledBy: 'modal-basic-title', centered: true});
    modalRef.closed.pipe(
      take(1)
    ).subscribe(res => {
      if (res) {
        this.deleteEnterpriseDirectory();
      }
    });
  }


  private deleteEnterpriseDirectory() {
    this.api.deleteEnterpriseDirectory().subscribe(content => {
      if (content['deleted']) {
        this.toastr.success('Votre entreprise à bien été retiré de l\'annuaire', 'Suppression');
      } else {
        this.toastr.error('Une erreur s\'est produite lors de la suppression', 'Suppression');
      }
      this.router.navigateByUrl('/directory')
    });
  }
}
