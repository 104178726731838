import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { EnterpriseDirectory } from 'src/app/models/enterprise_directory.model';
import { Marque } from 'src/app/models/marque.model';
import { AssetService } from 'src/app/services/asset.service';
import { JsonService } from 'src/app/services/json.service';
import { MarqueService } from 'src/app/services/marque.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ns-admin-enterprise-directory',
  templateUrl: './admin-enterprise-directory.component.html',
  styleUrls: ['./admin-enterprise-directory.component.scss']
})
export class AdminEnterpriseDirectoryComponent implements OnInit {
  public ready = false;

  public enterpriseDirectories: EnterpriseDirectory[] = [];
  public unvalidatedCount: number = 0;
  public validatedCount: number = 0;
  public marque: Marque;

  public baseUrl: string;

  constructor(
    private api: JsonService,
    public assetService: AssetService,
    private marqueService: MarqueService,
    private toasterService: ToastrService
  ) {
  }

  ngOnInit(): void {
    forkJoin({
      marque: this.marqueService.getMarque(),
      enterpriseDirectories: this.api.getEnterpriseDirectoriesToValidate()
    }).subscribe((res) => {
      this.marque = res.marque as Marque;
      this.enterpriseDirectories = this.aggregateData(res.enterpriseDirectories);
      this.unvalidatedCount = this.enterpriseDirectories.filter(ed => !ed.isValidated).length;
      this.validatedCount = this.enterpriseDirectories.length - this.unvalidatedCount;
      this.ready = true
    })
  }

  private aggregateData(enterpriseDirectories: EnterpriseDirectory[]) {
    const result = enterpriseDirectories?.map(ed => {
      ed.logoUrl = `${environment.apiUrl}/${ed.logoUrl}`;
      return ed;
    }).sort((a, b) => a.isValidated ? 1 : -1);
    return result;
  }

  validate(id: string) {
    this.api.validateEnterpriseDirectory(id).subscribe(
      (res: EnterpriseDirectory) => {
        this.toasterService.success('Demande validée avec succès');
        this.enterpriseDirectories.map(ed => {
          if (ed.id === res.id) {
            ed.isValidated = res.isValidated;
          }
          return ed;
        });
      },
      err => this.toasterService.error('Erreur lors de la validation', err.error.detail),
    );
  }

  invalidate(id: string) {
    this.api.invalidateEnterpriseDirectory(id).subscribe(
      (res: EnterpriseDirectory) => {
        this.toasterService.info('Demande rejetée avec succès');
        this.enterpriseDirectories.map(ed => {
          if (ed.id === res.id) {
            ed.isValidated = res.isValidated;
          }
          return ed;
        });
      },
      err => this.toasterService.error('Erreur lors du rejet', err.error.detail),
    );
  }

}
