import {Component, OnInit} from '@angular/core';
import {JsonService} from '../services/json.service';
import {AssistanceSystem} from '../models/assistance_systems.model';
import { MarqueService } from '../services/marque.service';
import { Marque } from '../models/marque.model';

@Component({
  templateUrl: '../templates/backend/assistance-systems.component.html'
})
export class AssistanceSystemsComponent implements OnInit {

  public assistanceSystems: Array<AssistanceSystem>;
  public ready = false;
  public marque?: Marque;

  public directoryPdfUrl?: string;
  public supportSystemPdfUrl?: string;

  constructor(
    private api: JsonService,
    private marqueService: MarqueService
  ) { }

  ngOnInit() {
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response
        this._updatePdkLinkAvailabilities();
      }
    });
    this.api.getAssistanceSystems().subscribe(assistanceSystems => {
      this.assistanceSystems = assistanceSystems;
      this.ready = true;
    });
  }

  private _updatePdkLinkAvailabilities() {
    if (this.marque.slug === 'grandlyon') {
      this.directoryPdfUrl = '/assets/documents/METROPOLE_Annuaire_Transformation_durable.pdf';
      this.supportSystemPdfUrl = '/assets/documents/transition-ecologique-entreprises-metropole-de-lyon-accompagnement-guide-fr.pdf';
    } else if (this.marque.slug === 'bordeaux') {
      this.supportSystemPdfUrl = '/assets/documents/Bordeaux-KitEntrepriseEnergie_LivretA5_2024_WEB.pdf';
    }
  }
}
