import { AfterContentInit, Component, OnInit } from '@angular/core';
import { Marque } from '../models/marque.model';
import { MarqueService } from '../services/marque.service';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'ns-app',
  templateUrl: '../templates/app.component.html'
})
export class AppComponent implements AfterContentInit, OnInit {
  public marque: Marque = null;

  constructor(
    private marqueService: MarqueService,
  ) {
  }

  ngOnInit() {
    // https://github.com/DethAriel/ng-recaptcha/issues/123
    RecaptchaComponent.prototype.ngOnDestroy = function () { };
  }

  ngAfterContentInit() {
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response;
        return this.marque;
      }
    });
  }
}
