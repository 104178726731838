import { Component } from '@angular/core';
import { JsonService } from '../services/json.service';
import { Marque } from "../models/marque.model";
import { MarqueService } from "../services/marque.service";
import { ChartConfiguration } from 'chart.js';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'ns-history',
  templateUrl: '../templates/history.component.html'
})
export class HistoryComponent {

  public launchAnimation: boolean;
  public ready: boolean = false;
  public marque: Marque;

  public lastScore: any;
  public bestScore: any;
  public averageScores: any;

  public lineChartReady: boolean = false;
  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: [],
    datasets: []
  };
  public lineChartOptions: ChartConfiguration<'line'>['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { display: false } },
    scales: {
      x: { display: true },
      y: { beginAtZero: true, suggestedMin: 0, suggestedMax: 100, ticks: { stepSize: 50 } },
    }
  };


  public radarChartReady: boolean = false;
  public radarChartData: ChartConfiguration<'radar'>['data'] = {
    labels: [],
    datasets: []
  };
  public radarChartOptions: ChartConfiguration<'radar'>['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { display: false } },
    scales: {
      r: { suggestedMin: 50, suggestedMax: 100, ticks: { stepSize: 50 } },
    }
  };

  constructor(private api: JsonService, private marqueService: MarqueService) {

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.ready = false;
      this.getData();
    });
  }

  getData() {
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response;
      }

      

      this.api.getHistory().subscribe(data => {
        if (data['scores']?.[0]?.data) {
          // sort adns from older to newer
          data['scores'][0].data = data['scores']?.[0]?.data?.sort((d1, d2) => {
            const date1 = new Date(d1.x).getTime();
            const date2 = new Date(d2.x).getTime();
            return date1 - date2;
          })
        }
        const datepipe = new DatePipe('en-EN');
        this.lineChartData.labels = data['scores']?.[0]?.data?.map(d => datepipe.transform(d?.x, 'd/MM/yy')) || [];
        this.lineChartData.datasets = [{
          label: 'Note Globale',
          data: data['scores']?.[0]?.data?.map(d => d?.y) || [],
          fill: true,
          backgroundColor: this.marqueService.backgroundColor[this.marque.slug],
          borderColor: this.marqueService.borderColor[this.marque.slug],
          pointBorderColor: this.marqueService.borderColor[this.marque.slug],
          pointBackgroundColor: this.marqueService.borderColor[this.marque.slug],
          tension: 0.2
        }];
        this.lineChartReady = true;

        this.lastScore = data['lastScore'];
        this.bestScore = data['bestScore'];
        this.averageScores = data['averageScores'];

        this.radarChartData.datasets = this.getRadarChartData(this.lastScore);
        this.radarChartData.labels = this.getRadarChartLabels(this.lastScore);
        this.radarChartReady = true;
        this.ready = true;

        setTimeout(() => { this.launchAnimation = true; }, 100);
      });
    });
  }

  private getRadarChartData(data: any) {
    const avgData = data.categories.map(category => {
      return this.averageScores.find(avgScore => avgScore.categoryName === category.name)?.value;
    });
    return [
      {
        data: avgData, //[67, 34, 43, 78],
        label: 'Moyenne des scores',
        borderColor: 'rgba(200, 200, 200, 1)',
        backgroundColor: 'rgba(200, 200, 200, 0.2)',
        pointBackgroundColor: 'white',
        pointBorderColor: 'rgba(100, 100, 100, 1)'
      },
      {
        data: data.categories.flatMap(d => Math.round(d.pourcentage)),
        label: 'Mon score',
        borderColor: this.marqueService.pointBorderColor[this.marque.slug],
        backgroundColor: this.marqueService.backgroundColor[this.marque.slug],
        pointBackgroundColor: 'white',
        pointBorderColor: this.marqueService.pointBorderColor[this.marque.slug]
      }
    ];
  }


  private getRadarChartLabels(data: any) {
    return data.categories.map((d, index) => {
      if (d.name.length > 20 && index > 0) {
        let closerFromMiddleMatch = -1;
        [...d.name.matchAll(/ /gm)].forEach((match) => {
          if (closerFromMiddleMatch === -1 ||
            Math.abs((d.name.length / 2) - closerFromMiddleMatch) > Math.abs((d.name.length / 2) - match.index)) {
            closerFromMiddleMatch = match.index;
          }
        });
        if (closerFromMiddleMatch !== -1) {
          return [[d.name.substr(0, closerFromMiddleMatch)], [d.name.substr(closerFromMiddleMatch + 1)]];
        }
      }
      return d.name;
    });
  }

  public calculateWidth(pourcentage) {
    if (this.launchAnimation) {
      return { width: pourcentage + '%' };
    }
  }
}
