import { Component } from '@angular/core';
import {JsonService} from "../services/json.service";
import {Router} from "@angular/router"
import {Offer} from "../models/offer.model"

@Component({
  selector: 'ns-offers-blocks',
  templateUrl: '../templates/backend/partials/_offers.component.html'
})

export class OffersBlocksComponent {
  title = 'angularfront'

  public offers: Array<Offer>

  constructor (private api: JsonService) {
  }

  ngAfterViewInit(): void {
    this.getOffers()
  }

  getOffers() {
    this.api.getOffers().subscribe(content => {
        this.offers = content['offers']
    });
  }
}
