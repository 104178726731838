import {Component, OnInit} from '@angular/core';
import {Marque} from "../../models/marque.model";
import {MarqueService} from "../../services/marque.service"
import {AssetService} from 'src/app/services/asset.service';

@Component({
  selector: 'ns-cgu-footer',
  templateUrl: './cgu-footer.component.html',
  styleUrls: ['./cgu-footer.component.scss']
})

export class CguFooterComponent implements OnInit {
  public privacyPolicyFilePath: string;
  public legalNoticeFilePath: string;
  public hasLegalNotice: boolean = false;
  public marque : Marque;

  constructor (
    private marqueService: MarqueService,
    private assetService: AssetService
  ) { }



  ngOnInit() {
    this.marqueService.getMarque().subscribe((marque: Marque) => {
      this.marque = marque;
      if (['bordeaux', 'paca'].includes(this.marque.slug)) {
        this.hasLegalNotice = true;
      }
    });

    this.assetService.getPrivacyPolicyFilePath().subscribe((filePath: string) => {
      this.privacyPolicyFilePath = filePath;
    });

    this.assetService.getLegalNoticeFilePath().subscribe((filePath: string) => {
      this.legalNoticeFilePath = filePath;
    });
  }
}
