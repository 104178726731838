import {Question} from "./question.model";

export class SubCategory {

    constructor(public name?: string,
                public slug?: string,
                public questions?: Array<Question>,
                public score?: number,
                public scoreMax?: number,
                public weight?: number,
                public pourcentageWeight?: number,
                public pourcentage?: number
                ) {
    }
}
