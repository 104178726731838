import {Choice} from './choice.model';

export class Question {

  constructor(
    public ref?: string,
    public label?: string,
    public isAcceptingComment?: boolean,
    public comment?: string,
    public choices?: Array<Choice>,
    public multiChoices?: boolean,
    public userChoice?: Array<any>,
    public ptsGranted?: number,
    public ptsBonusGranted?: number,
    public scoreMax?: number,
    public description?: string,
    public commentType: string = 'text'
  ) {}
}
