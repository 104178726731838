<ns-navbar></ns-navbar>
<div id="body-container">
  <ns-sidebar></ns-sidebar>
  <div id="content-wrapper" class="container overflow-visible {{ colorMarqueOnly ? marque.slug : ''}}" >
    <div *ngIf="ready" >
      <div class="upgrades-page__container">
        <div class="upgrades-page__spacer-before"></div>
        <div class="upgrades-page__content">
          <div class="row">
            <div class="col-sm-6">
              <h1 class="titre_backend">Vos axes d’amélioration prioritaires</h1>
            </div>
            <div class="col-sm-6 text-colored upgrades-download-container" [ngClass]="[marque.slug]">
              <div class="mb-2">
                <a [ngClass]="[downloadButtonState]" (click)="downloadUpgrades()">
                  <i class="fas fa-download"></i>
                  <span style="margin-left:10px;">Télécharger les axes d'amélioration (pdf)</span>
                </a>
              </div>
              <div *ngIf="marque?.slug === 'grandlyon'">
                <a href="/assets/documents/METROPOLE_Annuaire_Transformation_durable.pdf" target="_blank">
                  <i class="fas fa-download"></i>
                  <span style="margin-left:10px;">Télécharger l’annuaire (pdf)</span>
                </a>
              </div>
            </div>
          </div>
          <ul class="upgrades" id="upgrade">
            <div *ngFor="let subCategory of content?.recommendations.subCategories; let i = index">
              <li [ngClass]="[marque.slug, 'upgrades__upgrade', subCategory.family ? 'domain-family-'+subCategory.family : '', 'upgrades__upgrade__'+subCategory.categoryNameSlug]" *ngIf="catSelected === subCategory.subCategoryName || catSelected === null">
                <div class="upgrades__upgrade__left collapsed">
                  <span class="upgrades__upgrade__name" >{{ subCategory.subCategoryName }}</span>
                  <div class="upgrades__upgrade__progress-bar">
                    <div class="progress subdomain-header-progress">
                      <div [ngClass]="['progress-bar', getGradientClass(subCategory)]" class="" role="progressbar"
                          aria-valuemin="0" aria-valuemax="100" [ngStyle]="calculateWidth(subCategory.pourcentage)"></div>
                        </div>
                    </div>
                    <span class="upgrades__upgrade__progress-bar__percent">
                        <span>{{ subCategory.pourcentage }}/100</span>
                        <span class="red-dot"></span>
                    </span>
                    <div class="upgrades__upgrade__right">
                      <button
                        [ngClass]="{'btn-focus-disabled': catSelected == null}"
                        class="{{ marque.slug }} upgrades__upgrade__button btn btn-inverted"
                        (click)="catSelected == null ? catSelected = subCategory.subCategoryName : catSelected = null"
                        [innerHTML]="catSelected == null ? 'Solutions' : 'Retour'"
                      >
                      </button>
                    </div>
                </div>
              </li>
              <div class="upgrades-message" *ngIf="catSelected == subCategory.subCategoryName">
                <ng-container *ngFor="let recommendation of subCategory.recommendations">
                  <div *ngFor="let message of recommendation.messages; index as i" class="upgrades-message__solution">
                    
                    <div class="mb-3 upgrade-details">
                      <i class="fas fa-check"></i>
                      <span class="ms-2">{{ message }}</span>
                    </div>

                    <ul *ngIf="hasExternalLink(recommendation, message)" >
                      <li *ngFor="let externalLink of getExternalLink(recommendation, message)" class="mb-3">
                        <span *ngIf="externalLink.description">{{externalLink.description}}</span>
                        <a [href]="externalLink.link" target="_blank">
                          {{ externalLink.title }}
                        </a>
                      </li>
                    </ul>

                  </div>
                </ng-container>
              </div>
            </div>
          </ul>
          <div class="upgrades-cards"></div>
          <div *ngIf="content == null">
            Merci de remplir une première fois votre questionnaire pour connaitre vos axes d'amélioration
          </div>
        </div>
        <div class="upgrades-page__spacer"></div>
      </div>
    </div>
  </div>
</div>
