<div *ngIf="isReady">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Suppression de Membre</h4>
    <button type="button" class="btn-close" style="outline: none;box-shadow: none;" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="modal-body" [ngClass]="[marque?.slug]">
    <div *ngIf="canDeleteProject">
      <div class="custom-control custom-radio mb-2">
        <input type="radio" id="delete-qr" name="delete_option" [value]="NetworkDeleteResultType.Questionnaire" class="custom-control-input" [(ngModel)]="selectedOption" ngModel>
        <label for="delete-qr" class="custom-control-label">Supprimer ce questionnaire et les réponses associées</label><br>
      </div>
      <div class="custom-control custom-radio">
        <input type="radio" id="delete-user" name="delete_option" [value]="NetworkDeleteResultType.User" class="custom-control-input" [(ngModel)]="selectedOption" ngModel>
        <label for="delete-user" class="custom-control-label">Supprimer ce membre</label><br>
      </div>
    </div>
  
    <p *ngIf="!canDeleteProject">Souhaitez-vous réellement supprimer ce membre&nbsp;?</p>
  
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn" (click)="close()">Annuler</button>
    <button type="button" class="btn btn-danger" (click)="delete()">Supprimer</button>
  </div>
</div>
