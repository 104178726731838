<ns-navbar></ns-navbar>
<div id="body-container">
    <ns-sidebar sidebarType="private"></ns-sidebar>

    <div id="content-wrapper">
        <div *ngIf="ready && readyMarque">
            <h1 style="margin-bottom: 40px;">Documents des membres du réseau</h1>

            <div *ngIf="!hasDocument">
                <span><small>Aucun document disponible pour le moment</small></span>
            </div>

            <div>
                <tree-root [nodes]="documentNodes" #treeView>
                    <ng-template #treeNodeTemplate let-node let-index="index">
                        <div style="margin-bottom:10px">
                            <div *ngIf="!node.data.isDocument">
                                <span>{{ node.data.name }}</span>
                                <span style="margin-left:25px">({{ node.data.cpt }})</span>
                            </div>
                            <div *ngIf="node.data.isDocument">
                                <img [src]="'assets/images/medias/file.svg'" alt="file icon"
                                    style="width: 18px; margin-right: 12px">
                                <span style="margin-right:25px">{{ node.data.label }}</span>
                                <a (click)="downloadDocument(node.data)"
                                    [ngClass]="[marque.slug, 'btn', 'btn-outline btn-inverted btn-reseau-2']">
                                    Télécharger
                                </a>
                            </div>
                        </div>
                    </ng-template>
                </tree-root>
            </div>

        </div>
    </div>
</div>