import {Injectable} from '@angular/core';
import { JsonService } from './json.service';
import { User } from '../models/user.model';
import {Observable, Subject} from 'rxjs';
import {IdentificationStateService} from './identificationstate.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private user: User;
  public user$ = new Subject<User>();

  constructor(
    private jsonService: JsonService,
    private identificationStateService: IdentificationStateService
  ) {
  }

  getUser() {
    if (this.user) {
      return new Observable((observer) => {
        if (this.user) {
          observer.next(this.user);
          observer.complete();
        }
      });
    } else {
      return this.refreshUser();
    }
  }

  refreshUser() {
    const userObservable = this.jsonService.getUser();
    userObservable.subscribe(userResponse => {
      if (userResponse instanceof User) {
        this.user = userResponse;
        this.user$.next(userResponse);
      }
    });

    return userObservable;
  }

  logoutUser() {
    this.identificationStateService.isIdentificationCompleted = false;
    this.user = null;
    this.user$.next(undefined);
  }

  deleteAccount() {
    return this.jsonService.postDeleteAccount();
  }

  notifyFirstLogin() {
    return this.jsonService.notifyFirstLogin();
  }
}
