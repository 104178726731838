import { AfterViewInit, Component } from '@angular/core';
import { JsonService } from "../../../services/json.service";
import { Router } from "@angular/router"
import { ToastrService } from "ngx-toastr"
import { IdentificationField } from "../../../models/identificationField";

@Component({
  templateUrl: './super-admin-create-admin.component.html'
})
export class SuperAdminCreateAdminComponent implements AfterViewInit {
  public form: Array<IdentificationField>
  public ready: boolean = false

  constructor(private api: JsonService, private router: Router, private toastr: ToastrService) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getForm()
    })
  }

  private getForm() {
    this.api.getCreateAdminFormAsSuperAdmin().subscribe(content => {
      this.form = content['form']
      this.ready = true
    })
  }

  postForm(form) {
    this.api.postCreateAdminAsSuperAdmin(form).subscribe(content => {
      if (typeof content['redirect_to'] !== 'undefined') {
        this.toastr.success('L\'administrateur à bien été créé', 'Merci !')
        this.router.navigate([content['redirect_to']])
      } else {
        this.toastr.error(content['error'] || 'Une erreur est survenue', '');
      }
    });
  }
}
