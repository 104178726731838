<ns-navbar></ns-navbar>
<div id="body-container">
  <ns-sidebar sidebarType="admin"></ns-sidebar>
  <div id="content-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="row justify-content-between m-2 mtopbot2">
            <div class="col-lg-8" [ngClass]="[marque.slug, 'reseau-resultat', 'text-colored']">
              <h1 class="titre_backend_reseau">Liste des administrateurs</h1>
              <p>{{ userCount }} administrateurs</p>
            </div>
            <div class="col-lg-4">
              <div class="d-flex justify-content-end">
                <span class="me-3 sort-style">Trier par </span>
                <div ngbDropdown class="d-inline-block">
                  <button [ngClass]="[marque.slug, 'btn btn-outline btn-inverted-white btn-reseau-2']"
                    id="dropdownMenuButton" ngbDropdownToggle>
                    <span id="ordering-title" class="ps-2 pe-3">{{ currentOrderWording }}</span>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="updateOrdering('id', 'Date')">Date</button>
                    <button ngbDropdownItem (click)="updateOrdering('companyName', 'Entreprise')">Nom</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-6">
              <a routerLink="/admin/new-administrator" [ngClass]="[marque.slug, 'btn btn-outline btn-inverted-white btn-reseau-2 mx-4 shadow']" style="line-height: 36px;">
                <span class="btn-reseau-spec-span" style="position:relative;top: 2px;">&nbsp;+&nbsp;</span>
                <span class="pe-3" style="position:relative;top: -2px;">Ajouter un administrateur</span>
              </a>
            </div>
            <div class="col-6">
              <form style="width: 380px; float: right">
                <div class="input-group">
                  <div class="input-group-prepend network-home-searchbar-prepend">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                  <input type="text" class="form-control network-home-searchbar" placeholder="Rechercher un administrateur..." [formControl]="searchFormControl">
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ns-spinner *ngIf="!isReady()"></ns-spinner>
      <div *ngIf="isReady()">
        <div *ngFor="let user of filteredData" class="row">
          <div class="col-md-12">
            <div class="card reseau-card m-4 mb-2 shadow">
              <div class="reseau-card__company">
                {{ user.companyName }}
              </div>
  
              <div class="reseau-card__body">
                <span>{{ user.email }}</span>
              </div>
  
              <div class="reseau-card__buttons">
                <div class="">
                  <a (click)="openDeleteModal(deleteUserModal, user)" [ngClass]="['btn-reseau-danger', 'btn', 'btn-outline btn-inverted btn-reseau-2']" ngbTooltip="Supprimer">
                    <i class="fas fa-trash-alt"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #deleteUserModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Suppression d'utilisateur</h4>
    <button type="button" class="btn-close" style="outline: none;box-shadow: none;" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<span>Voulez-vous vraiment supprimer cet administrateur ?</span>
	</div>
	<div class="modal-footer justify-content-between">
    <button type="button" class="btn" (click)="modal.dismiss()" ngbAutofocus>Annuler</button>
    <button type="button" class="btn btn-danger" (click)="modal.close(true)">
      <i class="fas fa-trash-alt me-2"></i>
      <span>Supprimer</span>
    </button>
  </div>
</ng-template>