
export class SidebarItem {
    constructor(public cssClass: string,
                public url: string,
                public label: string,
                public iconPath: string,
                public isActive: boolean,
                public isHovered: boolean = false
                ) {
    }
}
