import {AfterViewInit, Component} from '@angular/core';
import {UserService} from '../services/user.service';
import {User} from '../models/user.model';
import {Router} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs';

@Component({
  selector: 'ns-cgu',
  templateUrl: '../templates/backend/parameters.component.html'
})
export class ParametersComponent implements AfterViewInit {

  public user: User;
  public ready = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private modalService: NgbModal,
  ) {
  }

  ngAfterViewInit() {
    this.ready = false;
    this.userService.getUser().subscribe(response => {
      if (response instanceof User) {
        this.user = response;
        this.ready = true;

        return this.user;
      }
    });
  }

  openDeleteModal(modal) {
    const modalRef = this.modalService.open(modal, {ariaLabelledBy: 'modal-basic-title', centered: true});
    modalRef.closed.pipe(
      take(1)
    ).subscribe(res => {
      if (res) {
        this.deleteAccount();
      }
    });
  }

  deleteAccount() {
    this.ready = false;
    this.userService.deleteAccount().subscribe(
      response => {
        if (response['deleted'] === true) {
          this.router.navigate(['/register']);
        }},
      error => {
        this.ready = true;
      }
    );
  }
}
