<ns-navbar></ns-navbar>
<div id="body-container"  class="bg-light">
  <ns-sidebar sidebarType="admin"></ns-sidebar>
    <div id="content-wrapper">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-10" class="welcome-dashboard" style="margin-top: 50px ; text-align: center">
                    <h1>Modification de logo</h1>
                    <div class="survey identification container">
                      <form (ngSubmit)="postForm(documentsForm.value)" #documentsForm="ngForm">

                        <div class="form-group row">
                            <label for="modify_logo_users" class="col-sm-4 col-form-label">
                              Utilisateur
                            </label>
                            <div class="col-sm-8">
                              <select [(ngModel)]="selectedOption"  name="modify_logo[users]" id="modify_logo_users" ngModel>
                                <option *ngFor="let user of users" [value]="user.id">
                                  {{ user.companyName }} - {{ user.fullName }}
                                </option>
                              </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label  class="col-sm-4 col-form-label">
                                Logo actuel
                            </label>
                            <div class="col-sm-8">
                                <img [src]="selectedUser?.logoUrl ? assetService.getUrl(selectedUser.logoUrl) : 'assets/images/medias/empty.png'" id="current_logo" style="width: 100px" />
                            </div>
                        </div>

                            <div class="form-group row">
                                <label for="logo" class="col-sm-4 col-form-label">
                                    Modifier le logo
                                </label>
                                <div class="col-sm-8">
                                  <input ngModel (change)="fileSelected($event)" type="file" id="logo" name="modify_logo[logo]" required="required" class="custom-file-input">
                                </div>
                        </div>

                        <p><input type="submit" value="Modifier le logo"/></p>
                    </form>
                    </div>

                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
    </div>
</div>
