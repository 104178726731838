import { AfterViewInit, Component } from '@angular/core';
import { JsonService } from "../services/json.service";
import { MarqueService } from "../services/marque.service"
import { Marque } from "../models/marque.model"
import { SubCategory } from "../models/subcategory.model"
import { PdfService } from '../services/pdf.service';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';

@Component({
  selector: 'ns-upgrades',
  templateUrl: '../templates/upgrades.component.html',
  styleUrls: ['../../assets/scss/pages/_upgrades.scss']
})

export class UpgradesComponent implements AfterViewInit {
  title = 'angularfront'

  public user: User;
  public catSelected: string;
  public content: any;
  public ready: boolean = false;
  public marque: Marque;
  public launchAnimation: boolean;
  public colorMarqueOnly: boolean = false;
  public downloadButtonState: 'download-bp-enabled' | 'download-bp-disabled' = 'download-bp-enabled';
  public isDownloading: boolean = false;

  private adn: any;

  constructor(
    private api: JsonService,
    private marqueService: MarqueService,
    private userService: UserService,
    private pdfService: PdfService,
  ) {
    this.catSelected = null;
    this.content = [];
  }

  ngOnInit(): void {
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response;
        if (this.marque.slug === 'grandlyon') {
          this.colorMarqueOnly = true
        }
      }
    })

    this.userService.getUser().subscribe(response => {
      if (response instanceof User) {
        this.user = response;
      }
    });

    this.api.getAdn().subscribe(data => {
      this.adn = data.adn;
    });
  }

  ngAfterViewInit(): void {
    this.getUpgrades();
  }

  getUpgrades() {
    this.api.getUpgrades().subscribe(content => {
      this.content = content;
      this.ready = true;
      setTimeout(() => { this.launchAnimation = true; }, 100);
    });
  }

  downloadUpgrades() {
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.downloadButtonState = 'download-bp-disabled';
    this.pdfService.downloadPdf(this.api.getUpgradesPdf(), this.user.companyName, new Date(this.adn.dateSaved.date), 'RECOMMANDATIONS').finally(() => {
      this.isDownloading = false;
      this.downloadButtonState = 'download-bp-enabled';
    })
  }

  public calculateWidth(pourcentage) {
    if (this.launchAnimation) {
      return { width: pourcentage + '%' };
    }
  }

  getGradientClass(subCategory: object) {
    return !this.colorMarqueOnly ? 'bg-gradient-' + subCategory['categoryNameSlug'] : ''
  }

  hasExternalLink(recommendation, msg) {
    return !!this.getExternalLink(recommendation, msg)
  }

  getExternalLink(recommendation, msg) {
    return recommendation?.externalLinks?.[msg];
  }
}
