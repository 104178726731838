<ns-navbar></ns-navbar>
<div id="body-container"  >
  <ns-sidebar sidebarType="admin"></ns-sidebar>
  <div id="content-wrapper" *ngIf="ready">
        <form (ngSubmit)="postForm(identificationForm)" #identificationForm="ngForm">
            <div class="survey-header">
                <h1>
                  <i class="fa-solid fa-city me-3"></i>
                  {{ 'Formulaire d\'identification' | titlecase }}
                </h1>
                <div class="survey-header-bottom">
                    <p>Remplissez ce formulaire pour une évaluation adaptée à votre entreprise</p>
                </div>
            </div>

            <div class="survey-content">
                <div class="form-group" *ngFor="let field of form">
                    <label [attr.for]="field.id" class="col-sm-4 col-form-label">{{field.label}} *</label>
                    <div class="col-sm-8">
                      <input *ngIf="field.type == 'text' && field.id != 'certificationsRse'" ngModel type="text" [id]="field.id" [name]="'test_mapping['+field.id+']'"
                             maxlength="255" class="form-control form-identification form-control ap-input" [placeholder]="field.label" autocomplete="off" spellcheck="false"
                             role="combobox" aria-autocomplete="both" aria-expanded="false" aria-owns="algolia-places-listbox-0" dir="auto" style="position: relative; vertical-align: top;"
                      >

                      <select *ngIf="field.type == 'choice' && field.id != 'certificationsRse'" ngModel [id]="field.id" [name]="'test_mapping['+field.id+']'"  class="form-control form-identification form-control">
                        <option *ngFor="let choice of field.choices" [value]="choice.value" selected="selected">{{choice.label}}</option>
                      </select>

                      <select2 *ngIf="field.id == 'certificationsRse'" ngModel name="test_mapping[certificationsRse]" [data]="data" [multiple]="true">
                      </select2>
                    </div>
                </div>
            </div>

                <div class="survey-content-submit text-center">
                    <button type="submit" class="btn btn-lg">VALIDER</button>
                </div>
        </form>
    <div>
      <p>QUESTIONNAIRE MATCHE :
        <span *ngIf="result['idMatched']" style="color: green">
          {{ result['idMatched'] }}
        </span>
        <span *ngIf="!result['idMatched']" style="color: red">
          AUCUN
        </span>
    </div>
    <table class="table table-striped table-sm">
      <ng-container *ngFor="let questionnaire of result['result']">
        <tr>
          <th colspan="2">
            {{ questionnaire['name'] }}
          </th>
        </tr>
          <tr *ngFor="let failure of questionnaire['failure']">
            <td style="background-color: #e08282">{{ failure }}</td>
            <td style="color: red; background-color: #e08282">X</td>
          </tr>
          <tr *ngFor="let success of questionnaire['success']">
            <td style="background-color: #bbefb4">{{ success }}</td>
            <td style="color: green; background-color: #bbefb4">✔</td>
          </tr>
      </ng-container>
    </table>
    </div>
</div>

