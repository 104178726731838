<ns-navbar></ns-navbar>
<div id="body-container" class="bg-light">
  <ns-sidebar sidebarType='admin'></ns-sidebar>
  <div id="content-wrapper" style="margin-top:50px;">
    <div class="container-fluid">
      <ns-spinner *ngIf="!ready"></ns-spinner>
      <ng-container *ngIf="ready">
        <div class="col-md-12 col-lg-12">
          <div class="d-flex justify-content-between">
            <h1>
              Aides à disposition
            </h1>
            <button class="btn btn-primary btn-sm" (click)="openModal()">Ajouter</button>
          </div>


          <table class="table table-striped">
            <thead>
              <tr>
                <td>#</td>
                <td>Titre</td>
                <td style="width: 20%;">Actions</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let assistanceSystem of assistanceSystems">
                <td>{{assistanceSystem.id}}</td>
                <td>{{assistanceSystem.title}}</td>
                <td>
                  <button class="btn btn-secondary btn-sm" (click)="openModal(assistanceSystem)">Editer</button>
                  <button class="btn btn-danger btn-sm"
                    (click)="deleteAssistanceSystem(assistanceSystem)">Supp.</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #assistanceSystemModal let-modal>
  <form [formGroup]="assistanceSystemForm">
    <div class="modal-body">
      <div class="form-group">
        <label for="title">Titre</label>
        <input type="text" class="form-control" formControlName="title" />
      </div>
      <div class="form-group">
        <label for="description">Description</label>
        <textarea class="form-control" formControlName="description"></textarea>
      </div>
      <div class="form-group">
        <label for="link">Lien</label>
        <input type="text" class="form-control" formControlName="link" />
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn" (click)="modal.dismiss('cancel')">Annuler</button>
      <button type="submit" class="btn btn-success" (click)="modal.close('submit')">Valider</button>
    </div>
  </form>
</ng-template>