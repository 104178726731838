<ns-navbar></ns-navbar>
<div id="body-container">
  <ns-sidebar></ns-sidebar>
  <div id="content-wrapper" *ngIf="marqueReady">
    <div [ngClass]="[marque.slug, 'survey', 'identification', 'container']" *ngIf="formReady">
      <form (ngSubmit)="postForm(enterpriseDirectoryForm)" #enterpriseDirectoryForm="ngForm">
        <div class="survey-header">
          <h1>
            <i class="fa-solid fa-city me-3"></i>
            Formulaire d'inscription à l'annuaire
          </h1>
          <div class="survey-header-bottom pt-4 pb-4">
            <p>Remplissez ce formulaire pour faire apparaître votre entreprise dans l'annuaire.</p>
          </div>
        </div>
        <div class="survey-content-identification mt-4">

          <div *ngFor="let field of form">

            <div class="form-group has-validation identification-form mt-4"><!-- ngStyle flex ..... -->
              <label [attr.for]="field.id" class="col-sm-4 col-form-label">{{field.label}}{{field.required ? '&nbsp;*' :
                ''}}&nbsp;:</label>
              <div class="col-sm-11">
                <input *ngIf="field.type == 'text' && field.id !== 'description'" [(ngModel)]="field.value" type="text" [id]="field.id"
                  [name]="'enterprise_directory_form['+field.id+']'"
                  [ngClass]="[formFieldHasError(enterpriseDirectoryForm, field.id) ? 'is-invalid' : 'is-valid']"
                  maxlength="{{field.id === 'contact' ? '10' : '255'}}" minlength="{{field.id === 'contact' ? '10' : '3'}}"
                  class="form-control form-identification form-control ap-input"
                  [placeholder]="field.label" autocomplete="off" spellcheck="false" role="combobox"
                  aria-autocomplete="both" aria-expanded="false" aria-owns="algolia-places-listbox-0" dir="auto"
                  style="position: relative; vertical-align: top;" [required]="field.required"
                  (keypress)="field.id === 'contact' ? ($event.charCode >= 48 && $event.charCode < 58) : ''">

                  <textarea *ngIf="field.id == 'description'" [(ngModel)]="field.value" [id]="field.id"
                  [name]="'enterprise_directory_form['+field.id+']'"
                  [ngClass]="[formFieldHasError(enterpriseDirectoryForm, field.id) ? 'is-invalid' : 'is-valid']"
                  maxlength="260" minlength="10" rows="6"
                  class="form-control form-identification form-control ap-input"
                  [placeholder]="field.label" autocomplete="off" spellcheck="false" role="combobox"
                  aria-autocomplete="both" aria-expanded="false" aria-owns="algolia-places-listbox-0" dir="auto"
                  style="position: relative; vertical-align: top;" [required]="field.required"></textarea>

                  <input *ngIf="field.type == 'file'" 
                  [(ngModel)]="field.value" [id]="field.id" [name]="'enterprise_directory_form['+field.id+']'"
                  type="file" class="btn" (change)="fileSelected($event)" accept="image/*">

                <div *ngIf="formFieldHasError(enterpriseDirectoryForm, field.id)" class="invalid-feedback">* Ce champs
                  doit être renseigné</div>
              </div>
            </div>
          </div>
        </div>

        <div class=" text-center">
          <button type="button" class="btn btn-outline-secondary me-3" routerLink="/directory">Annuler</button>

          <a *ngIf="isEditionMode" (click)="openDeleteModal(deleteModal)"
            [ngClass]="['btn-reseau-danger', 'btn btn-outline btn-inverted me-3']">
            Supprimer mon entreprise
          </a>

          <button type="submit" [ngClass]="[marque.slug, 'btn', 'btn-primary']" [disabled]="!enterpriseDirectoryForm.valid">VALIDER</button>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #deleteModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Retrait de mon entreprise dans l'annuaire</h4>
    <button type="button" class="btn-close" style="outline: none;box-shadow: none;" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<p>Voulez-vous retirer votre entreprise de l'annuaire ?</p>
    <p>Vous conserverez l'accès à votre compte ainsi que toutes vos données mais votre entreprise ne sera plus présente dans l'annuaire.
    </p>
	</div>
	<div class="modal-footer justify-content-between">
    <button type="button" class="btn" (click)="modal.dismiss()" ngbAutofocus>Annuler</button>
    <button type="button" class="btn btn-danger" (click)="modal.close(true)">
      <i class="fas fa-trash-alt me-2"></i>
      <span>Supprimer</span>
    </button>
  </div>
</ng-template>