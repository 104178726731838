import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserService } from "../services/user.service";
import { User } from "../models/user.model";

@Injectable()
export class IsSuperAdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return this.userService.getUser().pipe(
      map((user: User) => {
        if (user.isAdmin() && user.isSuperAdmin) {
          return true;
        }

        this.toastr.info('Vous n\'avez pas accès à cet espace.');
        this.router.navigate(['/']);
        return false;
      })
    ).toPromise();
  }
}
