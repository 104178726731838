<ns-navbar></ns-navbar>
<div id="body-container"  >
  <ns-sidebar></ns-sidebar>
    <div id="content-wrapper" class="page-suivi parameters-page">
      <ns-spinner *ngIf="!ready"></ns-spinner>
      <div *ngIf="ready" class="px-5">
        <div class="parameter-cards-container">
          <div class="card">
            <div class="card-header">
              <div class="card-header__title">
                Mon Profil
              </div>
              <div class="card-header__action">
                <a [routerLink]="['/identification', {editmode: true}]" title="Éditer mon profil">
                  <i class="far fa-edit"></i>
                </a>
              </div>
            </div>
            <div class="card-body">
              <p *ngIf="user?.companyName">
                <span class="user-parameter-title">Nom d'entreprise&nbsp;:</span> {{ user?.companyName }}
              </p>
              <p *ngIf="user?.nbEmployee">
                <span class="user-parameter-title">Taille&nbsp;:</span> {{ user?.nomenclatures?.nb_employee[user?.nbEmployee] }}
              </p>
              <p *ngIf="user?.businessSegment">
                <span class="user-parameter-title">Secteur&nbsp;:</span> {{ user?.nomenclatures?.business_segment[user?.businessSegment] }}
              </p>
              <p *ngIf="user?.status">
                <span class="user-parameter-title">Statut&nbsp;:</span> {{ user?.nomenclatures?.status[user?.status] }}
              </p>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <div class="card-header__title">
                Mon compte
              </div>
            </div>
            <div class="card-body">
              <p>
                <a [routerLink]="[]" (click)="deleteAccount()">Supprimer mon compte</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
