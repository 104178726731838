<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Aide</h4>
    <button type="button" class="btn-close" style="outline: none;box-shadow: none;" aria-label="Close" (click)="activeModal.dismiss('cancel')"></button>
  </div>
  <div class="modal-body">
    <p style="white-space: pre-wrap">{{ helpMessage }}</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn" (click)="activeModal.dismiss('cancel')">Fermer</button>
    <a *ngIf="helpLink" href="{{ helpLink }}" target="_blank" class="btn btn-success">Plus d'information</a>
  </div>
</div>
