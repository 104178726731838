export class IdentificationField {

    constructor(public type?: string,
                public label?: string,
                public name?: string,
                public id?: string,
                public choices?: IdentificationFieldChoice[],
                public value?: string,
                public required: boolean = false
                ) {
    }
}

export class IdentificationFieldChoice {
  constructor(
    public label?: string,
    public value?: string,
  ) {
  }
}
