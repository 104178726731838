<!--{% extends 'backend/backend_base.html.twig' %}

{% block title %}ADN{% endblock %}
    {% block sidebar %}
        {% include('backend/partials/_sidebar.html.twig') with {'smallMenu': false} %}
    {% endblock %}
{% block body %}-->
<ns-navbar></ns-navbar>
<div id="body-container"  >
  <ns-sidebar sidebarType="admin"></ns-sidebar>
  <div *ngIf="ready" id="content-wrapper" class="page-adn">

    <!-- {% if score is not null and score | length > 0 %} -->
    <div id="adn-diag" *ngIf="hasAdn">
      <div class="diagnostic container">

        <div class="diagnostic container">
          <div class="row">
            <p class="adn-evaluation-date" *ngIf="dateSaved">Date d'évaluation : {{ dateSaved | date: 'dd/MM/YYYY'}}</p>
          </div>
          <div class="row diagnostic-header">
            <div [ngClass]="[marque.slug, 'diagnostic-info']">
              <div class="diagnostic-info-head">
                <div class="diagnostic-info-head-img" >
                  <img src="assets/images/medias/building-new.png" title="" class="img-fluid ">
                </div>
                <div class="diagnostic-info-head-title">
                  <h1 class="h3">{{ user?.companyName | titlecase }}</h1>
                  <p class="text-primary">Secteur : {{ user?.businessSegment | titlecase}}</p>
                </div>
              </div>
              <p class="diagnostic-info-desc">
                <button type="button" (click)="downloadExportPdf()" class="btn btn-link" [ngClass]="[downloadButtonState]">
                  <i class="fas fa-download"></i>
                  <span>Télécharger le diagnostic (pdf)</span>
                </button>
                <button type="button" (click)="downloadUpgradesPdf()" class="btn btn-link" [ngClass]="[downloadButtonState]">
                  <i class="fas fa-download"></i>
                  <span>Télécharger les recommandations (pdf)</span>
                </button>
                <button type="button" (click)="downloadFullQrPdf()" class="btn btn-link" [ngClass]="[downloadButtonState]" *ngIf="marque.slug === 'lille'">
                  <i class="fas fa-download"></i>
                  <span>Télécharger le rapport complet (pdf)</span>
                </button>
              </p>
            </div>
            <div [ngClass]="[marque.slug, 'diagnostic-global']">
              <div class="diagnostic-global-details">
                <p *ngFor="let category of adn['form']">
                  <span>{{ category.name}}</span>
                  <span class="subcategory-score">{{ category['pourcentage']|  number: '1.0-0' }}/100</span>
                </p>
              </div>
              <div class="diagnostic-global-note">
                <div class="progress-bar-circle-wrapper">
                  <div class="progress-bar-circle index_0" [attr.data-copy]="adn['pourcentage']" style="position: relative;">
                    <svg viewBox="0 0 100 100" style="display: block; width: 100%;">
                      <path d="M 50,50 m 0,-46 a 46,46 0 1 1 0,92 a 46,46 0 1 1 0,-92" stroke="#eee" stroke-width="4" fill-opacity="0"></path>
                      <path d="M 50,50 m 0,-46 a 46,46 0 1 1 0,92 a 46,46 0 1 1 0,-92" class="progress-bar-circle-indicator" stroke-width="8" fill-opacity="0" [ngStyle]="calculateCircleAnime(adn['pourcentage'])"></path>
                    </svg>
                    <div [ngClass]="[marque.slug, 'progressbar-text']" >{{ adn['pourcentage'] |  number: '1.0-0' }}</div>
                    </div>
                </div>
                <div *ngIf="false && sectorPositioning">
                  Moyenne sur ce secteur d'activité : {{ sectorPositioning }}
                </div>
              </div>
            </div>
          </div>

          <!-- {% for category in score.categories %} -->
          <div *ngFor="let category of adn['form']" class="diagnostic-domain-shadow-elisa {{ category.family ? 'domain-family-'+category.family : ''}} {{ category.name | slugify }} {{ marque.slug }}">
            <div class="diagnostic-domain-title ">
                <h2 class="text-{{ category.name | lowercase }}">{{ category.name }}</h2>
                <span class="diagnostic-domain-title-note text-{{ category.name | lowercase }}">
                    {{ category['pourcentage']|  number: '1.0-0' }}/100
                </span>
            </div>

            <div class="diagnostic-domain-sub">
              <!-- {% for subCategory in category.subcategories %} -->
              <div *ngFor="let subCategory of category['subcategories']; index as i;" class="subdomain">
                <div class="subdomain-header" (click)="collapseExpand(category.name, i)">
                  <div class="subdomain-header-title">
                    <span>{{ subCategory.name }}</span>
                    <span class="d-block d-md-none"><i class="fas fa-chevron-down ms-3"></i></span>
                  </div>
                  <div class="progress subdomain-header-progress" [ngClass]="[marque?.slug]">
                    <div class="progress-bar" role="progressbar" [ngStyle]="calculateWidth(subCategory.pourcentage)"></div>
                    </div>
                  <div class="subdomain-header-link d-none d-md-block {{marque?.slug}} text-colored">
                    détails <i class="fas fa-chevron-down ms-3"></i>
                  </div>
                  <div class="subdomain-header-note">
                    {{ subCategory['pourcentage'] |  number: '1.0-0' }}/100
                  </div>
                </div>

                <div id="collapse_{{ category.name | slugify }}_{{ i }}" class="custom-collapse">
                  <div class="subdomain-body">
                    <div *ngFor="let question of subCategory['questions']" class="subdomain-body-row">
                      <span class="criteria">{{ question.label }}</span>
                      <span class="{{marque?.slug}} awnser">
                        <span *ngFor="let choice of question['choices']">
                          <span class="d-block" *ngIf="choice.isSelected">
                            <i class="fas fa-chevron-right fa-xs"></i>
                            <span class="ms-2">{{ choice.label }}</span>
                          </span>
                        </span>
                        <span *ngIf="question['comment']">
                          <span class="ms-2"># {{ question.comment }}</span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
