import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IdentificationField } from 'src/app/models/identificationField';
import { Marque } from 'src/app/models/marque.model';
import { User } from 'src/app/models/user.model';
import { JsonService } from 'src/app/services/json.service';
import { MarqueService } from 'src/app/services/marque.service';

@Component({
  selector: 'ns-network-member-new-project',
  templateUrl: '../../templates/backend/network/network-member-new-project.component.html'
})
export class NetworkMemberNewProjectComponent implements OnInit {
  public member: User;
  public marque: Marque;
  public form: Array<IdentificationField>;
  public ready: boolean = false;

  constructor(
    public jsonService: JsonService,
    private marqueService: MarqueService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const membreReseauId = +params.get('membreReseauId');
        return forkJoin(
          {
            member: this.jsonService.getNetworkUserById(membreReseauId),
            marque: this.marqueService.getMarque(),
            form: this.jsonService.getNetworkMemberNewProjectForm(membreReseauId)
          }
        )
      })
    ).subscribe(({member, marque, form}) => {
      this.member = member as User;
      this.marque = marque as Marque;
      this.form = form['form'];
      this.ready = true;
    })
  }

  isFormValid(form): boolean {
    return 'add_new_project_for_membre_reseau_form[investmentFund]' in form &&
      form['add_new_project_for_membre_reseau_form[investmentFund]'];
  }

  putForm(form) {
    this.jsonService.putNetworkMemberNewProject(this.member.id, form).subscribe(content => {
      if (typeof content['redirect_to'] !== 'undefined') {
          this.toastr.success('Nouveau projet créé avec succès', 'Merci !')
          this.router.navigate(['/'+content['redirect_to']])
      } else {
        this.toastr.error(content['error'], '');
      }
    });
  }
}

