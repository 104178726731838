<ns-navbar></ns-navbar>
<div id="body-container">
    <ns-sidebar></ns-sidebar>
    <div id="content-wrapper">
        <div [ngClass]="[marque.slug, 'survey', 'identification', 'container']" *ngIf="ready">
            <form (ngSubmit)="postForm(identificationForm)" #identificationForm="ngForm">
                <div class="survey-header">
                    <h1>
                        <i class="fa-solid fa-city me-3"></i>
                        {{ 'Formulaire d\'identification' }}
                    </h1>
                    <div class="survey-header-bottom pt-4 pb-4">
                        <p>Remplissez ce formulaire pour une évaluation adaptée à votre entreprise</p>
                    </div>
                </div>
                <div class="survey-content-identification mt-4">

                    <div *ngIf="isSiretOptional">
                        <div class="form-group has-validation identification-form" style="display: flex;">
                            <!-- Add radio button SIRET / SIREN -->
                            <label [attr.for]="'user-has-optional-siret'" class="col-sm-4 col-form-label">L'entreprise est-elle immatriculée&nbsp;?</label>
                            <div class="col-sm-8">
                                <div class="custom-radio custom-control">
                                    <input class="custom-control-input" type="radio" name="userHasOptionalSiret" value="false"
                                        id="paca-has-siret" [(ngModel)]="userHasOptionalSiret" checked>
                                    <label class="custom-control-label" for="paca-has-siret">Non</label>
                                </div>
                                <div class="custom-radio custom-control">
                                    <input class="custom-control-input" type="radio" name="userHasOptionalSiret" value="true"
                                        id="paca-doenst-have-siret" [(ngModel)]="userHasOptionalSiret">
                                    <label class="custom-control-label" for="paca-doenst-have-siret">Oui</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="hasSiretOrSirenFeature(identificationForm) && isOptionalSiretSelected()">
                        <div class="form-group has-validation identification-form" style="display: flex;">
                            <!-- Add radio button SIRET / SIREN -->
                            <label [attr.for]="'siretLength14'" class="col-sm-4 col-form-label">Système
                                d'identification&nbsp;:</label>
                            <div class="col-sm-8">
                                <div class="custom-radio custom-control">
                                    <input class="custom-control-input" type="radio" name="siretLength" value="14"
                                        id="siretLength14" [(ngModel)]="expectedSiretLength" checked>
                                    <label class="custom-control-label" for="siretLength14">SIRET</label>
                                </div>
                                <div class="custom-radio custom-control">
                                    <input class="custom-control-input" type="radio" name="siretLength" value="9"
                                        id="siretLength9" [(ngModel)]="expectedSiretLength">
                                    <label class="custom-control-label" for="siretLength9">SIREN</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngFor="let field of form">

                        <div *ngIf="field.id == 'addressStreet'">
                            <!-- Address Section Title -->
                            <h3 class="identification-section-title">Adresse postale</h3>
                        </div>

                        <div *ngIf="field.id == 'phone'">
                            <!-- Address Section Title -->
                            <h3 class="identification-section-title">Contact</h3>
                        </div>

                        <div *ngIf="field.id == 'lastname'">
                            <!-- Address Section Title -->
                            <h3 class="identification-section-title">Vos coordonnées</h3>
                        </div>

                        <div *ngIf="field.id === 'siret' ? isOptionalSiretSelected() : field.id !== 'whyAreYouHere'" class="form-group has-validation identification-form mt-4"
                            [ngStyle]="{'display': field.id == 'companyName' && !showCompanyNameInput ? 'none' : 'flex'}">
                            <label [attr.for]="field.id" class="col-sm-4 col-form-label">{{field.id === 'siret' &&
                                isSiren() ? 'Siren' : field.label}}{{field.required ? '&nbsp;*' : ''}}&nbsp;:</label>
                            <div class="col-sm-8">
                                <input *ngIf="field.type == 'text' && field.id != 'certificationsRse'"
                                    [(ngModel)]="field.value" type="text" [id]="field.id"
                                    [name]="'identification_form['+field.id+']'"
                                    [ngClass]="[formFieldHasError(identificationForm, field.id) ? 'is-invalid' : 'is-valid']"
                                    maxlength="{{ field.id === 'siret' ? expectedSiretLength : field.id === 'phone' ? '10' : '255'}}"
                                    minlength="{{ field.id === 'siret' ? expectedSiretLength : field.id === 'phone' ? '10' :'0'}}"
                                    class="form-control form-identification form-control ap-input"
                                    [placeholder]="field.id === 'siret' && isSiren() ? 'Siren' : field.label"
                                    autocomplete="off" spellcheck="false" role="combobox" aria-autocomplete="both"
                                    aria-expanded="false" aria-owns="algolia-places-listbox-0" dir="auto"
                                    style="position: relative; vertical-align: top;" [required]="field.required || (field.id === 'siret' && isOptionalSiretSelected())"
                                    (keypress)="field.id === 'siret' || field.id === 'phone' ? ($event.charCode >= 48 && $event.charCode < 58) : ''"
                                    (keyup)="field.id === 'siret' ? onSiretKeyup(identificationForm) : ''">

                                <select2 *ngIf="field.type == 'choice' && field.id != 'certificationsRse'"
                                    [ngClass]="[formFieldHasError(identificationForm, field.id) ? 'is-invalid' : 'is-valid']"
                                    ngModel [id]="field.id" [name]="'identification_form['+field.id+']'"
                                    [data]="field.choices" [required]="field.required">
                                </select2>

                                <select2 *ngIf="field.id == 'certificationsRse'" ngModel
                                    name="identification_form[certificationsRse]" [data]="data" [multiple]="true">
                                </select2>

                                <div *ngIf="field.id !== 'siret' && formFieldHasError(identificationForm, field.id)"
                                    class="invalid-feedback">* Ce champs doit être renseigné</div>
                                <div *ngIf="field.id === 'siret'" class="invalid-feedback">
                                    {{getSiretErrorMessage(identificationForm)}}</div>
                            </div>
                        </div>

                        <div *ngIf="field.id === 'whyAreYouHere'" class="form-group has-validation identification-form mt-4">
                            <label [attr.for]="field.id" class="col-sm-4 col-form-label">{{field.label}}&nbsp;*&nbsp;:</label>
                            <textarea
                                [id]="field.id"
                                [name]="'identification_form['+field.id+']'"
                                [placeholder]="field.label"
                                [ngClass]="[formFieldHasError(identificationForm, field.id) ? 'is-invalid' : 'is-valid']"
                                [(ngModel)]="field.value"
                                class="form-control form-identification form-control ap-input mt-2"
                                rows="5"
                                maxlength="1023"
                                required
                            >
                                {{field.label}}
                            </textarea>
                        </div>

                    </div>

                    <div *ngIf="marque?.slug === 'lille'">
                        <!-- RGPD -->
                        <p class="identification-rgpd-info"><b>RGPD :</b>
                            Les informations recueillies dans le cadre de ce questionnaires seront traitées par le Pôle
                            Developpement Economique et Emploi et resteront strictement réservées à un usage interne.
                            Conformément aux dispositions du RGPD et de la loi n° 78-17 du 6 janvier 1978 relative à
                            l'informatique, aux fichiers et aux libertés, vous pouvez exercer votre droit d’accès, de
                            rectification et d’effacement des données vous concernant, de définir des directives
                            relatives au sort de celles-ci après votre décès et de limitation du traitement et au
                            retrait de votre consentement, en vous adressant au Délégué à la Protection des Données que
                            la MEL a désigné : Protectdonneesperso@lillemetropole.fr avec copie au service Animation et
                            Service : forcedecontact@lillemetropole.fr
                        </p>
                    </div>
                </div>

                <div class="survey-content-submit text-center">
                    <button type="submit" class="btn btn-lg" [disabled]="!identificationForm.valid">VALIDER</button>
                </div>
            </form>
        </div>
    </div>
</div>
