<div *ngIf="user && marque?.hasSupportEnabled && showButtonForUserType && showButtonForCurrentUrl" class="sb-container">
    <div class="sb-icon" [ngClass]="[marque?.slug, 'text-colored']" ngbTooltip="Obtenir de l'aide"
        (click)="open(sbModalContent)">
        <span class="fa-stack fa-2x">
            <i class="fas fa-comment-alt fa-stack-2x sb-icon-comment"></i>
            <i class="fas fa-question fa-stack-1x fa-inverse sb-icon-questionmark"></i>
        </span>
    </div>
</div>

<ng-template #sbModalContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Obtenir de l'aide</h4>
        <a class="sb-btn-close text-colored" [ngClass]="[marque?.slug]" (click)="modal.dismiss()">
            <i class="fas fa-times"></i>
        </a>
    </div>
    <div class="modal-body">
        <form [formGroup]="sbFormGroup">
            <div class="mb-3">
                <label for="sb-modal-content">Poser une question au support pour obtenir de l'aide</label>
                <div class="input-group sb-input-group">
                    <label for="sbInputEmail" class="sb-input-label">
                        Votre Email
                    </label>
                    <input type="email" formControlName="email" name="email" id="sbInputEmail" class="form-control" placeholder="email@example.com" required>
                </div>
                <div class="input-group sb-input-group">
                    <label for="sbInputContent" class="sb-input-label">
                        Message
                    </label>
                    <textarea id="sb-modal-content" name="msg-content" id="sbInputContent" class="form-control" rows="5" maxlength="2000"
                        formControlName="question"></textarea>
                </div>
                <div class="sb-modal-counter">
                    <span>{{sbFormGroup.get('question')?.value?.length || 0}}/2000</span>
                </div>
                <div class="sb-modal-info">
                    Vous recevrez une réponse sous 48 heures.
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Annuler</button>
        <button type="button" [ngClass]="[marque?.slug]" class="btn btn-primary"
            (click)="submitForm()" [disabled]="!sbFormGroup?.valid">Envoyer le message</button>
    </div>
</ng-template>