
<!--Bonnes pratiques RSE-->

<ns-navbar></ns-navbar>
<div *ngIf="ready" id="body-container">
  <ns-sidebar></ns-sidebar>
      <div id="content-wrapper" class="container good-practices-container overflow-visible " [ngClass]="{ 'blur-effect': !user?.offer}" *ngIf="hasLoaded">
          <div class="row mb-5">
              <div class="col-sm-6">
                  <h1 class="titre_backend">Ressources</h1>
              </div>
              <div class="col-sm-6 d-flex align-items-center justify-content-end">
                  <div class="dropdown" ngbDropdown>
                      <button class="btn btn-reseau dropdown-toggle me-2 px-3 py-1 shadow-sm"
                              type="button" id="dropdownMenuButton" ngbDropdownToggle
                              >Tout
                      </button>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                          <a class="dropdown-item" href="#" ngbDropdownItem>Action</a>
                          <a class="dropdown-item" href="#" ngbDropdownItem>Another action</a>
                          <a class="dropdown-item" href="#" ngbDropdownItem>Something else here</a>
                      </div>
                  </div>
              </div>
          </div>

      <div class="practices">
        <div class="practices__element " *ngFor="let block of goodPractices; let indexPractices = index" >
          <div class="container-clickable" >
            <img  src='{{"/assets/images/medias/good-practices/" + block.image }}' />
            <div class="container-clickable__arrow"><img  src="/assets/images/medias/good-practices/short_right.svg"/></div>
            <div class="container-clickable__sous-section">
                  <label>{{ block.title  }}</label>
                  <label>Lecture: {{ block.temps }} min</label>
                </div>
            </div>
        </div>
      </div>
      </div>
</div>
