import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {JsonService} from '../../../services/json.service';
import {AssistanceSystem} from '../../../models/assistance_systems.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'ns-admin-assistance-systems',
  templateUrl: './admin-assistance-systems.component.html'
})

export class AdminAssistanceSystemsComponent implements OnInit{
  @ViewChild('assistanceSystemModal') assistanceSystemModal: ElementRef;

  public assistanceSystems: Array<AssistanceSystem>;
  public ready = false;
  assistanceSystemForm: UntypedFormGroup;
  private baseFormValues = {
    id: null,
    title: '',
    description: '',
    link: '',
  };

  constructor(
    private api: JsonService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.api.getAssistanceSystems().subscribe(assistanceSystems => {
      this.assistanceSystems = assistanceSystems;
      this.ready = true;
    });
  }

  openModal(assistanceSystem: AssistanceSystem|null = null) {
    if (null !== assistanceSystem) {
      this.assistanceSystemForm.patchValue(assistanceSystem);
    }

    this.modalService.open(this.assistanceSystemModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'lg',
      windowClass: 'has-already-login-modal',
      container: '.app-body',
    }).result.then(() => {
      this.submitForm();
    });
  }

  private createForm() {
    this.assistanceSystemForm = this.formBuilder.group(this.baseFormValues);
  }

  submitForm() {
    if (null !== this.assistanceSystemForm.value.id) {
      this.updateAssistanceSystem();
    } else {
      this.createAssistanceSystem();
    }
  }

  createAssistanceSystem() {
    this.api.createAssistanceSystem(this.assistanceSystemForm.value).subscribe(assistanceSystem => {
        this.assistanceSystems.push(assistanceSystem);
        this.assistanceSystemForm.patchValue(this.baseFormValues);
        this.toastr.success('Le dispositif d\'assistance a bien été ajouté', 'Merci !');
      },
      error => {
        this.toastr.error('Une erreur s\'est produite pendant l\'ajout du dispositif', 'Erreur');
      }
    );
  }

  updateAssistanceSystem() {
    this.api.updateAssistanceSystem(this.assistanceSystemForm.value).subscribe(result => {
        const index = this.assistanceSystems.findIndex(as => as.id === result.id);
        this.assistanceSystems[index] = result;

        this.assistanceSystemForm.patchValue(this.baseFormValues);
        this.toastr.success('Le dispositif d\'assistance a bien été modifié', 'Merci !');
      },
      error => {
        this.toastr.error('Une erreur s\'est produite pendant la modification du dispositif', 'Erreur');
      }
    );
  }

  deleteAssistanceSystem(assistanceSystem: AssistanceSystem) {
    this.api.deleteAssistanceSystem(assistanceSystem).subscribe(result => {
        const index = this.assistanceSystems.findIndex(as => as.id === assistanceSystem.id);
        this.assistanceSystems.splice(index, 1);

        this.assistanceSystemForm.patchValue(this.baseFormValues);
        this.toastr.success('Le dispositif d\'assistance a bien été supprimé', 'Merci !');
      },
      error => {
        this.toastr.error('Une erreur s\'est produite pendant la suppression du dispositif', 'Erreur');
      }
    );
  }
}
