<ns-navbar></ns-navbar>
<div id="body-container" *ngIf="marqueReady">
  <ns-sidebar></ns-sidebar>

  <div id="content-wrapper" [ngClass]="[marque?.slug, 'page-documents']">
    <div class="container container-padding">
      <h1 class="titre_backend">Vos documents</h1>
      <small>Formats autorisés : {{ allowedTypes?.join(', ') }}</small>

      <div class="documents" *ngIf="formReady">
        <form #documentsForm="ngForm">

          <div class="documents-item" *ngFor="let document of form">
            <div class="documents-item-name" [class]="{'exist': document['value']['attr']['data-valid'] === 'true'}">
              <div>
                {{ document['value']['title'] }}
                <span class="ms-3" *ngIf="document['value']['attr']['data-uploaded'] === 'true'"><i class="fas fa-check"></i></span>
              </div>
            </div>
            <div class="documents-item-file">
              <div [class]="{'file-exist': true, 'show': (document['value']['attr']['data-uploaded'] === 'true')}">

                <button type="button"
                [ngClass]="[marque.slug, 'btn', 'btn-sm', 'btn-outline', 'btn-inverted', 'blue-bp', 'me-3']"
                (click)="downloadDocument(document['value']['attr'])"
                *ngIf="document['value']['attr']['data-uploaded'] === 'true'">
                  <span class="me-1"><i class="fas fa-download"></i></span>
                  Télécharger
                </button>
                <button type="button"
                [ngClass]="[marque.slug, 'btn', 'btn-sm', 'btn-outline', 'btn-inverted']"
                (click)="deleteFile(confirmModal, document)">
                  <span class="me-1"><i class="fas fa-trash-alt"></i></span>
                  Supprimer
                </button>
              </div>
              <div *ngIf="document['value']['attr']['data-uploaded'] !== 'true'" [class]="{'file-uploaded': true, 'show': true}">
                <input ngModel type="file" [id]="document['value']['attr']['id']" [name]="document['value']['attr']['name']"
                data-uploaded="false"
                data-reviewed="false"
                data-valid="false"
                [class]="{
                  'custom-file-input': true,
                  'btn': true,
                  'btn-sm': true,
                  'btn-outline': true,
                  'btn-inverted': true,
                  'select': (document['value']['attr']['data-uploaded'] === 'true')
                }"
                (change)="fileSelected($event)">
                <label [for]="document['value']['attr']['id']"
                  [ngClass]="[marque.slug,  'btn', 'btn-sm', 'btn-outline', 'btn-inverted']"
                  [innerHTML]="isFileSelected(document['value']['attr']['id'])"
                ></label>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #confirmModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="confirm-modal-title">Voulez-vous vraiment supprimer le document ?</h4>
    <button type="button" class="btn-close" style="outline: none;box-shadow: none;" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss()">Annuler</button>
    <button type="button" [ngClass]="[marque.slug, 'btn', 'btn-outline', 'btn-inverted']" (click)="modal.close('yes')">Supprimer le document</button>
  </div>
</ng-template>
