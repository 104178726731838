import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {debounceTime, take} from 'rxjs/operators'
import {User} from '../../models/user.model';
import {JsonService} from '../../services/json.service';
import {AssetService} from '../../services/asset.service';
import {Marque} from '../../models/marque.model';
import {MarqueService} from '../../services/marque.service';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NetworkDeleteModalComponent, NetworkDeleteResultType} from './network-delete-modal.component';
import { NetworkNewProjectModalComponent, NetworkNewProjectModalResultType } from './network-newproject-modal.component';

import { Subscription } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { QuestionnaireResponse } from 'src/app/models/questionnaire_response.model';

@Component({
  selector: 'ns-network-home',
  templateUrl: '../../templates/backend/network/index_network.component.html'
})
export class NetworkHomeComponent implements OnInit, AfterViewInit, OnDestroy {  
  public readyMarque = false;
  public readyMembers = false;

  private membres: User[] = [];
  public filteredData: User[] = [];

  public canUserHasNewQuestionnaire: any = {};
  public adnCount: number = 0;
  public enterpriseCount: number = 0;
  public exportUrl: string;
  public marque: Marque;

  public currentOrderIdentifier = 'id';
  public currentOrderWording = 'Date';

  public currentFund: string = null;
  public networkFunds: string[] = [];

  public currentYear: string = null;
  public years: string[] = [];

  public isDownloadingXls: boolean = false;

  public searchFormControl = new UntypedFormControl();
  private searchSubscription: Subscription;

  public pageNumber: number = 1;
  public pageSize: number = 20;
  public memberSize: number = 0;

  constructor(
    private api: JsonService,
    public assetService: AssetService,
    private marqueService: MarqueService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.readyMarque = false;
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response;
        this.readyMarque = true;
      }
    });
    this.api.getInvestmentFundsAsNetwork().subscribe((res: any) => {
      this.networkFunds = res['investmentFunds'];
    })
    this.years = this.getYearList();
  }

  ngAfterViewInit(): void {
    this.getData();
    this.searchSubscription = this.searchFormControl.valueChanges
      .pipe(debounceTime(600))
      .subscribe(searchTerm => {
        this.getData();
      });
  }

  getData() {
    this.scrollToTop();
    this.readyMembers = false;
    this.api.getMembresReseau(
      this.currentOrderIdentifier,
      this.pageNumber,
      this.pageSize,
      this.searchFormControl.value,
      this.currentFund,
      this.currentYear
    ).subscribe(data => {
      this.membres = data['membres'];
      this.enterpriseCount = data['enterpriseCount'];
      this.adnCount = data['adnCount'];
      this.updateNewQuestionnaireButtonState();
      this.applyFilters();
      this.readyMembers = true;
    });
  }


  private scrollToTop() {
    const element = document.getElementById('content-wrapper');
    element.scrollIntoView();
  }

  updateOrdering(identifier, wording) {
    this.currentOrderIdentifier = identifier;
    this.currentOrderWording = wording;
    this.getData();
  }

  updateFundFilter(fund) {
    this.currentFund = fund;
    this.getData();
  }

  updateYearFilter(year) {
    this.currentYear = year;
    this.getData();
  }

  updateNewQuestionnaireButtonState() {
    const canUserHasNewQuestionnaire = {};
    for (let m of this.membres) {
      canUserHasNewQuestionnaire[m.id] = !m.questionnaireResponses?.find(qr => !qr.completed);
    }
    this.canUserHasNewQuestionnaire = canUserHasNewQuestionnaire;
  }

  applyFilters() {
    const filteredData = [];
    
    for (let m of this.membres) {
      filteredData.push({
        ...m,
        questionnaireResponses: m.questionnaireResponses.filter(qr => {
          const investmentFundMatch = !this.currentFund || this.currentFund === qr.investmentFund;
          const yearMatch = this.currentYear ? this.currentYear === this.getDateYearFromQr(qr) : true;
          return investmentFundMatch && yearMatch;
        })
      })
    }
    this.filteredData = filteredData;
  }

  openNewModal(member: User) {
    const canCreateNewProjectProject = this.canUserHasNewQuestionnaire[member.id];
    const modalRef = this.modalService.open(NetworkNewProjectModalComponent, {ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg'});
    modalRef.componentInstance.marque = this.marque;
    modalRef.componentInstance.user = member;
    modalRef.componentInstance.canCreateNewProjectProject = canCreateNewProjectProject;
    modalRef.componentInstance.isReady = true;
    modalRef.closed.pipe(
      take(1)
    ).subscribe(res => {
      if (res === NetworkNewProjectModalResultType.NewProject) {
        this.router.navigate([`/network/member/${member.id}/new-project`])
      } else if (res === NetworkNewProjectModalResultType.SameProject) {
        this.api.postNetworkMemberSameProject(member.id).pipe(take(1)).subscribe((res) => {
          console.log('res is ', res)
          if (res['state']) {
            this.toastr.success('Une nouvelle demande a bien été envoyée.', 'Relance');
            this.getData();
          } else {
            this.toastr.error('Une erreur s\'est produite lors de la relance', 'Relance');
          }
        })
      }
    });
  }

  openDeleteModal(member: User, questionnaireResponseId: number|null) {
    const modalRef = this.modalService.open(NetworkDeleteModalComponent, {ariaLabelledBy: 'modal-basic-title', centered: true});
    modalRef.componentInstance.marque = this.marque;
    modalRef.componentInstance.user = member;
    modalRef.componentInstance.questionnaireResponseId = questionnaireResponseId;
    modalRef.componentInstance.canDeleteProject = member.questionnaireResponses.length > 1;
    modalRef.componentInstance.isReady = true;
    modalRef.closed.pipe(
      take(1)
    ).subscribe(res => {
      if (res === NetworkDeleteResultType.User) {
        this.deleteMember(member.id);
      } else if (res === NetworkDeleteResultType.Questionnaire && questionnaireResponseId) {
        this.deleteQuestionnaire(member.id, questionnaireResponseId);
      } else if (res === NetworkDeleteResultType.Questionnaire && !questionnaireResponseId) {
        this.resetNetworkMemberInvestmentFundAndProject(member.id);
      }
    });
  }

  deleteMember(id) {
    this.api.deleteNetworkMember(id).subscribe(content => {
      this.getData();
      if (content['result']) {
        this.toastr.success('Membre supprimé avec succès', 'Suppression');
      } else {
        this.toastr.error('Une erreur s\'est produite lors de la suppression', 'Suppression');
      }
    });
  }

  deleteQuestionnaire(memberId: number, questionnaireResponseId: number|null) {
    this.api.deleteQuestionnaireResponseFromNetworkMember(memberId, questionnaireResponseId).subscribe(result => {
      this.getData();
      this.toastr.success('Le questionnaire à été supprimé', 'Suppression');
    }, (err) => {
      console.log(err)
      this.toastr.error('Une erreur s\'est produite lors de la suppression\n' + (err?.error?.detail || ''), 'Suppression');
    });
  }
  resetNetworkMemberInvestmentFundAndProject(memberId: number) {
    this.api.resetNetworkMemberInvestmentFundAndProject(memberId).subscribe(result => {
      this.getData();
      this.toastr.success('Le questionnaire à été supprimé', 'Suppression');
    }, () => {
      this.toastr.error('Une erreur s\'est produite lors de la suppression', 'Suppression');
    });
  }

  downloadExportExcel() {
    if (this.isDownloadingXls) {
      return;
    }

    this.isDownloadingXls = true;
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    this.api.getExportMembresReseau().subscribe(
      (blobby) => {
        this.isDownloadingXls = false;
        const objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = 'adn.xlsx';
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      }, (err) => {
        this.isDownloadingXls = false;
        this.toastr.warning('Une erreur est survenue lors du téléchargement');
        console.error(err);
      }
    );
  }

  private getYearList(): string[] {
    const years = [];
    const first = (new Date('2020')).getFullYear();
    const last = (new Date()).getFullYear();

    for (let i = first; i <= last; i++) {
      years.push(i.toString());
    }
    return years.reverse();
  }

  private getDateYearFromQr(qr: QuestionnaireResponse): string {
    // qr.dateSaved has a weird format...
    if ('date' in qr.dateSaved) {
      return (new Date((qr.dateSaved as any).date)).getFullYear().toString();
    }
    return (new Date(qr.dateSaved)).getFullYear().toString();
  }

  ngOnDestroy(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe()
    }
  }
}
