import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Marque } from 'src/app/models/marque.model';
import { User } from 'src/app/models/user.model';

export enum NetworkNewProjectModalResultType {
  NewProject = 'NEW_PROJECT',
  SameProject = 'SAME_PROJECT'
}

@Component({
  selector: 'ns-network-newproject-modal',
  templateUrl: '../../templates/backend/network/network-newproject-modal.component.html'
})
export class NetworkNewProjectModalComponent {
  NetworkNewProjectModalResultType = NetworkNewProjectModalResultType;
  @Input() marque: Marque;
  @Input() user: User;
  @Input() canCreateNewProjectProject: boolean;
  @Input() isReady: boolean;
  selectedOption: string = NetworkNewProjectModalResultType.SameProject;

  constructor(
    public activeModal: NgbActiveModal
  ) { }


  validate() {
    this.activeModal.close(this.selectedOption)
  }

  close() {
    this.activeModal.close();
  }
}
