import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ns-questionnaire-helper-modal',
  templateUrl: '../templates/questionnaire_helper_modal.component.html'
})

export class QuestionnaireHelperModalComponent{
  @Input() helpMessage;
  @Input() helpLink;

  constructor(
    public activeModal: NgbActiveModal
  ) {}
}

