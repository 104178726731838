<ns-navbar></ns-navbar>
<div id="body-container">
  <ns-sidebar></ns-sidebar>

  <div id="content-wrapper" class="container overflow-visible container-padding" *ngIf="ready">
    <div class="row ed-header-row">
      <div class="col-sm-6">
        <h1 class="titre_backend">Annuaire des entreprises</h1>
      </div>

      <div class="col-sm-6">
        <a *ngIf="!isReferenced" routerLink="/directory/inscription"
          [ngClass]="[marque.slug, 'btn btn-outline btn-inverted-white btn-reseau-2 shadow']" style="float: right;">
          <i class="fas fa-plus me-3"></i>
          <span class="pe-3">Inscrire mon entreprise</span>
        </a>

        <a *ngIf="isReferenced" routerLink="/directory/inscription"
          [ngClass]="[marque.slug, 'btn btn-outline btn-inverted-white btn-reseau-2 shadow']" style="float: right;">
          <i class="fas fa-pencil-alt me-3"></i>
          <span class="pe-3">Modifier mes informations</span>
        </a>
      </div>
    </div>

    <div>

      <div class="row ed-filters-row justify-content-md-end">

        <div class="col">
          <form>
            <div class="input-group">
              <div class="input-group-prepend network-home-searchbar-prepend"
                style="margin-top: 0px !important; height: 42px !important;">
                <span class="input-group-text"><i class="fas fa-search"></i></span>
              </div>
              <input type="text" class="form-control network-home-searchbar"
                style="margin-top: 0px !important; height: 42px !important;" placeholder="Rechercher une entreprise..."
                [formControl]="searchFormControl">
            </div>
          </form>
        </div>

        <div class="col-auto">
          <span class="me-3 sort-style">Trier par </span>
          <div ngbDropdown class="d-inline-block">
            <button [ngClass]="[marque.slug, 'btn btn-outline btn-inverted-white btn-reseau-2']" id="dropdownMenuButton"
              ngbDropdownToggle>
              <span id="ordering-title" class="ps-2 pe-3">{{ currentOrderWording }}</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="updateOrdering('id', 'Date')">Date</button>
              <button ngbDropdownItem (click)="updateOrdering('companyName', 'Entreprise')">Nom</button>
            </div>
          </div>
        </div>

        <div class="col-auto">
          <span class="me-3 sort-style">Filtrer par secteur</span>
          <div ngbDropdown class="d-inline-block">
            <button [ngClass]="[marque.slug, 'btn btn-outline btn-inverted-white btn-reseau-2']" id="dropdownMenuButton"
              ngbDropdownToggle>
              <span *ngIf="currentBusinessSegment === null" class="ps-2 pe-3">Tous</span>
              <span *ngIf="currentBusinessSegment !== null" class="ps-2 pe-3">{{ currentBusinessSegment }}</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="updateBusinessSegmentFilter(null)">Tous</button>
              <button *ngFor="let businessSegment of businessSegments" ngbDropdownItem
                (click)="updateBusinessSegmentFilter(businessSegment)">{{ businessSegment }}</button>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 ed-item" *ngFor="let ed of filteredData">
          <ns-enterprise-directory-item [marque]="marque" [enterpriseDirectory]="ed"></ns-enterprise-directory-item>
        </div>

        <div *ngIf="!filteredData?.length">Aucune entreprise disponible pour le moment.</div>
      </div>

    </div>
  </div>

</div>