<ns-navbar></ns-navbar>
<div id="body-container">
  <ns-sidebar sidebarType="admin"></ns-sidebar>
  <div id="content-wrapper">
    <div class="container-fluid" *ngIf="ready">

      <div *ngIf="ready" class="row">

        <div class="chart-container col-md-11 mb-5" *ngIf="qrChartReady">
          <label class="titre_backend_reseau chart-label">Evolution des questionnaires</label>
          <div [ngClass]="[marque.slug, 'text-colored', 'chart-subtitle', 'mb-3']">{{totalQrValidated}} questionnaires validés /
            {{totalQrCreated}} créés</div>
          <canvas baseChart
            class="card-body"
            [data]="qrChartData"
            [options]="qrChartOptions"
            plugins="[]"
            legend="true"
            type="bar">
          </canvas>
        </div>


        <div class="chart-container col-md-12 mb-5">
          <label class="titre_backend_reseau chart-label">Chiffres clefs</label>
          <div class="row mt-2">
            <div class="col-md-6">
              <div class="chart-subtitle mb-3"><span
                  [ngClass]="[marque.slug, 'text-colored', 'fw-bold']">{{totalUser}}</span>
                entreprises enregistrées.</div>

              <div class="chart-subtitle mb-3"><span
                  [ngClass]="[marque.slug, 'text-colored', 'fw-bold']">{{totalQrCreated}}</span>
                questionnaires créés.
              </div>
              <div class="chart-subtitle mb-3"><span
                  [ngClass]="[marque.slug, 'text-colored', 'fw-bold']">{{totalQrValidatedRatio}}%</span> de
                questionnaires validés.
              </div>
            </div>
            <div class="col-md-6">
              <div class="chart-subtitle mb-3"><span
                  [ngClass]="[marque.slug, 'text-colored', 'fw-bold']">{{totalUserWithQrRatio}}%</span> des
                entreprises ont complété au moins un questionnaire.</div>
              <div class="chart-subtitle mb-3"><span
                  [ngClass]="[marque.slug, 'text-colored', 'fw-bold']">{{totalComeBackRatio}}%</span> des
                entreprises ont complété plusieurs questionnaires.</div>
              <div class="chart-subtitle mb-3"><span
                  [ngClass]="[marque.slug, 'text-colored', 'fw-bold']">{{bestYearDate}}</span> est l'année
                record avec <span
                  [ngClass]="[marque.slug, 'text-colored', 'fw-bold']">{{bestYearCount}}</span>
                questionnaires créés.</div>
            </div>
          </div>
        </div>

        <div class="chart-container col-md-6" *ngIf="validationRateChartReady">
          <label class="titre_backend_reseau chart-label">Taux de validation</label>
          <div [ngClass]="[marque.slug, 'text-colored', 'chart-subtitle', 'mb-3']">{{totalQrValidatedRatio}} % de questionnaires
            validés</div>
            <div>
              <canvas baseChart
                class="card-body pie-chart"
                type="pie"
                [datasets]="validationRateChartData"
                [labels]="validationRateChartLabels"
                [options]="pieChartOptions"
                plugins="[]"
                legend="true">
              </canvas> 
            </div>
                   
        </div>

        <div class="chart-container col-md-6" *ngIf="yearlyOverviewChartReady">
          <label class="titre_backend_reseau chart-label">Répartition annuelle des questionnaires créés</label>
          <div [ngClass]="[marque.slug, 'text-colored', 'chart-subtitle', 'mb-3']">{{bestYearDate}} est l'année record avec
            {{bestYearCount}} questionnaires créés</div>
            <div>
              <canvas baseChart
                class="card-body pie-chart"
                type="pie"
                [datasets]="yearlyOverviewChartData"
                [labels]="yearlyOverviewChartLabels"
                [options]="pieChartOptions"
                plugins="[]"
                legend="true">
              </canvas>
            </div>
          
        </div>
      </div>

    </div>
  </div>
</div>