import { Component, OnInit, ViewChild } from '@angular/core';
import {JsonService} from "../services/json.service";
import {Router} from "@angular/router"
import {ToastrService} from "ngx-toastr"
import {UserService} from "../services/user.service"
import {User} from "../models/user.model"
import {MarqueService} from "../services/marque.service"
import {Marque} from "../models/marque.model"
import { environment } from './../../environments/environment';
import {AssetService} from "../services/asset.service"
import { RecaptchaComponent, RecaptchaErrorParameters } from 'ng-recaptcha';

@Component({
  selector: 'ns-login',
  templateUrl: '../templates/security/login.component.html'
})

export class LoginComponent {
  @ViewChild(RecaptchaComponent) recaptchaRef!: RecaptchaComponent;
  
  public googleRecaptchaSiteKey : string
  public processing : boolean
  public errorMessage : string
  public token: string
  public marque : Marque = null
  public ready: boolean = false
  public recaptchaToken: string = null
  public showPassword: boolean = false;

  constructor (
    private api: JsonService,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private marqueService: MarqueService,
    public assetService: AssetService
  ) {
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      this.googleRecaptchaSiteKey = environment.googleRecaptchaSiteKey
      this.marque = null
      this.ready = false
      this.marqueService.getMarque().subscribe(response => {
        if (response instanceof Marque) {
          this.marque = response
          this.ready = true
          return this.marque
        }
      })
    })

  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  login(user) {
    if (null == this.recaptchaToken) {
      this.toastr.error("Vous devez valider le captcha", 'Erreur')
      return
    }

    this.processing = true
    this.api.login({...user, recaptcha: this.recaptchaToken}).subscribe({
      next: (content) => {
        if (typeof content['token']!== 'undefined' && content['token'].length) {
          this.token = content['token']
          localStorage.setItem('token', this.token);
  
          this.userService.getUser().subscribe(userResponse => {
            if (userResponse instanceof User) {
              if (userResponse.type === 'entreprise' || userResponse.type === 'membre_reseau') {
                this.router.navigate(['/'])
              } else if (userResponse.type === 'reseau') {
                this.router.navigate(['/network'])
              } else if (userResponse.type === 'membre_reseau') {
                  this.router.navigate(['/'])
              } else if (userResponse.type === 'admin') {
                this.router.navigate(['/admin'])
              }
            }
          })
  
          this.processing = false
        } else {
          this.toastr.error(content['error'], 'Erreur')
          this.processing = false;
          this.recaptchaRef?.reset();
        }
      },
      error: (err) => {
        this.toastr.error('Une erreur est survenue. Merci de réessayer.', 'Erreur')
        console.error(err)
        this.recaptchaRef?.reset();
        this.processing = false;
      }
    });
  }

  recaptchaSuccessResponse(response) {
    this.recaptchaToken = response
  }
  
  recaptchaErrorResponse(errorDetails: RecaptchaErrorParameters) {
    console.error('Recaptcha error response : ', errorDetails)
    this.recaptchaRef?.reset();
  }

  isSignupAllowed(): boolean {
    return !['mcapital', 'ninkasi'].includes(this.marque?.slug);
  }
}
