import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, filter, map} from 'rxjs/operators';
import {Questionnaire} from "../models/questionnaire.model";
import {Category} from "../models/category.model";
import {SubCategory} from "../models/subcategory.model";
import {Question} from "../models/question.model";
import {Choice} from "../models/choice.model";
import {DefaultUrlSerializer, Router} from "@angular/router"
import { environment } from '../../environments/environment';
import {User} from "../models/user.model"
import {Offer} from "../models/offer.model"
import {Observable} from "rxjs"
import {Marque} from "../models/marque.model"
import {IdentificationField} from "../models/identificationField"
import {TestMappingField} from "../models/testMappingField"
import {AssistanceSystem} from "../models/assistance_systems.model";
import { EnterpriseDirectory } from '../models/enterprise_directory.model';

@Injectable({
    providedIn: 'root'
})
export class JsonService {

    private baseUrl: string;
    private header: HttpHeaders;

    constructor(private http: HttpClient, private urlSerializer: DefaultUrlSerializer,  private router: Router) {
        this.baseUrl = environment.apiUrl;
        this.header = new HttpHeaders()
                .set('Access-Control-Allow-Origin', '*')
                .set('Access-Control-Allow-Methods', 'GET')
                .set('Access-Control-Allow-Headers', 'Origin, Content-Type, X-Auth-Token, Accept')
                .set('Content-Type', 'application/json')
            ;
    }

  getQuestionnaire() {
    return this.http.get(`${this.baseUrl}/questionnaire-ns`).pipe(
      map(response => {
        if (response['redirect_to']) {
          return response;
        }

        const res = this.parseJson(response);
        const items = res[0].properties;
        return {
          form: new Questionnaire(
            this.parseJson(items).map((category, categoryIndex) => {
              const categorySlug = Object.keys(items)[categoryIndex];

              return new Category(
                category.title,
                categorySlug,
                category.attr?.family,
                this.parseJson(category.properties).map((subCategory, subCategoryIndex) => {

                  const subCategorySlug = Object.keys(category.properties)[subCategoryIndex];
                  return new SubCategory(
                    subCategory.title,
                    subCategorySlug,
                    this.parseJson(subCategory.properties).map(question => {
                      return new Question(
                        question.title,
                        question.properties.choices.title,
                        question.properties.hasOwnProperty('comment'),
                        question.properties.hasOwnProperty('comment') ? (question.properties.comment.title !== 'comment' ? question.properties.comment.title : null) : null,
                        function () {
                          const choices: Array<Choice> = [];
                          let items;

                          if (question.properties.choices.hasOwnProperty("items")) {
                            items = question.properties.choices.items;
                          } else {
                            items = question.properties.choices;
                          }
                          for (let i = 0; i < items.enum.length; i++) {
                            let name: string;
                            if (question.properties.choices.hasOwnProperty("items")) {
                              name = `score[${categorySlug}][${subCategorySlug}][${question.title}][choices][${items.enum[i]}]`
                            } else {
                              name = `score[${categorySlug}][${subCategorySlug}][${question.title}][choices]`
                            }

                            const checked = items.enum[i].match(/\*/) !== null;

                            choices.push(new Choice(
                              null,
                              items.enum_titles[i],
                              items.enum[i],
                              name,
                              `choice_${categorySlug}_${subCategorySlug}_${question.title}_${i}`,
                              null,
                              checked
                            ));
                          }

                          return choices;
                        }(),
                        (question.properties.choices.type === 'array'),
                        function () {
                          const choices: Array<any> = [];
                          let items;
                          if (question.properties.choices.hasOwnProperty("items")) {
                            items = question.properties.choices.items;
                          } else {
                            items = question.properties.choices;
                          }
                          for (let i = 0; i < items.enum.length; i++) {
                            const checked = items.enum[i].match(/\*/) !== null;
                            if (checked) {
                              choices.push(items.enum[i]);
                            } else {
                              if (question.properties.choices.hasOwnProperty("items")) {
                                choices.push(false);
                              }
                            }
                          }
                          return choices;
                        }(),
                        null,
                        null,
                        null,
                        question.properties.choices.hasOwnProperty('description') ? question.properties.choices.description : null,
                        question.properties.comment?.type || 'text'
                      );
                    })
                  );
                })
              );
            })
          ),
          token: res[1],
        };
      })
    );
  }


  getQuestionnaireDateSaved(questionnaireResponseId: number) {
    return this.http.get(`${this.baseUrl}/questionnaire/${questionnaireResponseId}/get-date`).pipe(
      map(response => {
        return response
      }))
  }

    // populateChoice(items: Array<any>, itemsKey: boolean, categorySlug: string, question: Array<any>) {
    //   const choices: Array<Choice> = [];
    //
    //   // let items
    //   // if (choice.hasOwnProperty('items')) {
    //   //   items = choice.items
    //   // } else {
    //   //   items = choice
    //   // }
    //   //
    //   // if (!items.hasOwnProperty('enum')) {
    //   //   return
    //   // }
    //
    //   // items = choice
    //
    //   for (let i = 0; i < items.length; i++) {
    //     let name: string
    //     if (question.properties.choices.hasOwnProperty("items")) {
    //       name = `score[${categorySlug}][${subCategorySlug}][${question.title}][choices][${items[i]}]`
    //     } else {
    //       name = `score[${categorySlug}][${subCategorySlug}][${question.title}][choices]`
    //     }
    //     choices.push(new Choice(
    //       null,
    //       items.enum_titles[i],
    //       items.enum[i],
    //       name,
    //       `choice_${categorySlug}_${subCategorySlug}_${question.title}_${i}`
    //     ));
    //   }
    //   return choices;
    //
    // })
    // }
    postQuestionnaire(data, completed) {
      const dataFiltered = {};

      this.parseJson(data).map( (item, key) => {
        if (typeof item !== 'undefined' && item) {
          const keyName = Object.keys(data)[key].replace(/\[\*/, '[');

          if (item === true) {
            dataFiltered[keyName] = Object.keys(data)[key].match(/score\[.+\]\[.+\]\[.+\]\[.+\]\[(.+)\]/)[1];
          } else {
            dataFiltered[keyName] = item.replace(/^\*/, '');
          }
        }
      });

      let url = `${this.baseUrl}/questionnaire-ns?formWithEmptyResponse=true`;

      if (completed) {
        url += '&completed=true';
      }

      return this.http.post(url, `${this.parseUrl(dataFiltered)}`, {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')});
    }

    getQuestionnaireExport() {
      return this.http.get(`${this.baseUrl}/questionnaire/export.xlsx`, {responseType: 'blob'}).pipe(
        map(response => {
          return response;
        }));
    }



    parseJson(json) : Array<any> {
      let result = [];
      let  keys = Object.keys(json);
      keys.forEach(function(key){
        result.push(json[key]);
      });

      return result;
    }

    parseUrl(json) {
      let result = ''
      let keys = Object.keys(json)
      keys.forEach(function(key){
        result = `${result}${key}=${encodeURIComponent(json[key])}&`;
      });

      return result
    }

  getUpgrades() {
    return this.http.get(`${this.baseUrl}/upgrades`).pipe(
      map(response => {
        return response
      }))
  }

  getUpgradesPdf() {
    return this.http.get(`${this.baseUrl}/upgrades-pdf`, {responseType: 'blob'}).pipe(
      map(response => {
        return response;
      })
    );
  }

  getHistory() {
    return this.http.get(`${this.baseUrl}/history`).pipe(
      map(response => {
        //response = this.parseJson(response)

        return {
          'scores': this.parseJson(response['scores']),
          'bestScore': JSON.parse(response['bestScore']),
          'lastScore': JSON.parse(response['lastScore']),
          'averageScores': response['averageScores']
        }
      }))
  }


  getAdn() {
    return this.http.get(`${this.baseUrl}/adn`).pipe(
      map(response => {
        //response = this.parseJson(response)

        return {
          'adn': response['score'],
          'sectorPositioning': response['sectorPositioning']
        }
      }))
  }

  getAdnByQuestionnaireResponseId(questionnaireResponseId: number) {
    return this.http.get(`${this.baseUrl}/adn/${questionnaireResponseId}`).pipe(
      map(response => {
        return {
          'adn': response['score']
        }
      }))
  }

  getGoodPractices() {
    return this.http.get(`${this.baseUrl}/good-practices`).pipe(
      map(response => {
        //response = this.parseJson(response)

        return {
          'goodPractices': response['goodPractices']
        }
      }))
  }

  getOffers() {
    return this.http.get(`${this.baseUrl}/offers`).pipe(
      map(response => {
        //response = this.parseJson(response)

        return {
          'offers': response['offers']
        }
      }))
  }

  getIdentification() {
    return this.http.get(`${this.baseUrl}/identification`).pipe(
      map(response => {
        //response = this.parseJson(response)
        const items = this.parseJson(response['form']['properties']);
        let form = [];

        items.map((item, itemIndex) => {

          let choices = [];
          if (item.hasOwnProperty("enum")) {
            this.parseJson(item.enum).map((enumValue, enumIndex) => {
              choices.push({
                "value": enumValue,
                "label": item.enum_titles[enumIndex]
              })
            })
          }

          let type: string = 'text';
          if (item.hasOwnProperty("enum")) {
            type = 'choice';
          } else if (item.type === 'boolean') {
            type = 'boolean';
          }

          form.push(
            new IdentificationField(
              type,
              item.title,
              'identification_form[' + item.attr.id + ']',
              item.attr.id,
              choices,
              item?.attr?.value,
              (response['form']['required'] || []).includes(item.attr.id)
            )
          )
        })
        return {'form':form}
      }))

  }

  postIdentification(form) {
    return this.http.post(`${this.baseUrl}/identification`, this.parseUrl(form), {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).pipe(
      map(response => {
        //response = this.parseJson(response)

        return response
      }))
  }

  getTestMapping() {
    return this.http.get(`${this.baseUrl}/admin/test-mapping`).pipe(
      map(response => {
        //response = this.parseJson(response)
        const items = this.parseJson(response['form']['properties']);
        let form = [];

        items.map((item, itemIndex) => {

          let choices = [];
          if (item.hasOwnProperty("enum")) {
            this.parseJson(item.enum).map((enumValue, enumIndex) => {
              choices.push({
                "value": enumValue,
                "label": item.enum_titles[enumIndex]
              })
            })
          }

          form.push(
            new TestMappingField(
              item.hasOwnProperty("enum") ? 'choice' : 'text',
              item.title,
              'identification_form[' + item.attr.id + ']',
              item.attr.id,
              choices
            )
          )
        })
        return {'form':form}
      }))

  }

  postTestMapping(form) {
    return this.http.post(`${this.baseUrl}/admin/test-mapping`, this.parseUrl(form), {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).pipe(
      map(response => {
        //response = this.parseJson(response)

        return response
      }))
  }

  getDocuments() {
    return this.http.get(`${this.baseUrl}/legal-documents`).pipe(
      map(response => {

        return response
      })
      )
  }

  getDocument(documentName) {
    return this.http.get(`${this.baseUrl}/legal-document/${documentName}`, {responseType: 'blob'}).pipe(
      map(response => {
        return response;
      })
    );
  }

  deleteUserDocument(documentUid: string) {
    return this.http.delete(`${this.baseUrl}/legal-document/${documentUid}`).pipe(
      map(response => {
        return response;
      })
    );
  }


  getUserListAsAdmin(order: string = 'id', pageNumber: number, pageSize: number, searchTerm?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .set('order', order)
      .set('pageNumber', pageNumber)
      .set('pageSize', pageSize);
    if (searchTerm) httpParams = httpParams.set('searchTerm', searchTerm);

    return this.http.get(`${this.baseUrl}/admin/users`, { params: httpParams }).pipe(
      map(response => {
        return response;
      })
    );
  }

  getUserByIdAsAdmin(id: number) {
    return this.http.get(`${this.baseUrl}/admin/user/${id}`).pipe(
      map(response => {
        return response
      }))
  }

  getAdnByQuestionnaireResponseIdAsAdmin(questionnaireResponseId: number) {
    return this.http.get(`${this.baseUrl}/admin/user/adn/${questionnaireResponseId}`).pipe(
      map(response => {
        return {
          'adn': response['score']
        }
      }))
  }

  getAdnPdfExportAsAdmin(questionnaireResponseId: number) {
    return this.http.get(`${this.baseUrl}/admin/adn/${questionnaireResponseId}/pdf`, {responseType: 'blob'}).pipe(
      map(response => {
        return response;
      })
    );
  }

  getFullQrPdfExportAsAdmin(questionnaireResponseId: number) {
    return this.http.get(`${this.baseUrl}/admin/adn/${questionnaireResponseId}/full-qr-pdf`, {responseType: 'blob'}).pipe(
      map(response => {
        return response;
      })
    );
  }

  getUpgradesPdfAsAdmin(questionnaireResponseId: number) {
    return this.http.get(`${this.baseUrl}/admin/upgrades/${questionnaireResponseId}/pdf`, {responseType: 'blob'}).pipe(
      map(response => {
        return response;
      })
    );
  }

  getExportAdnAsAdmin(fullDownload: boolean = false, part: 0 | 1 = 0) {
    return this.http.get(`${this.baseUrl}/admin/export/adn.xlsx?fullDownload=${fullDownload}&part=${part}`, {responseType: 'blob'}).pipe(
      map(response => {
        return response;
      })
    );
  }


  getAdminUserListAsSuperAdmin(order: string = 'id') {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('order', order);

    return this.http.get<User[]>(`${this.baseUrl}/superadmin/users`, { params: httpParams });
  }

  deleteAdminUserAsSuperAdmin(userId: number) {
    return this.http.delete(`${this.baseUrl}/superadmin/user/${userId}`);
  }

  getCreateAdminFormAsSuperAdmin() {
    return this.http.get(`${this.baseUrl}/superadmin/new-admin`).pipe(
      map(response => {
        return this.parseForm(response);
      }))
  }

  postCreateAdminAsSuperAdmin(form) {
    return this.http.post(
        `${this.baseUrl}/superadmin/new-admin`,
        this.parseUrl(form),
        {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}
      );
  }
  

  getAdnPdf() {
    return this.http.get(`${this.baseUrl}/adn-pdf`, {responseType: 'blob'}).pipe(
      map(response => {
        return response;
      })
    );
  }
  getAttestationPdf() {
    return this.http.get(`${this.baseUrl}/attestation-pdf`, {responseType: 'blob'}).pipe(
      map(response => {
        return response;
      })
    );
  }

  getAdnFromNetworkUserPdfExport(networkMemberId) {
    return this.http.get(`${this.baseUrl}/network/adn/${networkMemberId}/adn-pdf`, {responseType: 'blob'}).pipe(
      map(response => {
        return response;
      })
    );
  }

  postDocuments(form) {
    return this.http.post(`${this.baseUrl}/legal-documents`, form).pipe(
      map(response => {
        return response
      }))
  }

  getOffer(name) {
    return this.http.get(`${this.baseUrl}/offer/${name}`).pipe(
      map(response => {
        return {
          'offer': response['offer'],
          'stripePublicKey': response['stripePublicKey']
        }
      }))
  }

  postPayment(name, infos) {
    return this.http.post(`${this.baseUrl}/offer/${name}`, infos, {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).pipe(
      map(response => {
        return response
      }))
  }

  getMembresReseau(order: string = 'id', pageNumber: number, pageSize: number, searchTerm?: string, fundFilter?:string, yearFilter?: string) {
    let httpParams = new HttpParams();
    httpParams = httpParams
      .set('order', order)
      .set('pageNumber', pageNumber)
      .set('pageSize', pageSize);
    if (searchTerm) httpParams = httpParams.set('searchTerm', searchTerm);
    if (yearFilter) httpParams = httpParams.set('yearFilter', yearFilter);
    if (fundFilter) httpParams = httpParams.set('fundFilter', fundFilter);

    return this.http.get(`${this.baseUrl}/network`, { params: httpParams }).pipe(
      map(response => {
        return response;
      })
    );
  }

  getInvestmentFundsAsNetwork() {
    return this.http.get(`${this.baseUrl}/network/investment-funds`)
    
  }
  getExportMembresReseauUrl(userId: number) {
      return `${this.baseUrl}/network/export/${userId}/adn.xlsx`;
  }

  getExportMembresReseau() {
    return this.http
      .get(`${this.baseUrl}/network/export/adn.xlsx`, {responseType: 'blob'})
      .pipe(map(response => {
        return response;
      }));
  }

  getAdnExcelExport() {
    return this.http.get(`${this.baseUrl}/adn/export.xlsx`, {responseType: 'blob'}).pipe(
      map(response => {
        return response;
      }));
  }

  getAdnFromNetworkUserExcelExport(networkMemberId) {
    return this.http.get(`${this.baseUrl}/network/adn/${networkMemberId}/export.xlsx`, {responseType: 'blob'}).pipe(
      map(response => {
        return response;
      }));
  }

  getFunds() {
    return this.http.get(`${this.baseUrl}/funds`).pipe(
      map(response => {
        return response;
      })
    );
  }

  getDocumentsReview() {
    return this.http.get(`${this.baseUrl}/admin/review-documents`).pipe(
      map(response => {
        return response
      }))
  }

  deleteDocument(id: number) {
    return this.http.get(`${this.baseUrl}/admin/review-documents/delete/${id}`).pipe(
      map(response => {
        return response
      }))
  }

  reviewDocuments(id: number, decision: string) {
    return this.http.get(`${this.baseUrl}/admin/review-documents/${decision}/${id}`).pipe(
      map(response => {
        return response
      }))
  }

  getNetworkUserById(id: number) {
    return this.http.get(`${this.baseUrl}/network/member/${id}`).pipe(
      map(response => {
        return response
      }))
  }

  getNetworkNewMemberForm() {
    return this.http.get(`${this.baseUrl}/network/new-member`).pipe(
      map(response => {
        return this.parseForm(response);
      }))
  }

  postNetworkNewMember(form) {
    return this.http.post(`${this.baseUrl}/network/new-member`, this.parseUrl(form), {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).pipe(
      map(response => {
        return response;
      })
    );
  }


  getNetworkMemberNewProjectForm(memberId: number) {
    return this.http.get(`${this.baseUrl}/network/member/${memberId}/new-project`).pipe(
      map(response => {
        return this.parseForm(response);
      }));
  }

  putNetworkMemberNewProject(memberId: number, form) {
    return this.http.put(`${this.baseUrl}/network/member/${memberId}/new-project`, this.parseUrl(form), {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).pipe(
      map(response => {
        return response;
      })
    );
  }

  postNetworkMemberSameProject(memberId: number) {
    return this.http.post(`${this.baseUrl}/network/member/${memberId}/same-project`, {}).pipe(
      map(response => {
        return response;
      })
    );
  }

  deleteNetworkMember(id) {
    return this.http.delete(
      `${this.baseUrl}/network/member/${id}`,
      {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}
    ).pipe(
      map(response => {
        return response;
      })
    );
  }

  deleteQuestionnaireResponseFromNetworkMember(memberId: number, questionnaireResponseId: number) {
    return this.http.delete(
      `${this.baseUrl}/network/member/${memberId}/${questionnaireResponseId}`,
      {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}
    ).pipe(
      map(response => {
        return response;
      })
    );
  }

  resetNetworkMemberInvestmentFundAndProject(memberId: number) {
    return this.http.delete(
      `${this.baseUrl}/network/member/${memberId}/reset-project`,
      {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}
    ).pipe(
      map(response => {
        return response;
      })
    );
  }

  getNetworkDocuments() {
    return this.http.get(`${this.baseUrl}/network/documents`).pipe(
      map(response => {
        return response;
      }));
  }

  downloadNetworkDocument(documentId) {
    return this.http.get(`${this.baseUrl}/network/document/${documentId}`, {responseType: 'blob'}).pipe(
      map(response => {
        return response;
      })
    );
  }

  postAdminModifyLogo(form) {
    return this.http.post(`${this.baseUrl}/admin/modifiy-logo`, form).pipe(
      map(response => {
        return response
      }))
  }
  getUsers() {
    return this.http.get(`${this.baseUrl}/admin/modifiy-logo`).pipe(
      map(response => {
        return response
      }))
  }

  login(user) {
    let headers = this.header
    headers.set('Content-Type', 'application/x-www-form-urlencoded')
      return this.http.post(`${this.baseUrl}/login_check`, JSON.stringify(user), {headers: new HttpHeaders().set('Content-Type', 'application/json')}).pipe(
        map(response => {
          return response
        }))
  }

  postRegistration(form) {
    return this.http.post(`${this.baseUrl}/register`, this.parseUrl(form), {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).pipe(
      map(response => {
        return response
      }))
  }

  postPasswordForgotten(form) {
    return this.http.post(`${this.baseUrl}/password-forgotten`, this.parseUrl(form), {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).pipe(
      map(response => {
        return response
      }))
  }

  postPasswordForgottenDefine(form, hash) {
    return this.http.post(`${this.baseUrl}/password-forgotten/${hash}`, this.parseUrl(form), {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).pipe(
      map(response => {
        return response
      }))
  }

  postDeleteAccount() {
    return this.http.post(`${this.baseUrl}/user/delete`, {}, {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).pipe(
      map(response => {
        return response;
      }));
  }

  notifyFirstLogin() {
    return this.http.post(`${this.baseUrl}/user/first-login`, {}, {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).pipe(
      map(response => {
        return response;
      }));
  }

  getUser() {
    return this.http.get(`${this.baseUrl}/get-user`).pipe(
      map(response => {
        const res =  response;

        return new User(
          res['id'],
          res['type'],
          res['email'],
          res['companyName'],
          res['fullName'],
          res['cardId'],
          res['customerId'],
          res['subscriptionId'],
            res['offer'] ? new Offer(
              res['offer']['id'],
              res['offer']['name'],
              res['offer']['description'],
              res['offer']['price'],
              res['offer']['slug']
            ) : null,
          res['businessSegment'],
          res['status'],
          res['address'],
          res['logoUrl'],
          res['nbEmployee'],
          res['nomenclatures'],
          res['investmentFund'],
          res['projectName'],
          res['firstLoginAt'] !== null,
          !!res['isSuperAdmin'],
        );
      }), catchError(() => this.router.navigate(['/login'])));
  }

  getMarque() {
    return this.http.get(`${this.baseUrl}/get-marque`).pipe(
      map(res => {
        return new Marque(
          res['marque'],
          res['marqueLabel'],
          res['hasAssistanceSystems'],
          res['hasSupportEnabled']
        )
      }), catchError(() => this.router.navigate(['/login'])))
  }

  getAssistanceSystems(): Observable<AssistanceSystem[]> {
    return this.http.get<Array<object>>(`${this.baseUrl}/assistance-systems`).pipe(
      map(res => {
        return res.map((assistanceSystem: object) => new AssistanceSystem(
          assistanceSystem['id'],
          assistanceSystem['title'],
          assistanceSystem['description'],
          assistanceSystem['link'],
        ));
      }),
      catchError(() => {
        this.router.navigate(['/login']);
        return [];
      })
    );
  }

  createAssistanceSystem(assistanceSystem: {title: string, description: string, link: string}): Observable<AssistanceSystem> {
    return this.http.post(`${this.baseUrl}/assistance-systems`, this.parseUrl(assistanceSystem), {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).pipe(
      map(response => {
        return new AssistanceSystem(
          response['id'],
          response['title'],
          response['description'],
          response['link'],
        );
      })
    );
  }

  updateAssistanceSystem(assistanceSystem: AssistanceSystem): Observable<AssistanceSystem> {
    return this.http.put(`${this.baseUrl}/assistance-systems/${assistanceSystem.id}`, this.parseUrl(assistanceSystem), {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}).pipe(
      map(response => {
        return new AssistanceSystem(
          response['id'],
          response['title'],
          response['description'],
          response['link'],
        );
      })
    );
  }

  deleteAssistanceSystem(assistanceSystem: AssistanceSystem) {
    return this.http.delete(
      `${this.baseUrl}/assistance-systems/${assistanceSystem.id}`,
      {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')}
    ).pipe(
      map(response => {
        return response;
      })
    );
  }

  sendSupportQuestion(email: string, question: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/user/support`, {email: email, question: question}).pipe(
      map(response => {
        return response;
      })
    );
  }

  getSearchInseeSiren(siren: string) {
    return this.http.get(`${this.baseUrl}/insee/search/siren/${siren}`).pipe(
      map(response => {
        return response
      }))
  }

  getSearchInseeSiret(siret: string) {
    return this.http.get(`${this.baseUrl}/insee/search/siret/${siret}`).pipe(
      map(response => {
        return response
      }))
  }


  getEnterpriseDirectories(order: string = 'id') {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('order', order);

    return this.http.get<EnterpriseDirectory[]>(`${this.baseUrl}/enterprise-directory/list`, { params: httpParams });
  }

  getEnterpriseDirectoriesToValidate() {
    return this.http.get<EnterpriseDirectory[]>(`${this.baseUrl}/admin/enterprise-directory/list`);
  }

  getEnterpriseDirectoryForm() {
    return this.http.get(`${this.baseUrl}/enterprise-directory/create`).pipe(
      map(response => {
        const items = this.parseJson(response['form']['properties']);
        let form = [];

        items.map((item, itemIndex) => {
          form.push(
            new IdentificationField(
              item.widget === 'file_widget' ? 'file': 'text',
              item.title,
              'enterprise_directory_form[' + item.attr.id + ']',
              item.attr.id,
              [],
              item?.attr?.value,
              (response['form']['required'] || []).includes(item.attr.id)
            )
          )
        })
        return {'form':form}
      }))
  }

  postEnterpriseDirectoryForm(form) {
    return this.http.post(`${this.baseUrl}/enterprise-directory/create`,
      form,
    );
  }

  deleteEnterpriseDirectory() {
    return this.http.delete(`${this.baseUrl}/enterprise-directory/delete`);
  }

  validateEnterpriseDirectory(id: string) {
    return this.http.post(`${this.baseUrl}/admin/enterprise-directory/validate/${id}`,
      {},
    );
  }

  invalidateEnterpriseDirectory(id: string) {
    return this.http.post(`${this.baseUrl}/admin/enterprise-directory/invalidate/${id}`,
      {},
    );
  }

  getAdminStatsKeyNumbers() {
    return this.http.get(`${this.baseUrl}/admin/stats/key-numbers`,
      {},
    );
  }

  getAdminStatsQr() {
    return this.http.get(`${this.baseUrl}/admin/stats/questionnaires`,
      {},
    );
  }

  getAdminStatsQrYearly() {
    return this.http.get(`${this.baseUrl}/admin/stats/yearly`,
      {},
    );
  }

  private parseForm(data: any) {
    const items = this.parseJson(data['form']['properties']);
        let form = [];

        items.map((item, itemIndex) => {

          let choices = [];
          if (item.hasOwnProperty("enum")) {

            this.parseJson(item.enum).map((enumValue, enumIndex) => {
             // console.log(enumValue)
              choices.push({
                "value": enumValue,
                "label": item.enum_titles[enumIndex]
              })
            })
          }

          if (item.hasOwnProperty("items")) {

            this.parseJson(item.items.enum).map((enumValue, enumIndex) => {
              // console.log(enumValue)
              choices.push({
                "value": enumValue,
                "label": item.items.enum_titles[enumIndex]
              })
            })
          }

          let fieldType = 'text';
          if (choices.length > 0) {
            fieldType = 'choice';
          } else if (item.type === 'boolean') {
            fieldType = 'checkbox';
          }

          form.push(
            new IdentificationField(
              fieldType,
              item.title,
              `${data['form']['title']}[${item.attr.id}]`,
              item.attr.id,
              choices
            )
          )
        })
        return {'form':form}
  }
}
