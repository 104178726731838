<ns-navbar></ns-navbar>
<div id="body-container"  >
  <ns-sidebar></ns-sidebar>
    <div id="content-wrapper">
        <div class="pro row">
            <form (ngSubmit)="payment(offerForm.value)" #offerForm="ngForm">
                <div class="pro__title">
                    <h2>Souscrivez à l'offre {{ offer?.name }}</h2>
                    <span><b>Au tarif de {{ offer?.price }}€ </b>/mois</span>
                </div>

            <div id="card-errors" class="alert alert-danger"  *ngIf="error?.length">
                {{error}}
            </div>
              <!-- <stripe-card style="width:100%" *ngIf="stripeReady" #stripeCard
                           (catch) = "onStripeError($event)"
                           [(complete)] = "cardDetailsFilledOut"
                           [(invalid)] = "invalidError"
                           (cardMounted) = "cardCaptureReady = true"
                           (paymentMethodChange) = "setPaymentMethod($event)"
                           (tokenChange) = "setStripeToken($event)"
                           (sourceChange) = "setStripeSource($event)"
              ></stripe-card> -->
                <label class="pro__checkbox">
                    <input type="checkbox" name="payment[authorizeSubscription]" class="form-check-input" required ngModel/>
                        J'autorise Gen'ethic à me débiter automatiquement tous les mois jusqu'à ce que j'annule mon abonnement.
                  <a routerLink="cgu">
                    Offre soumise à conditions .
                  </a>

                </label>
                <label class="pro__checkbox">
                    <input type="checkbox" name="payment[acceptTerms]" class="form-check-input" required ngModel>
                        J'accepte les <a routerLink="cgu">conditions générales d'utilisation.</a>

                </label>
                <input type="hidden" name="payment[cardToken]">
                <input type="hidden" name="payment[_token]" ngModel>

                <input class="pro__submit" type="submit" [value]="'Souscrire à Gen\'Ethic '+(offer?.name | uppercase)" />
            </form>
            <ul class="pro__list col">
                    <li *ngFor="let item of offer?.description" class="pro__list__elem">
                      <div *ngIf="!item['disabled']">
                        &#10003;
                        <div>
                            <p class="pro__list__elem__description">
                                {{ item?.text }}
                            </p>
                        </div>
                      </div>
                    </li>
            </ul>
        </div>
    </div>
</div>

