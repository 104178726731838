import { Component } from '@angular/core';
import { SidebarItem } from "../models/sidebar_item.model"
import {Router} from '@angular/router';
import {User} from "../models/user.model"
import {UserService} from "../services/user.service"
import { NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {MarqueService} from "../services/marque.service"
import {Marque} from "../models/marque.model"
import {AssetService} from "../services/asset.service"
import { MenuService } from '../services/menu.service';

@Component({
  selector: 'ns-navbar',
  templateUrl: '../templates/backend/partials/_navbar.component.html',
})
export class NavbarComponent {

  public user : User
  public marque : Marque
  public ready: boolean = false
  public privacyPolicyFilePath: string;

  constructor(
    private router: Router,
    private userService : UserService,
    private marqueService: MarqueService,
    public assetService: AssetService,
    private menuService: MenuService
  ) {

  }

  ngAfterContentInit() {
    this.ready = false
    this.userService.getUser().subscribe(response => {
      if (response instanceof User) {
        this.user = response
        return this.user
      }
    })

    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response
        this.ready = true
        return this.marque
      }
    })

    this.assetService.getPrivacyPolicyFilePath().subscribe((filePath: string) => {
      this.privacyPolicyFilePath = filePath;
    });
  }

  toggleMenu() {
    this.menuService.toggle();
  }

  isActive(url: string)
  {
    return this.router.isActive(url, true)
  }
}
