import {Injectable} from '@angular/core';
import { environment } from './../../environments/environment';
import { MarqueService } from './marque.service';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Marque } from '../models/marque.model';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  private readonly baseDocumentPath: string = 'assets/documents';
  private cguFilePath: string;
  private privacyPolicyFilePath: string;
  private legalNoticeFilePath?: string;

  constructor(
    private readonly marqueService: MarqueService
  ) {} 

  getUrl(path: String) {
    return environment.assetUrl+path
  }

  getLogo(marque: String) {
    let path = '/assets/images/medias/'
    switch (marque) {
      case 'genethic':
        return path+'logo-genethic.svg'
      case 'aderly':
        return path+'logo-aderly.svg'
      case 'grandlyon':
        return path+'logo-grandlyon.png'
      case 'rennes':
        return path+'logo-rennes.png'
      case 'mcapital':
        return path+'logo-mcapital.png'
      case 'bordeaux':
        return path+'logo-bordeaux.svg'
      case 'village':
        return path+'logo-village.png'
      case 'ninkasi':
        return path+'logo-ninkasi.png'
      case 'lille':
        return path+'logo-lille.png'
      case 'paca':
        return path+'logo-paca.png'
    }
  }


  getCguFilePath() {
    if (this.cguFilePath) return of(this.cguFilePath);

    return this.marqueService.getMarque().pipe(
      switchMap((marque: Marque) => {
        switch (marque?.slug) {
          case 'bordeaux':
            this.cguFilePath = `${this.baseDocumentPath}/mentions-legales-et-CGU_Plateforme-RSE-Bordeaux-Vf.pdf`;
            break;
          default:
            this.cguFilePath = `${this.baseDocumentPath}/cgu.pdf`;
            break;
        }
        return of(this.cguFilePath)
      })
    );
  }


  getPrivacyPolicyFilePath() {
    if (this.privacyPolicyFilePath) return of(this.privacyPolicyFilePath);

    return this.marqueService.getMarque().pipe(
      switchMap((marque: Marque) => {
        switch (marque?.slug) {
          case 'bordeaux':
            this.privacyPolicyFilePath = `${this.baseDocumentPath}/politique-de-confidentialite_plateforme-RSE-Bordeaux.pdf`;
            break;
          default:
            this.privacyPolicyFilePath = `${this.baseDocumentPath}/cgu.pdf`;
            break;
        }
        return of(this.privacyPolicyFilePath)
      })
    );
  }

  
  getLegalNoticeFilePath() {
    if (this.legalNoticeFilePath) return of(this.legalNoticeFilePath);

    return this.marqueService.getMarque().pipe(
      switchMap((marque: Marque) => {
        switch (marque?.slug) {
          case 'bordeaux':
            this.legalNoticeFilePath = `${this.baseDocumentPath}/mentions-legales-Plateforme-RSE-Bordeaux.pdf`;
            break;
        }
        return of(this.legalNoticeFilePath)
      })
    );
  }
}
