
export class SidebarItem {
    constructor(public cssClass: string,
                public url: string,
                public label: string,
                public iconPath: string,
                public isActive: boolean,
                public isHovered: boolean = false,
                public isSeparator: boolean = false,
                public target: '_self' | '_blank' = '_self'
                ) {
    }
}
