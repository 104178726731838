<ns-navbar></ns-navbar>
<div id="body-container">
  <ns-sidebar sidebarType="private"></ns-sidebar>
  <div id="content-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8 welcome-dashboard" style="text-align: center">
          <form (ngSubmit)="putForm(memberForm.value)" #memberForm="ngForm">
            <div class="survey identification container">

              <div class="survey-header">
                <h1>
                  <i class="fa-solid fa-city me-3"></i>
                  Nouveau projet d'entreprise
                </h1>
                <div class="survey-header-bottom">
                  <p>Renseignez le fond d'investissement et le nom du nouveau projet.</p>
                  <p>Le membre recevra une invitation par email afin de compléter le nouveau questionnaire correspondant aux critères
                    sélectionnés.</p>
                </div>
              </div>

              <div class="survey-content" *ngIf="ready">

                <div class="form-group">
                  <label class="col-sm-2 col-form-label">Email&nbsp;*</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control ap-input" style="position: relative; vertical-align: top; cursor: not-allowed; background-color: #e9ecef;" [value]="member?.email" disabled>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-sm-2 col-form-label">Nom de l'entreprise&nbsp;*</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control ap-input" style="position: relative; vertical-align: top; cursor: not-allowed; background-color: #e9ecef;" [value]="member?.companyName" disabled>
                  </div>
                </div>

                <div class="form-group" *ngFor="let field of form">
                  <label [attr.for]="field.id" class="col-sm-2 col-form-label">{{field.label}}
                    <span *ngIf="field.id !== 'projectName'">&nbsp;*</span>
                  </label>
                  <div class="col-sm-10" [ngClass]="[field.type === 'checkbox' ? 'text-left' : '']">
                    <input *ngIf="field.type == 'text'" ngModel type="text" [id]="field.id"
                      [name]="'add_new_project_for_membre_reseau_form['+field.id+']'" maxlength="255" class="form-control ap-input"
                      [placeholder]="field.label" autocomplete="off" spellcheck="false" role="combobox"
                      aria-autocomplete="both" aria-expanded="false" aria-owns="algolia-places-listbox-0" dir="auto"
                      style="position: relative; vertical-align: top;">

                    <select *ngIf="field.id == 'investmentFund'" ngModel [id]="field.id"
                      [name]="'add_new_project_for_membre_reseau_form['+field.id+']'" class="form-control">
                      <option *ngFor="let choice of field.choices" [value]="choice.value" selected="selected">
                        {{choice.label}}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="text-center" *ngIf="ready">
                <a routerLink="/network" class="btn btn-outline-secondary me-4">Annuler</a>
                <button type="submit" [ngClass]="[marque.slug, 'btn', 'btn-primary']" [disabled]="!isFormValid(memberForm.value)">VALIDER</button>
              </div>
            </div>

          </form>
        </div>
        <div class="col-md-2"></div>
      </div>


    </div>
  </div>
</div>
