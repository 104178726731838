<div *ngIf="isReady">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Nouvelle évaluation pour {{user.companyName}}</h4>
    <button type="button" class="btn-close" style="outline: none;box-shadow: none;" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="modal-body" [ngClass]="[marque?.slug]">
    <div>
      <div class="custom-radio custom-control mb-2">
        <input type="radio" id="delete-qr" name="delete_option" [value]="NetworkNewProjectModalResultType.SameProject" class="custom-control-input" [(ngModel)]="selectedOption" ngModel>
        <label for="delete-qr" class="custom-control-label">Envoyer une nouvelle demande pour le même fond/projet</label><br>
      </div>
      <div class="custom-radio custom-control">
        <input type="radio" id="delete-user" name="delete_option" [value]="NetworkNewProjectModalResultType.NewProject" class="custom-control-input" [(ngModel)]="selectedOption" ngModel>
        <label for="delete-user" class="custom-control-label">Créer un nouveau projet pour cette entreprise</label><br>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn" (click)="close()">Annuler</button>
    <button type="button" class="btn btn-danger" (click)="validate()">
      <span *ngIf="selectedOption === NetworkNewProjectModalResultType.NewProject">Suivant</span>
      <span *ngIf="selectedOption === NetworkNewProjectModalResultType.SameProject">Envoyer la demande</span>
    </button>
  </div>
</div>
