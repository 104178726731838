import { Component } from '@angular/core';
import {JsonService} from "../services/json.service";
import {ActivatedRoute, ParamMap, Router} from "@angular/router"
import {Offer} from "../models/offer.model"
//import {StripeScriptTag} from "stripe-angular"
import { environment } from '../../environments/environment';

@Component({
  selector: 'ns-offer',
  templateUrl: '../templates/backend/offer.component.html'
})

export class OfferComponent {
  title = 'angularfront'

  public name: string
  public offer: Offer
  public error: string
  public state: string
  public stripePublicKey: string
  public cardCaptureReady: boolean
  public invalidError: any
  public stripeReady: boolean
  constructor (private api: JsonService, private route: ActivatedRoute/* , private stripeScriptTag: StripeScriptTag */) {
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.name = params.get('name')
    })

  }

  ngAfterViewInit(): void {
    this.getOffer()
    this.stripePublicKey = environment.stripeApiPublicKey
    //this.stripeScriptTag.setPublishableKey(this.stripePublicKey)
  }

  getOffer() {
    this.api.getOffer(this.name).subscribe(content => {
        this.offer = content['offer']

      this.stripeReady = true
    });
  }

  payment(infos) {
    this.api.postPayment(this.name, infos).subscribe(content => {
      if (content['state'].length) {
        this.state = content['state']
      } else {
        this.error = content['error']
      }

    });
  }

  onStripeInvalid( error: Error ){
    console.log('Validation Error', error)
  }

  onStripeError( error: Error ){
    console.error('Stripe error', error)
  }

  setPaymentMethod( token/* : stripe.paymentMethod.PaymentMethod */ ){
    console.log('Stripe Payment Method', token)
  }

  setStripeToken( token/* : stripe.Token */ ){
    console.log('Stripe Token', token)
  }

  setStripeSource( source/* : stripe.Source */ ){
    console.log('Stripe Source', source)
  }

  cardDetailsFilledOut() {}
}
