<nav *ngIf="ready && marque" id="sidebar-main-container" class="nav-sidebar  d-none d-lg-block"> <!--{%  if smallMenu is defined and smallMenu %} retract{% endif %}-->
  <div class="nav-sidebar-container" [ngClass]="{'nav-sidebar-container__admin': sidebarType =='private'}">
    <div *ngIf="sidebarType === 'private'" class="user-sidebar mb-5 pt-5">
      <div class="user-sidebar-img d-flex shadow-lg justify-content-center align-items-center rounded-circle mx-auto">
          <img class="img-fluid img-shortened" [src]="getLogoUrl()"> <!--asset(user.logoUrl)-->
      </div>
      <div class="user-sidebar-company my-4">
          <!-- <span class="font-weight-bold">{{ user?.companyName }}</span> -->
          <span>{{ user?.fullName }}</span>
      </div>
    </div>
    <ul class="menu-sidebar " [ngClass]="{'nav-sidebar__disabled': isDisabled}" >
      <ng-container *ngFor="let item of items">
        <li [ngClass]="item.cssClass" (mouseenter)="item.isHovered = true" (mouseleave)="item.isHovered = false">
            <a class="nav-sidebar__link" [routerLink]="['/'+item.url]">
                <span class="img">
                    <img [src]="'assets/images/medias/'+marque.slug+'/'+item.iconPath+(isDisabled?'-disabled':(isActive(item.url) || item.isHovered) ? '-active' : '')+'.svg'" alt="">
                </span>
                <span [ngClass]="marque.slug+'-'+(isActive(item.url) ? 'active' : 'inactive')" style="font-size:15px;margin-top:2px;">
                  {{ item.label }}
                </span>
            </a>
        </li>
      </ng-container>

    </ul>
    <div class="sidebar_logout">
      <a class="nav-sidebar__link" routerLink="/logout">
        <span class="img">
          <!-- <img style="width:20px;" [src]="'assets/images/medias/'+marque.slug+'/deco.svg'" alt=""> -->
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960"><path d="M224.615-160Q197-160 178.5-178.5 160-197 160-224.615v-510.77Q160-763 178.5-781.5 197-800 224.615-800h256.154v40H224.615q-9.23 0-16.923 7.692Q200-744.615 200-735.385v510.77q0 9.23 7.692 16.923Q215.385-200 224.615-200h256.154v40H224.615Zm433.846-178.461-28.077-28.77L723.154-460H367.692v-40h355.462l-92.77-92.769 28.077-28.77L800-480 658.461-338.461Z"/></svg>
        </span>
      </a>
    </div>
  </div>
</nav>
