import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";

/**
 * Network brands like MCapital or Ninkasi must have signup disabled
 */
@Injectable()
export class RegisterGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    const subdomain = window.location.hostname.split('.')?.shift();
    if (['mcapital', 'ninkasi'].includes(subdomain)) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
