import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {JsonService} from '../services/json.service';
import {Questionnaire} from '../models/questionnaire.model';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {MarqueService} from '../services/marque.service';
import {Marque} from '../models/marque.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {QuestionnaireHelperModalComponent} from './questionnaireHelperModal.component';

@Component({
  selector: 'ns-questionnaire',
  templateUrl: '../templates/questionnaire.component.html'
})

export class QuestionnaireComponent implements OnInit, AfterViewInit, OnDestroy{
  title = 'angularfront';

  public questionnaire: Questionnaire;
  public step: any;
  public reachEnd: boolean;
  public atStart: boolean;
  public nextStepLabel: string;
  public token: string;
  public sending: boolean;
  public sentSuccessfully: boolean;
  public ready: boolean = false;
  public marque: Marque = null;
  private autoSaveIntervalId: any = null;

  @ViewChild('questionnaireForm') questionnaireForm: NgForm;
  @ViewChild('submitQuestionnaireFormModal') submitQuestionnaireFormModal: ElementRef;

  /**
   * questionNumberMap[categoryIndex].[subcategoryIndex].[questionIndex]: number
   */
  questionNumberMap: Record<string, Record<string, Record<string, number>>> = {}

  constructor (
    private api: JsonService,
    private router: Router,
    private toastr: ToastrService,
    private marqueService: MarqueService,
    private modalService: NgbModal
  ) {
    this.step =  {'category': 0, 'subCategory': 0};
    this.reachEnd = false;
  }

  ngOnInit(): void {
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response;

        return this.marque;
      }
    });
  }

  ngAfterViewInit(): void {
    this.ready = false;
    this.atStart = true;
    this.getProjects();

    this.autoSaveIntervalId = setInterval(() => {
      this.submitForm(this.questionnaireForm, false, true);
    }, 3 * 60 * 1000);
  }

  ngOnDestroy(): void {
    if (null !== this.autoSaveIntervalId) {
      clearInterval(this.autoSaveIntervalId);
    }
  }

  getProjects() {
    this.api.getQuestionnaire().subscribe(questionnaire => {
      if (questionnaire['redirect_to']) {
        this.router.navigate(['/identification']);
        return;
      }
      this.questionnaire = questionnaire['form'];
      this.token = questionnaire['token'];
      this.updateQuestionNumberMap();
      this.getNextStepLabel();
      this.ready = true;
    });
  }

  private submitForm(questionnaire, completed: boolean, hidden: boolean) {
    if (!this.sending && !this.sentSuccessfully) {
      if (false === hidden) {
        this.nextStepLabel = 'Envoi du questionnaire ...';
      }

      this.sending = true;

      this.api.postQuestionnaire(questionnaire.value, completed).subscribe(response => {
        if (false === hidden && typeof response !== 'undefined' && response['result'] === 'success') {
          this.sentSuccessfully = true;
          this.router.navigate(completed ? ['/adn'] : ['/']);
        } else if (typeof response !== 'undefined' && response['result'] === 'error') {
          this.toastr.error(response['error'], 'Erreur');
        }
      });
    }

    this.sending = false;
  }

  submit(questionnaire, completed: boolean) {
    if (completed === false) {
      this.submitForm(questionnaire, completed, false);
    }

    this.modalService.open(this.submitQuestionnaireFormModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'lg',
      windowClass: 'has-already-login-modal',
      container: '.app-body',
    }).result.then(() => {
      this.submitForm(questionnaire, completed, false);
    });
  }

  previousStep(elementToScroll, questionnaireForm) {
    if (this.step.subCategory === 0 && this.step.category > 0) {
      this.step.category--;
      this.step.subCategory =  this.questionnaire.categories[this.step.category].subCategories.length - 1;
      elementToScroll.scrollIntoView();
    } else if (this.step.subCategory > 0) {
      this.step.subCategory--;
      if (this.step.subCategory === 0 && this.step.category === 0) {
        this.atStart = true;
      }
      elementToScroll.scrollIntoView();
    } else {
      this.atStart = true;
    }

    this.getNextStepLabel();

    this.submitForm(questionnaireForm, false, true);
  }

  nextStep(elementToScroll, questionnaireForm) {
    if (this.step.subCategory + 1 < this.questionnaire.categories[this.step.category].subCategories.length) {
      this.step.subCategory++;
      this.atStart = false;
      elementToScroll.scrollIntoView();
    } else if (this.questionnaire.categories.length > this.step.category + 1) {
      this.step.subCategory = 0;
      this.step.category++;
      this.atStart = false;
      elementToScroll.scrollIntoView();
    } else {
      this.reachEnd = true;
      this.atStart = false;
    }

    this.getNextStepLabel();

    this.submitForm(questionnaireForm, false, true);
  }

  goToCategory(categoryIndex) {
    this.step.category = categoryIndex;
    this.step.subCategory = 0;
    this.atStart = this.step.category === 0;
  }

  getNextStepLabel() {
    if (this.step.subCategory + 1 < this.questionnaire.categories[this.step.category].subCategories.length) {
      this.nextStepLabel = 'Etape suivante';
    } else if (this.questionnaire.categories.length > this.step.category + 1) {
      this.nextStepLabel = 'Etape suivante';
    } else {
      this.nextStepLabel = 'Valider';
    }
  }

  showHelper(helperChain: string) {
    if (null === helperChain) {
      return;
    }

    const [helperMessage, helpLink] = helperChain.split('###');

    const helperModal = this.modalService.open(QuestionnaireHelperModalComponent, {ariaLabelledBy: 'modal-basic-title', centered: true});
    helperModal.componentInstance.helpMessage = helperMessage.trim();
    helperModal.componentInstance.helpLink = helpLink.trim();
  }

  downloadQuestionnaireExcel() {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    this.api.getQuestionnaireExport().subscribe((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = 'questionnaire.xlsx';
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
  }

  private updateQuestionNumberMap() {
    this.questionNumberMap = {}
    let increment = 1;
    for (let catIndex = 0; catIndex < this.questionnaire.categories?.length || 0 ; catIndex++) {
      this.questionNumberMap[catIndex] = {}
      for (let subCatIndex = 0; subCatIndex < this.questionnaire.categories![catIndex].subCategories?.length || 0 ; subCatIndex++) {
        this.questionNumberMap[catIndex][subCatIndex] = {}
        for (let questionIndex = 0; questionIndex < this.questionnaire.categories![catIndex].subCategories![subCatIndex].questions?.length || 0 ; questionIndex++) {
          this.questionNumberMap[catIndex][subCatIndex][questionIndex] = increment;
          increment++;
        }
      }
    }
  }

  getQuestionNumberFormatted(categoryIndex: number, subCategoryIndex: number, questionIndex: number): number {
    if (this.marque.slug !== 'paca') {
      return questionIndex + 1;
    }
    return this.questionNumberMap[categoryIndex][subCategoryIndex][questionIndex]
  } 
}

