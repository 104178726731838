import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SidebarItem } from '../../models/sidebar_item.model';
import {Router} from '@angular/router';
import {User} from '../../models/user.model';
import {UserService} from '../../services/user.service';
import {AssetService} from '../../services/asset.service';
import {MarqueService} from '../../services/marque.service';
import {Marque} from '../../models/marque.model';
import {JsonService} from '../../services/json.service';
import {AdnService} from '../../services/adn.service';
import { MenuService } from '../../services/menu.service';
import { Subject, forkJoin } from 'rxjs';

@Component({
  selector: 'ns-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit, OnDestroy {

  @Input()
  public sidebarType: string;
  @Input()
  public items: Array<any>;
  public marque: Marque = null;
  public ready: boolean = false;
  public isDisabled: boolean = true;
  public user: User;
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    public assetService: AssetService,
    private marqueService: MarqueService,
    private api: JsonService,
    private userService: UserService,
    private adnService: AdnService,
    private menuService: MenuService
  ) {
    this.items = [
      new SidebarItem('sidebar_adn', 'adn', 'Score ESG', 'adn', this.isActive('adn')),
      new SidebarItem('sidebar_upgrades', 'upgrades', 'Améliorations', 'search', this.isActive('upgrades')),
      new SidebarItem('sidebar_follow', 'history', 'Suivi', 'startup', this.isActive('suivi')),
      //new SidebarItem('sidebar_enterpriseDirectory', 'directory', 'Annuaire', 'users', this.isActive('directory')),
      // new SidebarItem('sidebar_audience', 'documents', 'Documents', 'file', this.isActive('documents')),
      // new SidebarItem('sidebar_audience', 'good-practices', 'Ressources', 'goodpractices', this.isActive('good-pratices')),
    ];
  }

  ngOnInit(): void {

    forkJoin({
      marque: this.marqueService.getMarque(),
      user: this.userService.getUser()
    })
    .subscribe(({ marque, user }) => {
      if (marque instanceof Marque) this.marque = marque;
      if (user instanceof User) this.user = user;
      this.ready = true;



      if (this.sidebarType === 'private') {
        // Menu network (mcapital)
        this.isDisabled = false;
        this.items = [
          new SidebarItem('sidebar_follow', '/network', 'Votre réseau', 'search', this.isActive('network_home')),
          new SidebarItem('sidebar_follow', '/network/documents', 'Documents', 'file', this.isActive('network_documents')),
        ];
  
        this.userService.getUser().subscribe(response => {
          if (response instanceof User) {
            this.user = response;
            return this.user;
          }
        });
      } else if (this.sidebarType === 'admin') {
        // Menu admin
        this.isDisabled = false;
  
        this.items = [
          new SidebarItem('sidebar_follow', 'admin', 'Vue d\'ensemble', 'adn', this.isActive('admin_home')),
          new SidebarItem('sidebar_follow', 'admin/statistics', 'Statistiques', 'startup', this.isActive('admin_statistics')),
          //new SidebarItem('sidebar_follow', 'admin/modify-logo', 'Modification de logo', 'search', this.isActive('admin_modify_logo')),
          new SidebarItem('sidebar_follow', 'admin/documents-review', 'Documents', 'file', this.isActive('admin_review_documents')),
          //new SidebarItem('sidebar_follow', 'admin/export', 'Export', 'search', this.isActive('admin_export')),
          new SidebarItem('sidebar_follow', 'admin/assistance-systems', 'Accompagnement', 'goodpractices', this.isActive('admin_assistance_systems')),
        ];

        if (this.user.isSuperAdmin) {
          this.items.push(new SidebarItem('sidebar_audience', 'admin/users', 'Administrateurs', 'users', this.isActive('admin_users')));
          //this.items.push(new SidebarItem('sidebar_enterpriseDirectory', 'admin/directory', 'Annuaire', 'users', this.isActive('admin_directory')));
        }
      } else {
        // Menu enterprise
        this.items = [
          new SidebarItem('sidebar_home', '/', 'Accueil', 'home', this.isActive('/')),
          ...this.items
        ]
        this.getData();
        if (!['bordeaux', 'rennes', 'paca'].includes(this.marque.slug)) {
          this.items.push(new SidebarItem('sidebar_audience', 'documents', 'Documents', 'file', this.isActive('documents')));
        }
        if (this.marque.hasAssistanceSystems === true) {
          this.items.push(new SidebarItem('sidebar_assistance_systems', 'assistance-systems', 'Accompagnement', 'goodpractices', this.isActive('assistance_systems')));
        }
      }
    });
  }

  getData() {
    if (this.adnService.isLoaded()) {
      this.isDisabled = this.adnService.getStoredAdn() === null;
    }

    this.ready = true;
    this.adnService.getRefreshedAdn().subscribe(data => {
      this.isDisabled = data.adn === null;
    });
  }

  isActive(url: string) {
    return this.router.isActive(url, true);
  }


  getLogoUrl() {
    if (this.user?.logoUrl) {
      return this.assetService.getUrl(this.user?.logoUrl);
    }
    if (this.marque?.slug) {
      return this.assetService.getLogo(this.marque.slug)
    }
  }

  ngOnDestroy() {
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }
}
