<ns-navbar></ns-navbar>
<div id="body-container">
  <ns-sidebar></ns-sidebar>
  <div id="content-wrapper" class="assistance-systems container-padding">
    <ns-spinner *ngIf="!ready"></ns-spinner>
    <ng-container *ngIf="ready">
      <div *ngIf="supportSystemPdfUrl || supportSystemPdfUrl" class="col-md-10 col-lg-10" style="margin-bottom:40px;">

        <div class="row">
          <h1>Annuaires et guides</h1>
        </div>

        <div class="mt-4">
          <div class="assistance-systems-bloc" *ngIf="directoryPdfUrl">
            <h3>Annuaire des prestataires privés pour la transformation durable</h3>
            <p>Une liste, non exaustive, de prestataires privés du territoire, en capacité d'aider les entreprises dans leur transformation durable.</p>
            <a [href]="directoryPdfUrl" target="_blank">
              <i class="fas fa-arrow-right"></i>
            </a>
          </div>

          <div class="assistance-systems-bloc" *ngIf="supportSystemPdfUrl">
            <h3>Dispositifs d'accompagnement à la transition écologique</h3>
            <p>Un guide pratique pour connaître les aides dédiées à la transition écologique des entreprises.</p>
            <a [href]="supportSystemPdfUrl" target="_blank">
              <i class="fas fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>

      <div class="col-md-10 col-lg-10">

        <div class="row">
          <h1>Aides à disposition</h1>
        </div>

        <div class="mt-4">
          <div class="assistance-systems-bloc" *ngFor="let assistanceSystem of assistanceSystems">
            <h3>{{ assistanceSystem.title }}</h3>
            <p>{{ assistanceSystem.description }}</p>
            <a href="{{ assistanceSystem.link }}" target="_blank">
              <i class="fas fa-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
