import { Component, OnInit } from '@angular/core';
import { JsonService } from "../../../services/json.service"
import { MarqueService } from '../../../services/marque.service';
import { Marque } from '../../../models/marque.model';
import { forkJoin } from 'rxjs';
import { ChartConfiguration, ChartOptions } from 'chart.js';

@Component({
  selector: 'ns-admin-stats',
  templateUrl: './admin-stats.component.html',
  styleUrls: ['./admin-stats.component.scss']
})
export class AdminStatsComponent implements OnInit {

  public ready: boolean = false;
  public marque: Marque;

  public totalQrCreated: number = 0;
  public totalQrValidated: number = 0;
  public totalQrValidatedRatio: number = 100;
  public totalUserWithAtLeastOneCompletedQr: number = 0;
  public totalUser: number = 0;
  public totalUserWithoutQr: number = 0;
  public totalUserWithQrRatio: number = 100;
  public totalComeBackRatio: number = 100;
  public bestYearDate: string = '';
  public bestYearCount: number = 0;

  /** QR bar chart */
  public qrChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [],
    datasets: []
  };
  public qrChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    plugins: { legend: { position: 'bottom' } }
  };
  public qrChartReady: boolean = false;


  /** ValidationRate pie chart */
  public validationRateChartData = [];
  public validationRateChartLabels = [];
  public pieChartOptions: ChartOptions<'pie'> = {
    responsive: false,
    plugins: { legend: { position: 'bottom' } },
    //radius: '60%'
  };
  public validationRateChartReady: boolean = false;


  /** YearlyOverview pie chart */
  public yearlyOverviewChartData = [];
  public yearlyOverviewChartLabels = [];
  public yearlyOverviewChartReady: boolean = false;


  constructor(
    private api: JsonService,
    private marqueService: MarqueService,
  ) { }

  ngOnInit() {
    forkJoin({
      marque: this.marqueService.getMarque(),
    }).subscribe((res) => {
      this.marque = res.marque as Marque;

      this.getAdminStatsKeyNumbers();
      this.setupQrChart();
      this.setupYearlyOverviewChart();
      this.ready = true;
    })
  }

  private getAdminStatsKeyNumbers() {
    this.api.getAdminStatsKeyNumbers().subscribe((res: any) => {
      this.totalUser = res?.totalUser || 0;
      this.totalUserWithoutQr = res?.totalUserWithoutQr || 0;
      this.totalUserWithAtLeastOneCompletedQr = res?.totalUserWithAtLeastOneCompletedQr || 0;
      this.totalUserWithQrRatio = Math.round(this.totalUserWithAtLeastOneCompletedQr * 100 / this.totalUser);
      this.totalComeBackRatio = res?.totalComeBackRatio || 0;
    })
  }

  private setupQrChart() {
    this.api.getAdminStatsQr().subscribe((res: any) => {
      this.qrChartData['datasets'] = res?.data || [];
      this.qrChartData['labels'] = res?.labels || [];
      if (this.qrChartData['datasets']?.length > 1) {
        this.qrChartData['datasets'][0]['backgroundColor'] = this.marqueService.borderColor[this.marque.slug];
        this.qrChartData['datasets'][0]['borderColor'] = this.marqueService.borderColor[this.marque.slug];
        this.qrChartData['datasets'][0]['borderWidth'] = 2;
        this.qrChartData['datasets'][1]['backgroundColor'] = this.marqueService.backgroundColor[this.marque.slug];
        this.qrChartData['datasets'][1]['borderColor'] = this.marqueService.borderColor[this.marque.slug];
        this.qrChartData['datasets'][1]['borderWidth'] = 2;
      }
      this.qrChartReady = true;

      this.totalQrCreated = res?.totalCreated || 0;
      this.totalQrValidated = res?.totalValidated || 0;
      this.totalQrValidatedRatio = Math.round(this.totalQrValidated * 100 / (this.totalQrCreated || 1));

      this.validationRateChartData = [{
        data: [this.totalQrValidatedRatio, 100 - this.totalQrValidatedRatio],
        backgroundColor: [this.marqueService.borderColor[this.marque.slug], this.marqueService.backgroundColor[this.marque.slug]],
        borderColor: this.marqueService.borderColor[this.marque.slug],
      }];
      this.validationRateChartLabels = ['Validés', 'Non validés'];
      this.validationRateChartReady = true;
    });
  }

  private setupYearlyOverviewChart() {
    this.api.getAdminStatsQrYearly().subscribe((res: any) => {
      this.yearlyOverviewChartLabels = res?.labels || [];
      const data: [] = res?.data || [];
      const backgroundColors = [];
      for (let d of data) {
        const i = data.length - backgroundColors.length;
        backgroundColors.push(
          this.hexToRGB(this.marqueService.borderColor[this.marque.slug], i / data.length)
        );
      }

      const best = data.reduce((prev, curr, index) => {
        if (curr > prev.bestYearCount) {
          prev.bestYearCount = curr;
          prev.bestYearDate = res?.labels[index];
        }
        return prev;
      },
        { bestYearDate: '', bestYearCount: 0 }
      );
      this.bestYearCount = best.bestYearCount;
      this.bestYearDate = best.bestYearDate;

      this.yearlyOverviewChartData = [
        {
          data: data,
          backgroundColor: backgroundColors,
          borderColor: this.marqueService.borderColor[this.marque.slug],
        },
      ];
      this.yearlyOverviewChartReady = true;
    });
  }

  private hexToRGB(hex: string, alpha?: number): string {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);
    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
  }
}
