import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { Marque } from 'src/app/models/marque.model';
import { User } from 'src/app/models/user.model';
import { AssetService } from 'src/app/services/asset.service';
import { JsonService } from 'src/app/services/json.service';
import { MarqueService } from 'src/app/services/marque.service';

@Component({
  selector: 'ns-super-admin-users',
  templateUrl: './super-admin-users.component.html'
})
export class SuperAdminUsersComponent implements OnInit, AfterViewInit, OnDestroy {
  public readyMarque = false;
  public readyUsers = false;

  private users: User[] = [];
  public filteredData: User[] = [];

  public userCount: number = 0;
  public marque: Marque;

  public currentOrderIdentifier = 'id';
  public currentOrderWording = 'Date';

  public searchFormControl = new UntypedFormControl();
  private searchSubscription: Subscription;

  isReady() {
    return this.readyMarque && this.readyUsers;
  }

  constructor(
    private api: JsonService,
    public assetService: AssetService,
    private marqueService: MarqueService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
  }

  ngOnInit(): void {
    this.readyMarque = false;
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response;
        this.readyMarque = true;
      }
    });
  }

  ngAfterViewInit(): void {
    this.getData();
    this.searchSubscription = this.searchFormControl.valueChanges
      .pipe(debounceTime(600))
      .subscribe(searchTerm => {
        this.applyFilters();
        if (searchTerm) {
          this.filteredData = this.filteredData.filter((u: User) => {
            return u.companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
              u.email.toLowerCase().includes(searchTerm.toLowerCase());
          })
        }
      });
  }

  getData() {
    this.api.getAdminUserListAsSuperAdmin(this.currentOrderIdentifier).subscribe(data => {
      this.users = data;
      this.applyFilters();
      this.readyUsers = true;
    });
  }

  updateOrdering(identifier, wording) {
    this.currentOrderIdentifier = identifier;
    this.currentOrderWording = wording;
    this.readyUsers = false;
    this.searchFormControl.setValue('');
    this.getData();
  }

  private applyFilters() {
    const filteredData = [];
    
    for (let m of this.users) {
      filteredData.push(m)
    }
    this.filteredData = filteredData;
    this.userCount = this.filteredData.length;
  }


  

  openDeleteModal(modal, user: User) {
    const modalRef = this.modalService.open(modal, {ariaLabelledBy: 'modal-basic-title', centered: true});
    modalRef.closed.pipe(
      take(1)
    ).subscribe(res => {
      console.log('res is ', res)
      if (res) {
        this.deleteUser(user.id);
      }
    });
  }

  private deleteUser(id) {
    this.api.deleteAdminUserAsSuperAdmin(id).subscribe(content => {
      this.getData();
      if (content['result']) {
        this.toastr.success('L\'administrateur à bien été supprimé', 'Suppression');
      } else {
        this.toastr.error('Une erreur s\'est produite lors de la suppression', 'Suppression');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe()
    }
  }
}
