import { Component } from '@angular/core';
import { JsonService } from "../../../services/json.service";
import { ToastrService } from "ngx-toastr"
import { AssetService } from "../../../services/asset.service"
import { environment } from "../../../../environments/environment"

@Component({
  selector: 'ns-admin-export',
  templateUrl: './admin-export.component.html'
})
export class AdminExportComponent {

  title = 'angularfront'
  public baseUrl: string
  public token: string
  public isDownloadingXls: boolean = false;

  constructor(
    private api: JsonService,
    public assetService: AssetService,
    private toasterService: ToastrService
  ) {
    this.baseUrl = environment.apiUrl;
    this.token = localStorage.getItem('token')
  }

  downloadExport(documentAttribute) {
    this.isDownloadingXls = true;
    const fileExtension = 'xlsx';

    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    this.api.getExportAdnAsAdmin().subscribe(
      (blobby) => {
        this.isDownloadingXls = false;
        const objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = documentAttribute['filename'] + '.' + fileExtension;
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      },
      (err) => {
        this.isDownloadingXls = false;
        this.toasterService.warning('Une erreur est survenue lors du téléchargement');
        console.error(err);
      });
  }
}
