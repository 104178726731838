import {Component} from '@angular/core';
import {JsonService} from '../services/json.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {AssetService} from '../services/asset.service';
import {Marque} from '../models/marque.model';
import {MarqueService} from "../services/marque.service";

@Component({
  templateUrl: '../templates/security/password-forgotten.component.html'
})

export class PasswordForgottenComponent {

  title = 'angularfront';
  public marque: Marque = null;
  public ready: boolean = false;

  constructor(
    private api: JsonService,
    private router: Router,
    private toastr: ToastrService,
    public assetService: AssetService,
    private marqueService: MarqueService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.marque = null;
      this.ready = false;
      this.marqueService.getMarque().subscribe(response => {
        if (response instanceof Marque) {
          this.marque = response;
          this.ready = true;
          return this.marque;
        }
      });
    });
  }

  postForm(form) {
    this.api.postPasswordForgotten(form).subscribe(content => {
      if (typeof content['state'] !== 'undefined' && content['state'] === 'success') {
          this.toastr.success('Votre demande a été prise en compte, merci de vérifier votre boite email', '')
          this.router.navigate(['/login'])
      } else {
        this.toastr.error(content['error'], 'Erreur');
      }

    });
  }
}
