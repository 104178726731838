<div class="login-page">
  <div *ngIf="ready" class="container-login">

      <div class="card-login">

          <form class="login-form" method="post" (ngSubmit)="login(userForm.value)" #userForm="ngForm">

              <h3 style=" text-align: center">
                <img [src]="assetService.getLogo(marque.slug)" alt=""  >
              </h3>
              <div class="input-group mt-5">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-user"></i></span>
                  </div>
                  <input type="email" name="email" id="inputEmail" class="form-control" placeholder="Email..." aria-label="Email" required autofocus ngModel>
              </div>
              <div class="input-group password-div mt-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fas fa-lock"></i></span>
                  </div>
                  <input type="{{showPassword ? 'text' : 'password' }}" name="password" id="inputPassword" class="form-control" placeholder="Mot de passe..." aria-label="Mot de passe" required ngModel>
                  <a (click)="togglePassword()" class="password-eye-icon">
                    <i class="{{showPassword ? 'fas fa-eye' : 'fas fa-eye-slash' }}"></i>
                  </a>
              </div>
              <div class="form-group mt-4">
                <div style="margin: auto; width: fit-content; min-height:80px;">
                  <re-captcha
                    [siteKey]="googleRecaptchaSiteKey"
                    (resolved)="recaptchaSuccessResponse($event)"
                    (errored)="recaptchaErrorResponse($event)" errorMode="handled" #recaptchaRef
                  ></re-captcha>
                </div>
              </div>

              <div class="form-groupgroup mt-3">
                  <a routerLink="/password-forgotten" [ngClass]="[marque.slug, 'text-colored']">Mot de passe oublié ?</a>
              </div>
              <button type="submit" [disabled]="processing || !userForm.value?.email || !userForm.value?.password" [ngClass]="[marque.slug, 'btn', 'btn-lg', 'btn-block', 'mt-3']" style="font-size: 15px; font-weight: bold; color: white; width: 100%">{{ 'login'| uppercase }}</button>
              <div class="form-group__register" *ngIf="isSignupAllowed()">Pas encore membre?
                <a routerLink="/register" [ngClass]="[marque.slug, 'text-colored']">Inscrivez-vous</a>
            </div>

          </form>
      </div>
  </div>
</div>
<ns-cgu-footer></ns-cgu-footer>