<ns-navbar></ns-navbar>
<div id="body-container"  >
  <ns-sidebar sidebarType="admin"></ns-sidebar>
    <div id="content-wrapper">
        <tr class="container-fluid">
            <td class="row">
                <div class="col-md-10" class="welcome-dashboard" style="margin-top: 50px ; text-align: center">
                    <h1>Documents en attente de validation</h1>
                    <table class="table">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Entreprise</th>
                            <th>Document</th>
                            <th>Décision ?</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let document of documents; let i = index">
                            <td><a (click)="deleteDocument(document['id'], i)">Supprimer</a></td>
                            <td><strong>{{ document['user']['companyName'] }} - {{ document['user']['fullName'] }}</strong></td>
                            <td><a [href]="assetService.getUrl(document['path'])" target="_blank">
                                    {{ document['uid'] }}
                                </a></td>
                            <td>
                                <p><a (click)="reviewDocument(document['id'], 'valid', i)">Valider</a></p>
                                <p><a (click)="reviewDocument(document['id'], 'invalid', i)">Refuser</a></p>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>
                <div class="col-md-2"></div>
            </div>
        </div>

