import {SubCategory} from './subcategory.model';

export class Category {

    constructor(
        public name?: string,
        public slug?: string,
        public family?: string,
        public subCategories?: Array<SubCategory>,
        public score?: number,
        public scoreMax?: number,
        public pourcentage?: number,
        public pourcentagePondere?: number,
        public ptsPourcentageBonus?: number,
        public totalWeight?: number,
    ) {}
}
