import { AfterViewInit, Component } from '@angular/core';
import { JsonService } from "../services/json.service";
import { MarqueService } from "../services/marque.service"
import { Marque } from "../models/marque.model"
import { SubCategory } from "../models/subcategory.model"
import { PdfService } from '../services/pdf.service';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';

@Component({
  selector: 'ns-upgrades',
  templateUrl: '../templates/upgrades.component.html',
  styleUrls: ['../../assets/scss/pages/_upgrades.scss']
})

export class UpgradesComponent implements AfterViewInit {
  title = 'angularfront'

  public user: User;
  public catSelected: string;
  public content: any;
  public ready: boolean = false;
  public marque: Marque;
  public launchAnimation: boolean;
  public colorMarqueOnly: boolean = false;
  public downloadButtonState: 'download-bp-enabled' | 'download-bp-disabled' = 'download-bp-enabled';
  public isDownloading: boolean = false;

  private adn: any;

  constructor(
    private api: JsonService,
    private marqueService: MarqueService,
    private userService: UserService,
    private pdfService: PdfService,
  ) {
    this.catSelected = null;
    this.content = [];
  }

  ngOnInit(): void {
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response;
        if (this.marque.slug === 'grandlyon') {
          this.colorMarqueOnly = true
        }
      }
    })

    this.userService.getUser().subscribe(response => {
      if (response instanceof User) {
        this.user = response;
      }
    });

    this.api.getAdn().subscribe(data => {
      this.adn = data.adn;
    });
  }

  ngAfterViewInit(): void {
    this.getUpgrades();
  }

  getUpgrades() {
    if (this.marque.slug === 'paca') {
      this.content = {
        "recommendations": {
          "subCategories": [
            {
              "categoryName": "Gouvernance et Coop\u00e9rations",
              "categoryNameSlug": "gouvernance-et-cooperations",
              "subCategoryName": "Coop\u00e9rations",
              "pourcentage": 0,
              "family": "default",
              "missingPoints": 100,
              "recommendations": [
                {
                  "ref": "q60ff1805c15c5",
                  "messages": [
                    "Devenez partenaire de DEMO pour b\u00e9n\u00e9ficier d\u0027un accompangement personnalis\u00e9 en lien avec vos strat\u00e9gies carbone. Plus d\u0027infos ici : demo@gen-ethic.com",
                    "Participez \u00e0 des groupes de travail territoriaux ayant pour objectif de limiter l\u0027impact environnemental de ses membres"
                  ],
                  "externalLinks": []
                }
              ]
            },
            {
              "categoryName": "Activit\u00e9 \u00e0 impact positif",
              "categoryNameSlug": "activite-a-impact-positif",
              "subCategoryName": "Mission",
              "pourcentage": 0,
              "family": "default",
              "missingPoints": 100,
              "recommendations": [
                {
                  "ref": "q615b684da3159",
                  "messages": [
                    "Si vous avez formul\u00e9 une raison d\u0027\u00eatre, inscrivez la dans les statuts de l\u0027entreprise",
                    "Adoptez une raison d\u0027\u00eatre et inscrivez la dans les statuts de l\u0027entreprise",
                    "Adoptez le statut d\u0027Entreprise \u00e0 mission"
                  ],
                  "externalLinks": []
                }
              ]
            },
            {
              "categoryName": "Soutenabilit\u00e9 \u00e9conomique",
              "categoryNameSlug": "soutenabilite-economique",
              "subCategoryName": "Souverainet\u00e9 \u00e9conomique",
              "pourcentage": 4,
              "family": "default",
              "missingPoints": 96,
              "recommendations": [
                {
                  "ref": "q60ff1805c30c1",
                  "messages": [
                    "Veillez \u00e0 une mise \u00e0 jour syst\u00e9matique de tous les logiciels\/ syst\u00e8mes avec mise en place d\u2019un audit au moins une fois par an",
                    "Imposez une double authentification pour toute connexion \u00e0 distance",
                    "R\u00e9alisez des audits de s\u00e9curit\u00e9 informatique au moins une fois par an",
                    "Etablissez une proc\u00e9dure de gestion des droits (diff\u00e9rents niveaux de droits, r\u00e9vocation imm\u00e9diate en cas de d\u00e9part\/changement de droits)",
                    "Optez pour des sauvegardes compl\u00e8tes externalis\u00e9es, d\u00e9connect\u00e9es et test\u00e9es",
                    "Mettez en place un SOC (Centre op\u00e9rationnel de s\u00e9curit\u00e9), abritant une unit\u00e9 de s\u00e9curit\u00e9 charg\u00e9e de surveiller et d\u0027analyser en permanence le dispositif de s\u00e9curit\u00e9"
                  ],
                  "externalLinks": []
                }
              ]
            },
            {
              "categoryName": "Gouvernance et Coop\u00e9rations",
              "categoryNameSlug": "gouvernance-et-cooperations",
              "subCategoryName": "D\u00e9marche RSE",
              "pourcentage": 13,
              "family": "default",
              "missingPoints": 87,
              "recommendations": [
                {
                  "ref": "q60ff1805c47e0",
                  "messages": [
                    "Donnez des objectifs li\u00e9s enjeux sociaux et\/ou environnementaux \u00e0 certains de vos manageur\u00b7euse\u00b7s",
                    "Indexez une partie de la r\u00e9mun\u00e9ration du\u00b7de la dirigeant\u00b7e s sur la performance RSE de l\u0027entreprise",
                    "Indexez une partie de la r\u00e9mun\u00e9ration des employ\u00e9\u00b7e\u00b7s sur la performance RSE de l\u0027entreprise"
                  ],
                  "externalLinks": []
                },
                {
                  "ref": "q615cbd1185793",
                  "messages": [
                    "R\u00e9alisez un \u00e9tat des lieux de votre performance RSE",
                    "Mettez en place un plan d\u0027action RSE",
                    "Analysez la mat\u00e9rialit\u00e9 des enjeux li\u00e9s \u00e0 votre responsabilit\u00e9 soci\u00e9tale et suivez les indicateurs li\u00e9s \u00e0 vos enjeux mat\u00e9riels"
                  ],
                  "externalLinks": []
                }
              ]
            },
            {
              "categoryName": "Viabilit\u00e9 environnementale",
              "categoryNameSlug": "viabilite-environnementale",
              "subCategoryName": "Num\u00e9rique responsable",
              "pourcentage": 15,
              "family": "default",
              "missingPoints": 85,
              "recommendations": [
                {
                  "ref": "q615cbd1185093",
                  "messages": [
                    "Incitez vos collaborateurs \u00e0 supprimer r\u00e9guli\u00e8rement des emails \/ pi\u00e8ces jointes (inclus signature de mail sans image)",
                    "Menez des formations et\/ou des actions de sensibilisation \u00e0 la gestion \u00e9co-responsable des donn\u00e9es"
                  ],
                  "externalLinks": []
                },
                {
                  "ref": "q60ff1805c1389",
                  "messages": [
                    "Remettez votre mat\u00e9riel fonctionnel en \u00e9tat via une entreprise de l\u0027\u00e9conomie sociale et solidaire",
                    "Favorisez la vente ou le don des appareils usag\u00e9s aupr\u00e8s de la fili\u00e8re du r\u00e9emploi",
                    "R\u00e9duisez la fr\u00e9quence de renouvellement des \u00e9quipements et mettez en place une politique syst\u00e9matique de r\u00e9paration en cas de panne",
                    "Optez pour l\u0027\u00e9conomie de fonctionnalit\u00e9 (la location de mat\u00e9riel)",
                    "Optez pour des \u00e9quipements facilement r\u00e9parables",
                    "Favorisez l\u0027achat d\u0027\u00e9quipements num\u00e9riques reconditionn\u00e9s"
                  ],
                  "externalLinks": []
                }
              ]
            },
            {
              "categoryName": "Sant\u00e9 et bien \u00eatre au travail",
              "categoryNameSlug": "sante-et-bien-etre-au-travail",
              "subCategoryName": "Sant\u00e9 des salari\u00e9s",
              "pourcentage": 17,
              "family": "default",
              "missingPoints": 83,
              "recommendations": [
                {
                  "ref": "q618809a3c02d2",
                  "messages": [
                    "Mettez en place des programmes afin de pr\u00e9venir les blessures de nature ergonomiques ou posturales sur le lieu de travail",
                    "Mettez en place des programmes afin de pr\u00e9venir les risques psychiques, les risques li\u00e9s aux addictions (tabac, alcool, psychotrope...), aux troubles du sommeil ou de l\u2019attention, \u00e0 la gestion du stress ou aux maladies cardiovasculaires",
                    "Incitez vos employ\u00e9\u00b7e\u00b7s \u00e0 r\u00e9pondre \u00e0 une \u00e9valuation des risques de sant\u00e9"
                  ],
                  "externalLinks": []
                }
              ]
            },
            {
              "categoryName": "Sant\u00e9 et bien \u00eatre au travail",
              "categoryNameSlug": "sante-et-bien-etre-au-travail",
              "subCategoryName": "Avantages et comodit\u00e9s",
              "pourcentage": 17,
              "family": "default",
              "missingPoints": 83,
              "recommendations": [
                {
                  "ref": "q618809a3c0299",
                  "messages": [
                    "Offrez des contrats de pr\u00e9voyance collective (non pr\u00e9vus par des conventions collectives ou des accords de branche)",
                    "Offrez des titres-restaurant (Ticket Restaurant, Ch\u00e8que D\u00e9jeuner, Ch\u00e8que de Table, Pass Restaurant)",
                    "Encouragez l\u0027acc\u00e8s aux prestations et biens de nature culturelle en offrant des ch\u00e8ques culture",
                    "Accordez des r\u00e9ductions tarifaires pour des activit\u00e9s de loisir (salle de sport, cin\u00e9ma, etc.)",
                    "Attribuez des aides pour des activit\u00e9s de services \u00e0 la personne et de garde d\u2019enfant",
                    "Offrez des cadeaux et\/ou des bons d\u2019achat"
                  ],
                  "externalLinks": []
                }
              ]
            },
            {
              "categoryName": "Gouvernance et Coop\u00e9rations",
              "categoryNameSlug": "gouvernance-et-cooperations",
              "subCategoryName": "Implication des parties prenantes",
              "pourcentage": 17,
              "family": "default",
              "missingPoints": 83,
              "recommendations": [
                {
                  "ref": "q60ff1805c476c",
                  "messages": [
                    "Donnez la possibilit\u00e9 \u00e0 vos salari\u00e9.e.s de participer de fa\u00e7on volontaire \u00e0 la strat\u00e9gie RSE de l\u0027entreprise et\/ou de mener un projet en interne li\u00e9 \u00e0 la RSE",
                    "Impliquez vos salari\u00e9.e.s de fa\u00e7on transversale dans des projets inh\u00e9rents \u00e0 la strat\u00e9gie RSE de l\u0027entreprise",
                    "Impliquez vos salari\u00e9.e.s dans l\u0027\u00e9laboration de la strat\u00e9gie RSE de l\u0027entreprise"
                  ],
                  "externalLinks": []
                }
              ]
            }
          ]
        }
      };
      this.ready = true;
      setTimeout(() => { this.launchAnimation = true; }, 100);
      return;
    }
    this.api.getUpgrades().subscribe(content => {
      this.content = content;
      this.ready = true;
      setTimeout(() => { this.launchAnimation = true; }, 100);
    });
  }

  downloadUpgrades() {
    if (this.isDownloading) {
      return;
    }
    this.isDownloading = true;
    this.downloadButtonState = 'download-bp-disabled';
    this.pdfService.downloadPdf(this.api.getUpgradesPdf(), this.user.companyName, new Date(this.adn.dateSaved.date), 'RECOMMANDATIONS').finally(() => {
      this.isDownloading = false;
      this.downloadButtonState = 'download-bp-enabled';
    })
  }

  public calculateWidth(pourcentage) {
    if (this.launchAnimation) {
      return { width: pourcentage + '%' };
    }
  }

  getGradientClass(subCategory: object) {
    return !this.colorMarqueOnly ? 'bg-gradient-' + subCategory['categoryNameSlug'] : ''
  }

  hasExternalLink(recommendation, msg) {
    return !!this.getExternalLink(recommendation, msg)
  }

  getExternalLink(recommendation, msg) {
    return recommendation?.externalLinks?.[msg];
  }
}
