<ns-navbar></ns-navbar>
<div id="body-container">
  <!-- <ns-network-sidebar></ns-network-sidebar> -->
  <ns-sidebar sidebarType="admin"></ns-sidebar>
  <div id="content-wrapper">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="row justify-content-between m-2 mtopbot2">
            <div class="col-lg-8" [ngClass]="[marque.slug, 'reseau-resultat', 'text-colored']">
              <h1 class="titre_backend_reseau">Les évaluations de vos entreprises</h1>
              <p>{{ enterpriseCount }} entreprise.s trouvée.s</p>

              <div *ngIf="marque?.slug === 'grandlyon'">
                <p>
                  <span class="me-2">Exporter au format Excel : </span>
                  <a [routerLink]="[]" (click)="downloadExport(false, 0)" [style.cursor]="isDownloadingXls ? 'wait' : 'pointer'" class="me-3">Part 1</a>
                  <a [routerLink]="[]" (click)="downloadExport(false, 1)" [style.cursor]="isDownloadingXls ? 'wait' : 'pointer'">Part 2</a>
                </p>
                <p>
                  <span class="me-2">Exporter tout au format Excel : </span>
                  <a [routerLink]="[]" (click)="downloadExport(true, 0)" [style.cursor]="isDownloadingXls ? 'wait' : 'pointer'" class="me-3">Part 1</a>
                  <a [routerLink]="[]" (click)="downloadExport(true, 1)" [style.cursor]="isDownloadingXls ? 'wait' : 'pointer'">Part 2</a>
                </p>
              </div>
              <div *ngIf="marque?.slug !== 'grandlyon'">
                <p><a [routerLink]="[]" (click)="downloadExport()"
                [style.cursor]="isDownloadingXls ? 'wait' : 'pointer'">Exporter au format Excel</a></p>
              </div>
              
            </div>
            <div class="col-lg-4">
              <div class="d-flex justify-content-end">
                <span class="me-3 sort-style">Trier par </span>
                <div ngbDropdown class="d-inline-block">
                  <button [ngClass]="[marque.slug, 'btn btn-outline btn-inverted-white btn-reseau-2']"
                    id="dropdownMenuButton" ngbDropdownToggle>
                    <span id="ordering-title" class="ps-2 pe-3">{{ currentOrderWording }}</span>
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="updateOrdering('id', 'Date')">Date</button>
                    <button ngbDropdownItem (click)="updateOrdering('companyName', 'Entreprise')">Nom</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <form style="margin-left: 30px;; width: 380px;">
                <div class="input-group">
                  <div class="input-group-prepend network-home-searchbar-prepend">
                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                  </div>
                  <input type="text" class="form-control network-home-searchbar" placeholder="Rechercher un membre..."
                    [formControl]="searchFormControl">
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ns-spinner *ngIf="!isReady()"></ns-spinner>
      <div *ngIf="isReady()">
        <div *ngFor="let membre of membres" class="row">
          <div *ngFor="let questionnaireResponse of membre.questionnaireResponses" class="col-md-12">
            <div class="card reseau-card m-4 mb-2 shadow">
              <div class="reseau-card__company">
                {{ membre.companyName }}
              </div>

              <div class="reseau-card__body">
                <ng-container *ngIf="questionnaireResponse.completed">
                  {{ questionnaireResponse.dateSaved?.date | date:'dd/MM/YYYY' }}</ng-container>

                <span *ngIf="questionnaireResponse.completed" [ngClass]="[marque.slug, 'text-colored']"
                  style="margin-left:60%">{{
                  questionnaireResponse.adn.pourcentage | number: '1.0-0' }}/100</span>

                <ng-container *ngIf="!questionnaireResponse.completed">
                  Questionnaire non validé
                </ng-container>
              </div>

              <div class="reseau-card__buttons">
                <div class="me-2" *ngIf="questionnaireResponse.completed">
                  <a [routerLink]="['adn', membre?.id, questionnaireResponse.id]"
                    [ngClass]="[marque.slug, 'btn', 'btn-outline btn-inverted btn-reseau-2']"
                    ngbTooltip="Voir le détail">
                    <i class="far fa-eye"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-md-center mt-5">
          <ngb-pagination
          [(collectionSize)]="enterpriseCount"
          [(page)]="pageNumber"
          [(pageSize)]="pageSize"
          (pageChange)="getData()"
          [maxSize]="5"
          [rotate]="true"
          [boundaryLinks]="true"
          aria-label="Pagination"></ngb-pagination>
        </div>
      </div>

    </div>
  </div>
</div>