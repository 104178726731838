import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  constructor() { }

  downloadPdf(observable: Observable<Blob>, enterpriseName: string, evaludationDate: Date, fileSuffix: string) {
    return new Promise(function (resolve, reject) {
      const anchor = document.createElement('a');
      document.body.appendChild(anchor);

      observable.pipe(
        take(1)
      ).subscribe({
        next: (blobby) => {
          const objectUrl = window.URL.createObjectURL(blobby);
          const dateString = `${evaludationDate.getFullYear()}-${String(evaludationDate.getMonth() + 1).padStart(2, '0')}-${evaludationDate.getDate()}`; // yyyy-mm-dd

          anchor.href = objectUrl;
          anchor.download = `${dateString}-${enterpriseName.replace(/\s+/gi, '_')}-${fileSuffix}.pdf`;
          anchor.click();

          resolve(true);

          window.URL.revokeObjectURL(objectUrl);
        },
        error: (err) => {
          resolve(true);
        }
      });
    });
  }

}
