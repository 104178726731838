<div class="login-page">
  <div *ngIf="ready" class="container-login">

      <div class="card-login">

          <form class="login-form" method="post" (ngSubmit)="postForm(userForm.value)" #userForm="ngForm">

              <h3 style=" text-align: center">
                <img [src]="assetService.getLogo(marque.slug)" alt=""  >
              </h3>

              <h2 style="font-size:22px;text-align: center;margin-top: 24px;">Changement de mot de passe</h2>


              <div class="input-group password-div"  style="margin-top: 30px;">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-lock"></i></span>
                </div>
                <input type="{{showPassword ? 'text' : 'password' }}" name="define_password_form[plainPassword][first]" id="inputPassword"
                class="form-control pwd-form-ctrl" [ngClass]="{'is-valid': isPwdValid, 'is-invalid': pwdHasFocus && !isPwdValid}"
                (keyup)="onPasswordChanged(userForm.value)" (focus)='onPwdFocus()'
                placeholder="Nouveau mot de passe" aria-label="Mot de passe" required ngModel>
                <a (click)="togglePassword()" class="password-eye-icon">
                  <i class="{{showPassword ? 'fas fa-eye' : 'fas fa-eye-slash' }}"></i>
                </a>
              </div>
              <div class="ms-2 pwd-input-validation-bloc" [ngClass]="{'opened': pwdHasFocus && !isPwdValid }">
                <div class="mt-4"></div>
                <div class="register-password-indication">Le mot de passe doit contenir :</div>
                <div class="register-password-indication ms-3" [ngClass]="passwordChecks.minLength ? 'valid' : 'invalid'">
                  <i class="fas me-2" [ngClass]="passwordChecks.minLength ? 'fa-check' : 'fa-times'"></i>
                  {{ passwordMinLength }} caractères minimum
                </div>
                <div *ngIf="hasSpecialPasswordPolicy" class="register-password-indication ms-3" [ngClass]="passwordChecks.lowerChar ? 'valid' : 'invalid'">
                  <i class="fas me-2" [ngClass]="passwordChecks.lowerChar ? 'fa-check' : 'fa-times'"></i>
                  1 caractère en minuscule
                </div>
                <div *ngIf="hasSpecialPasswordPolicy" class="register-password-indication ms-3" [ngClass]="passwordChecks.upperChar ? 'valid' : 'invalid'">
                  <i class="fas me-2" [ngClass]="passwordChecks.upperChar ? 'fa-check' : 'fa-times'"></i>
                  1 caractère en majuscule
                </div>
                <div *ngIf="hasSpecialPasswordPolicy" class="register-password-indication ms-3" [ngClass]="passwordChecks.number ? 'valid' : 'invalid'">
                  <i class="fas me-2" [ngClass]="passwordChecks.number ? 'fa-check' : 'fa-times'"></i>
                  1 chiffre
                </div>
                <div *ngIf="hasSpecialPasswordPolicy" class="register-password-indication ms-3" [ngClass]="passwordChecks.specialChar ? 'valid' : 'invalid'">
                  <i class="fas me-2" [ngClass]="passwordChecks.specialChar ? 'fa-check' : 'fa-times'"></i>
                  1 caractère spécial
                </div>
                <div class="mb-2"></div>
              </div>

              <div class="input-group password-div"  style="margin-top: 30px;margin-bottom: 20px;">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-lock"></i></span>
                </div>
                <input type="{{showPassword ? 'text' : 'password' }}" name="define_password_form[plainPassword][second]" id="inputPassword"
                class="form-control pwd-form-ctrl" (focus)='onPwd2Focus()'
                [ngClass]="{'is-valid': pwd2HasFocus && userForm.value['define_password_form[plainPassword][first]'] === userForm.value['define_password_form[plainPassword][second]'], 'is-invalid': pwd2HasFocus && (!userForm.value['define_password_form[plainPassword][first]'] || userForm.value['define_password_form[plainPassword][first]'] !== userForm.value['define_password_form[plainPassword][second]'])}"
                placeholder="Répétez le mot de passe" aria-label="Mot de passe" required ngModel>
                <a (click)="togglePassword()" class="password-eye-icon">
                  <i class="{{showPassword ? 'fas fa-eye' : 'fas fa-eye-slash' }}"></i>
                </a>
              </div>

              <button type="submit" [disabled]="!isFormValid(userForm)" [ngClass]="[marque.slug, 'btn', 'btn-lg', 'btn-block', 'mt-3']" style="font-size: 15px; font-weight: bold; color: white; width: 100%">{{ 'demander une réinitialisation'| titlecase }}</button>
          </form>
      </div>
  </div>
</div>
<ns-cgu-footer></ns-cgu-footer>
