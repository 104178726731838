<ns-navbar></ns-navbar>
<div id="body-container"  >
  <ns-sidebar sidebarType="admin"></ns-sidebar>
  <div id="content-wrapper">
            <div class="survey-header">
                <h1>
                    {{ 'Export' | titlecase }}
                </h1>
            </div>
            <div class="survey-content">
              <button type="button" class="btn"
                [style.cursor]="isDownloadingXls ? 'wait' : 'pointer'"
                (click)="downloadExport({path: baseUrl+'/admin/export/adn.xlsx', filename: 'adn.xlsx'})" [disabled]="isDownloadingXls">Exporter tous les ADN (xls)</button>
            </div>
    </div>
</div>

