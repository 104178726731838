import { Component } from '@angular/core';
import {JsonService} from "../services/json.service";
import {Router} from "@angular/router"
import {ToastrService} from "ngx-toastr"
import {AssetService} from "src/app/services/asset.service";
import {MarqueService} from "../services/marque.service"
import {Marque} from "../models/marque.model"
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ns-documents',
  templateUrl: '../templates/backend/documents.component.html'
})

export class DocumentsComponent {
  title = 'angularfront'

  public form: object
  public formReady: boolean = false
  public marqueReady: boolean = false
  public allowedTypes: Array<string>
  public filesSelected: object = {}
  public formData : FormData
  public marque : Marque

  constructor (
    private api: JsonService,
    private router: Router,
    private toastr: ToastrService,
    public assetService: AssetService,
    private marqueService: MarqueService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.marqueReady = false
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response
        this.marqueReady = true
      }
    })
  }

  ngAfterViewInit(): void {
    this.filesSelected = {}
    this.formData = new FormData()
    this.getForm()
  }

  downloadDocument(documentAttribute) {
    const fileExtention = documentAttribute['data-path'].split('.').pop();

    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    this.api.getDocument(documentAttribute['data-path']).subscribe((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = documentAttribute['data-filename'] + '.' + fileExtention;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
  }

  getForm() {
    this.api.getDocuments().subscribe(content => {

      if (typeof content['form'] !== 'undefined') {
        this.allowedTypes = content['allowedTypes']
        this.form = Object.keys(content['form']['properties']).map(key => ({type: key, value: content['form']['properties'][key]}))
        this.formData = new FormData();
        this.filesSelected = {};
        this.formReady = true;
      } else {
        this.toastr.error('Erreur lors de la récupération de la page', 'Erreur')
      }
    });
  }

  postForm() {
    this.api.postDocuments(this.formData).subscribe(content => {
      if (typeof content['state'] !== 'undefined') {
          this.toastr.success('Le document à bien été importé', 'Merci !');
          this.getForm();
      } else {
        this.filesSelected = {};
        this.formData = new FormData();
        this.toastr.error(content['error'], 'Erreur');
      }

    });
  }

  deleteFile(confirmModal, userDocument) {
    this.modalService.open(
      confirmModal,
      { ariaLabelledBy: 'confirm-modal-title', centered: true }
    ).result.then((result) => {
      if (result !== 'yes') {
        return;
      }
      this.api.deleteUserDocument(userDocument['value']['attr']['id']).subscribe((response: any) => {
        if (response?.errors?.length === 0) {
          this.toastr.success('Le document à bien été supprimé', 'Merci !');
          this.getForm();
        } else {
          this.toastr.error(response['errors'][0], 'Erreur');
        }
      });
    }, (reason) => {});
  }

  fileSelected(event) {
    let fileName = event.target.value.split( '\\' ).pop().substr(0, 15);
    this.filesSelected[event.target.id] = fileName;
    this.formData.append('document['+event.target.id+']', event.target.files[0]);
    this.postForm();
  }

  isFileSelected(id) {
    if (Object.keys(this.filesSelected).includes(id)) {
      return `<span class="me-2"><i class="fas fa-spinner fa-spin"></i></span>${this.filesSelected[id]}`;
    }
    return '<span class="me-2"><i class="fas fa-file-upload"></i></span>Importer un fichier';
  }
}
