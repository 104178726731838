<div class="login-page">
    <div *ngIf="ready" class="container-login">
  
        <div class="card-login">
  
            <form class="login-form" method="post" (ngSubmit)="postForm(userForm.value)" #userForm="ngForm">
  
                <h3 style=" text-align: center">
                  <img [src]="assetService.getLogo(marque.slug)" alt=""  >
                </h3>

                <h2 style="font-size:22px;text-align: center;margin-top: 24px;">Récupération de mot de passe</h2>
                <div class="input-group" style="margin-top: 30px;margin-bottom: 20px;">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="fas fa-user"></i></span>
                    </div>
                    <input type="email" name="email" id="inputEmail" name="password_forgotten_form[email]" class="form-control" placeholder="Email..." aria-label="Email" required autofocus ngModel>
                </div>

                <button type="submit" [disabled]="!userForm.value?.['password_forgotten_form[email]']" [ngClass]="[marque.slug, 'btn', 'btn-lg', 'btn-block', 'mt-3']" style="font-size: 15px; font-weight: bold; color: white; width: 100%">{{ 'demander une réinitialisation'| titlecase }}</button>

                <p *ngIf="marque?.slug === 'grandlyon'" style="color:red; margin-top:20px; font-size: 10px;">
                    <span style="font-size: 14px; margin-right:8px;"><i class="fas fa-exclamation-triangle"></i></span>
                    Suite à un incident technique, certains comptes utilisateurs ont été supprimés.<br />
                    Si vous n'arrivez pas à créer un nouveau mot de passe ou à vous connecter à la plateforme,<br />
                    veuillez contacter notre équipe à l'adresse suivante : contact@gen-ethic.com</p>

                <div class="form-groupgroup mt-3">
                    <a routerLink="/login" [ngClass]="[marque.slug, 'text-colored']">Revenir à la connexion</a>
                </div>
            </form>
        </div>
    </div>
  </div>
  <ns-cgu-footer></ns-cgu-footer>
