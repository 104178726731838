import { Component, OnInit, ViewChild } from '@angular/core';
import {JsonService} from "../../services/json.service"
import {UserService} from "../../services/user.service"
import {User} from "../../models/user.model"
import {ActivatedRoute, ParamMap} from "@angular/router"
import { SlugifyPipe } from '../../pipes/slugify.pipe';
import { MarqueService } from '../../services/marque.service';
import { Marque } from '../../models/marque.model';
import { catchError, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ns-adn-network',
  templateUrl: '../../templates/backend/network/adn_network.component.html'
})
export class AdnNetworkComponent {
  public hasAdn: boolean;
  public adn: Array<any>;
  public user: User;
  public ready: boolean = false;
  public membreReseauId: number;
  public questionnaireResponseId: number;
  public sectorPositioning: number;
  public launchAnimation: boolean;
  public marque: Marque;
  public dateSaved: Date;

  constructor(
    private api: JsonService,
    private userService: UserService,
    private marqueService: MarqueService,
    private route: ActivatedRoute,
    private slugifyPipe: SlugifyPipe,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.membreReseauId = parseInt(params.get('membreReseauId'))
      this.questionnaireResponseId = parseInt(params.get('questionnaireResponseId'))
    })
  }

  ngAfterViewInit(): void {
    this.marqueService.getMarque().subscribe(response => {
      if (response instanceof Marque) {
        this.marque = response
      }
    });
    this.getData();
  }

  calculateWidth(pourcentage) {
    if (this.launchAnimation) {
      return { width: pourcentage + '%' };
    }
  }
  calculateCircleAnime(pourcentage) {
    if (this.launchAnimation) {
      return { 'stroke-dashoffset': 289.067- (289.067 * pourcentage)/100 +'px' };
    }
  }

  collapseExpand(categoryName: string, index: string) {
    const id = 'collapse_' + this.slugifyPipe.transform(categoryName) + '_' + index;
    document.getElementById(id).classList.toggle('custom-collapse-opened');
  }

  getData() {
    this.api.getNetworkUserById(this.membreReseauId).subscribe(data => {
      this.user = data as User;
    });

    this.api.getAdnByQuestionnaireResponseId(this.questionnaireResponseId)
    .subscribe(
      data => {
        this.adn = data.adn;
        this.sectorPositioning = data['sectorPositioning'] || 0;
        if (data.adn !== null) {
          this.hasAdn = data.adn['form'].length;
        } else {
          this.hasAdn = false;
        }
        this.ready = true;
        setTimeout(() => {
          this.launchAnimation = true;
        }, 100);
      },
      err => {
        this.toastr.warning('Le questionnaire n\'a pas pu être trouvé')
      }
    );

    this.api.getQuestionnaireDateSaved(this.questionnaireResponseId).pipe(take(1)).subscribe((res: any) => {
      if ('dateSaved' in res) {
        this.dateSaved = this.getDateFromDateSaved(res.dateSaved);
      }
    })
  }

  downloadExportExcel() {
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);

    this.api.getAdnFromNetworkUserExcelExport(this.user?.id).subscribe((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = `adn-${this.user?.companyName?.replace(/\s/g, '-')}.xlsx`;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
    });
  }

  downloadExportPdf() {
    const newTab = window.open();
    newTab.document.title = 'Chargement du Pdf...';
    this.api.getAdnFromNetworkUserPdfExport(this.user?.id).subscribe((blobby) => {
      newTab.location.href = window.URL.createObjectURL(blobby);
    });
  }

  private getDateFromDateSaved(dateSaved: any): Date {
    // qr.dateSaved has a weird format...
    if ('date' in dateSaved) {
      return new Date((dateSaved as any).date);
    }
    return new Date(dateSaved);
  }
}
