import {Component} from '@angular/core';
import {JsonService} from '../services/json.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {Marque} from '../models/marque.model';
import {AssetService} from '../services/asset.service';
import {MarqueService} from '../services/marque.service';

@Component({
  templateUrl: '../templates/security/password-forgotten-define.component.html'
})

export class PasswordForgottenDefineComponent {


  title = 'angularfront';
  public hash: string;
  public marque : Marque = null;
  public ready: boolean = false;
  public showPassword: boolean = false;

  hasSpecialPasswordPolicy: boolean = false;
  pwdHasFocus: boolean = false;
  pwd2HasFocus: boolean = false;
  passwordMinLength: number = 6;
  passwordChecks = {
    minLength: false,
    specialChar: false,
    lowerChar: false,
    upperChar: false,
    number: false
  };
  isPwdValid: boolean = false;

  constructor(
    private api: JsonService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public assetService: AssetService,
    private marqueService: MarqueService
  ) {}


  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.hash = params.get('hash');
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.marque = null;
      this.ready = false;
      this.marqueService.getMarque().subscribe(response => {
        if (response instanceof Marque) {
          this.marque = response;
          this.hasSpecialPasswordPolicy = ['lille', 'bordeaux', 'rennes', 'paca'].includes(this.marque.slug);
          this.passwordMinLength = this.marqueService.getMinPasswordLength(this.marque.slug);
          this.ready = true;
          return this.marque;
        }
      });
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  onPasswordChanged(formValues: any) {
    this.passwordChecks.minLength = formValues['define_password_form[plainPassword][first]']?.length >= this.passwordMinLength;
    this.passwordChecks.specialChar = !this.hasSpecialPasswordPolicy || (/[^\w]/g).test(formValues['define_password_form[plainPassword][first]']);
    this.passwordChecks.lowerChar = !this.hasSpecialPasswordPolicy || (/[a-z]/g).test(formValues['define_password_form[plainPassword][first]']);
    this.passwordChecks.upperChar = !this.hasSpecialPasswordPolicy || (/[A-Z]/g).test(formValues['define_password_form[plainPassword][first]']);
    this.passwordChecks.number = !this.hasSpecialPasswordPolicy || (/[0-9]/g).test(formValues['define_password_form[plainPassword][first]']);
    this.isPwdValid = Object.values(this.passwordChecks).every(b => b)
  }

  onPwdFocus() {
    this.pwdHasFocus = true;
  }
  onPwd2Focus() {
    this.pwd2HasFocus = true;
  }

  isFormValid(userForm): boolean {
    return userForm?.value?.['define_password_form[plainPassword][first]'] &&
      userForm.value?.['define_password_form[plainPassword][first]'] === userForm.value?.['define_password_form[plainPassword][second]'];
  }

  postForm(form) {
    this.api.postPasswordForgottenDefine(form, this.hash).subscribe(content => {
      if (typeof content['state'] !== 'undefined' && content['state'] === 'success') {
          this.toastr.success('Votre mot de passe a été modifié, vous pouvez maintenant vous connecter', '')
          this.router.navigate(['/login'])
      } else {
        this.toastr.error(content['error'], 'Erreur');
      }

    });
  }
}
