export class Choice {

    constructor(public ref?: string,
                public label?: string,
                public value?: number,
                public name?: string,
                public idHTML?: string,
                public bonusPourcentage?: number,
                public checked?: boolean
                ) {
    }
}
