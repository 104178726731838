import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router"
import { UserService } from "../services/user.service"
import { MarqueService } from '../services/marque.service';
import { Marque } from '../models/marque.model';

@Component({
  selector: 'ns-logout',
  templateUrl: '../templates/security/logout.component.html'
})

export class LogoutComponent implements OnInit {
  public lastUsername: string
  public errorMessage: string
  public token: string

  constructor(
    private router: Router,
    private userService: UserService,
    private marqueService: MarqueService
  ) {
  }

  ngOnInit() {
    this.marqueService.getMarque().subscribe((marque: Marque) => {
      localStorage.setItem('token', '');
      this.userService.logoutUser();
      const url = ['grandlyon', 'lille'].includes(marque?.slug || '') ? '/register' : '/login';
      this.router.navigate([url]);
    })
  }
}
