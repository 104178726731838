<ns-navbar></ns-navbar>
<div id="body-container">
  <ns-sidebar></ns-sidebar>
  <div id="content-wrapper" class="content-wrapper-with-actions overflow-visible" #questionnaireview>
    <div class="content-wrapper-actions" *ngIf="ready">
      <button [ngClass]="[marque.slug, 'btn', 'btn-inverted']" (click)="downloadQuestionnaireExcel()">
        Télécharger <i class="fas fa-download"></i>
      </button>
    </div>
    <ns-spinner *ngIf="!ready"></ns-spinner>
    <div class="survey-container container" *ngIf="ready">
      <div class="survey-nav mb-5" *ngIf="questionnaire?.categories.length > 1">
        <span
          *ngFor="let category of questionnaire?.categories; let categoryIndex = index"
          class="survey-nav__element {{ category.family ? 'domain-family-'+category.family : ''}}"
          [ngClass]="{'active': this.step.category === categoryIndex}"
          (click)="goToCategory(categoryIndex)"
        >
          <svg *ngIf="categoryIndex > 0" xmlns="http://www.w3.org/2000/svg" width="16" height="16" class="bi bi-chevron-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
          </svg>
          <span>{{category.name}}</span>
        </span>
      </div>
      <form (ngSubmit)="submit(questionnaireForm, true)" #questionnaireForm="ngForm">
        <div *ngFor="let category of questionnaire?.categories; let categoryIndex = index">
          <div class="survey {{ category.family ? 'domain-family-'+category.family : ''}} {{ category.name|slugify }}">
            <div *ngFor="let subCategory of category.subCategories; let subCategoryIndex = index">
              <div [style]="{'display':(step?.category !== categoryIndex || step?.subCategory !== subCategoryIndex) ? 'none' : 'block'}">
                <div class="survey-header">
                  <h1>
                    {{category.name}}
                    <small>
                      {{ subCategoryIndex + 1 }}/{{ category.subCategories.length }} - {{ subCategory.name }}
                    </small>
                  </h1>
                  <div class="survey-header-bottom">
                    <div class="step">
                      <span *ngFor="let subCategoryStep of category.subCategories; let stepIndex = index" [ngClass]="{'active' : (step?.subCategory >= stepIndex)}"></span>
                    </div>
                  </div>
                </div>
                <div class="survey-content">
                  <div class="form-group" *ngFor="let question of subCategory.questions; let questionIndex = index">
                    <div *ngIf="null !== question.description" class="survey-content__helper" (click)="showHelper(question.description)">
                      <i class="far fa-question-circle"></i>
                    </div>
                    <div class="number-question">
                      <span><label></label>{{ questionIndex + 1 }}.</span> {{ question.label }}
                    </div>
                    <div *ngFor="let choice of question.choices; let choiceIndex = index" [ngClass]="{'custom-checkbox': question.multiChoices, 'custom-radio': !question.multiChoices, 'custom-control': true}">
                      <input *ngIf="question.multiChoices" [id]="choice.idHTML" class="custom-control-input" type="checkbox" [name]="choice.name" [value]="choice.value" [ngModel]="question.userChoice[choiceIndex]"/>
                      <input *ngIf="!question.multiChoices" [id]="choice.idHTML" class="custom-control-input" type="radio" [name]="choice.name" [value]="choice.value" [(ngModel)]="question.userChoice[0]"/> <!--[(ngModel)]="question.userChoice"-->
                      <label class="custom-control-label" [for]="choice.idHTML">
                        {{ choice.label }}
                      </label>
                    </div>
                    <div *ngIf="question.isAcceptingComment">
                      <input type="{{question.commentType}}" [ngModel]="question.comment" [name]="'score['+category.slug+']['+subCategory.slug+']['+question.ref+'][comment]'" [value]="question.comment" class="custom-free-input" placeholder="{{question.userChoice?.length === 0 ? 'Entrez votre réponse ici' : 'Commentaire sur la question (facultatif)'}}">
                    </div>
                  </div>
                  <div class="survey-content-submit container text-center">
                    <a *ngIf="!sentSuccessfully" routerLink="/" [ngClass]="[marque.slug, 'text-colored', 'small', 'text-decoration-none', 'me-4']" style="vertical-align:sub;cursor:pointer;">Reprendre plus tard</a>
                    <button *ngIf="!atStart" type="button" class="btn btn-lg me-4" (click)="previousStep(questionnaireview, questionnaireForm)">Précédent</button>
                    <button *ngIf="!sentSuccessfully" [type]="reachEnd ? 'submit' : 'button'" class="btn btn-lg me-4" (click)="nextStep(questionnaireview, questionnaireForm)" [innerHTML]="nextStepLabel"></button>
                    <button *ngIf="sentSuccessfully" class="btn btn-lg">Questionnaire validé !</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #submitQuestionnaireFormModal let-modal>
  <div class="modal-body">
    <p>Attention, en validant votre questionnaire vous n'aurez plus la possibilité de le modifier par la suite.</p>
    <p>Êtes-vous sûr(e) de vouloir valider votre questionnaire afin d'obtenir votre diagnostic&nbsp;?</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn" (click)="modal.dismiss('cancel')">Non</button>
    <button type="button" class="btn btn-success" (click)="modal.close('submit questionnaire')">Oui</button>
  </div>
</ng-template>
