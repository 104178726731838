import {Component} from '@angular/core';
import {JsonService} from "../../../services/json.service";
import {Router} from "@angular/router"
import {ToastrService} from "ngx-toastr"
import {AssetService} from "../../../services/asset.service"
import {User} from "src/app/models/user.model";

@Component({
  selector: 'ns-admin-documents-review',
  templateUrl: './admin-modify-logo.component.html'
})

export class AdminModifyLogoComponent {

  title = 'angularfront'

  public users: Array<User>
  public selectedUser: User
  public selectedOption: number
  public formData : FormData
  public filesSelected: object

  constructor (private api: JsonService, private router: Router, private toastr: ToastrService, public assetService: AssetService) {
  }


  ngAfterViewInit(): void {
    this.getUsers()
    this.filesSelected = {}
    this.formData = new FormData()
  }

  getUsers() {
    this.api.getUsers().subscribe(content => {
      if (typeof content['users'] !== 'undefined') {
        this.users = content['users']
      }

    });
  }

  selectUser() {
    this.selectedUser = this.users.filter( (user) => user.id === this.selectedOption)[0]
  }

  fileSelected(event) {
    let fileName = event.target.value.split( '\\' ).pop().substr(0, 15);
    this.filesSelected[event.target.id] = fileName
    this.formData.append('modify_logo['+event.target.id+']', event.target.files[0])
  }

  postForm(form) {
    this.formData.set('modify_logo[users]', form['modify_logo[users]'])

    this.api.postAdminModifyLogo(this.formData).subscribe(content => {
      if (typeof content['state'] !== 'undefined') {
        this.toastr.success('Vos documents ont bien été mis à jour', 'Merci !');
        this.router.navigate(['/'])
      } else {
        this.toastr.error(content['error'], 'Erreur')
      }

    });
  }
  // deleteDocument(id: number) {
  //   if (!confirm('Supprimer le document ?')) {
  //     return
  //   }
  //
  //   this.api.deleteDocument(id).subscribe(content => {
  //     if (typeof content['state'] !== 'undefined' && content['state'] === 'success') {
  //       this.documents.splice(id, 1)
  //       this.toastr.success('Document supprimé', 'Succès')
  //     } else {
  //       this.toastr.error(content['error'], 'Erreur');
  //     }
  //
  //   });
  // }
  //
  // reviewDocument(id: number, decision: string) {
  //   this.api.reviewDocuments(id, decision).subscribe(content => {
  //     if (typeof content['state'] !== 'undefined' && content['state'] === 'success') {
  //       this.documents.splice(id, 1)
  //       this.toastr.success('Votre décision à été prise en compte', 'Succès')
  //     } else {
  //       this.toastr.error(content['error'], 'Erreur');
  //     }
  //
  //   });
  // }
}
