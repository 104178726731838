/**
 * Map to convert insee code to gen-ethic code
 * @see https://www.sirene.fr/sirene/public/variable/trancheEffectifsUniteLegale
 **/
export const InseeNbEmployeeCodeMap = {
  'NN': 'zero',
  '00': 'zero',
  '01': '1a2',
  '02': '3a5',
  '03': '6a9',
  '11': '10a19',
  '12': '20a49',
  '21': '50a99',
  '22': '100a199',
  '31': '200a249',
  '32': '250a499',
  '41': '500a999',
  '42': '1000a1999',
  '51': '2000a4999',
  '52': '5000a9999',
  '53': '10000plus'
} as const;

/**
 * Map to convert insee code to gen-ethic code
 * @see https://www.sirene.fr/sirene/public/variable/categorieJuridiqueUniteLegale
 **/
export const InseeStatusCodeMap = {
    // ESS (no code found)

    '3120': 'societe_commerciale',

    '6539': 'societe_civile',
    '6540': 'societe_civile',
    '6541': 'societe_civile',
    '6542': 'societe_civile',
    '6543': 'societe_civile',
    '6544': 'societe_civile',
    '6551': 'societe_civile',
    '6554': 'societe_civile',
    '6558': 'societe_civile',
    '6560': 'societe_civile',
    '6585': 'societe_civile',
    '6589': 'societe_civile',
    '6599': 'societe_civile',

    '1000': 'entreprise_individuelle',

    // EIRL (no code found)

    // EURL (no code found)

    // SA
    '5505': 'SA',
    '5510': 'SA',
    '5515': 'SA',
    '5522': 'SA',
    '5525': 'SA',
    '5530': 'SA',
    '5531': 'SA',
    '5532': 'SA',
    '5542': 'SA',
    '5543': 'SA',
    '5546': 'SA',
    '5547': 'SA',
    '5548': 'SA',
    '5551': 'SA',
    '5552': 'SA',
    '5553': 'SA',
    '5554': 'SA',
    '5555': 'SA',
    '5559': 'SA',
    '5560': 'SA',
    '5599': 'SA',
    '5605': 'SA',
    '5610': 'SA',
    '5615': 'SA',
    '5622': 'SA',
    '5625': 'SA',
    '5631': 'SA',
    '5632': 'SA',
    '5642': 'SA',
    '5643': 'SA',
    '5646': 'SA',
    '5648': 'SA',
    '5651': 'SA',
    '5652': 'SA',
    '5653': 'SA',
    '5654': 'SA',
    '5655': 'SA',
    '5659': 'SA',
    '5660': 'SA',
    
    // SARL
    '5410': 'SARL',
    '5415': 'SARL',
    '5422': 'SARL',
    '5426': 'SARL',
    '5430': 'SARL',
    '5431': 'SARL',
    '5432': 'SARL',
    '5442': 'SARL',
    '5443': 'SARL',
    '5451': 'SARL',
    '5453': 'SARL',
    '5454': 'SARL',
    '5455': 'SARL',
    '5459': 'SARL',
    '5460': 'SARL',
    '5499': 'SARL',

    // SAS
    '5710': 'SAS',

    // ESUS (no code found)

    // EA  (no code found)

    // ESAT (no code found)

    // SIAE (no code found)

    // association
    '7321': 'association',
    '7322': 'association',
    '7323': 'association',
    '9150': 'association',
    '9210': 'association',
    '9220': 'association',
    '9221': 'association',
    '9222': 'association',
    '9224': 'association',
    '9230': 'association',
    '9260': 'association',

    // collectivité
    '3210': 'collectivité',
    '4150': 'collectivité',
    '7225': 'collectivité',
    '7229': 'collectivité',
    '7378': 'collectivité',

    // fondation
    '9300': 'fondation',

    // mutuelle
    '8210': 'mutuelle',

    // SCOP
    '5458': 'SCOP',
    '5558': 'SCOP',
    '5658': 'SCOP',

    // SCIC (no code found)
} as const;
