import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Marque } from "../models/marque.model";
import { MarqueService } from "../services/marque.service";
import jwt_decode, { JwtPayload } from "jwt-decode";

/**
 * Redundancy with jwtinteceptor
 * Just provide a pre-rendering guard, to avoid blinks between the 
 * view rendering and the redirection to '/login' path
 */
@Injectable()
export class JwtGuard implements CanActivate {
  private marque: Marque;

  constructor(
    private router: Router,
    private marqueService: MarqueService
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean|UrlTree> {
    if (!this.marque) {
      this.marque = await this.marqueService.getMarque().toPromise() as Marque;
    }
    let jwt = localStorage.getItem('token');
    if (!this.isJwtValid(jwt)) {
      const url = ['grandlyon', 'lille'].includes(this.marque?.slug || '') ? '/register' : '/login';
      this.router.navigate([url]);
      return false;
    }
    return true;
  }

  isJwtValid(jwt: string | undefined) {
    if (jwt) {
      const tokenData = jwt_decode(jwt) as JwtPayload;
      if (Date.now() >= tokenData?.exp * 1000) {
        return false;
      }
      return true;
    }
    return false;
  }
}
