import { Injectable} from "@angular/core"
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http"
import {Observable, throwError} from "rxjs"
import {Router} from "@angular/router";
import {catchError} from "rxjs/operators";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let jwt = localStorage.getItem('token');
    if (jwt) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${jwt}`
        }
      });
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            const subdomain = window.location.hostname.split('.')?.shift();
            const isGrandLyon = subdomain === 'kelimpact';
            const isLille = subdomain === 'diag';
            if (isGrandLyon || isLille) {
              this.router.navigate(['/register']);
            } else {
              this.router.navigate(['/login'])
            }
          }
          return throwError(error)
        }
      })
    );
  }
}
