<div class="login-page" *ngIf="ready && !hasCustomRegister">
  <div class="container-login">

    <div class="card-login">

      <form class="login-form" method="post" (ngSubmit)="postForm(userForm.value)" #userForm="ngForm">
        <h3 style=" text-align: center">
          <img [src]="assetService.getLogo(marque.slug)" alt="">
        </h3>

        <div class="input-group mt-5">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-user"></i></span>
          </div>
          <input ngModel type="email" id="registration_form_email" name="registration_form[email]" [value]="email"
            required="required" class="form-control form-control" placeholder="Votre mail..." value="">
        </div>

        <div class="input-group mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-building"></i></span>
          </div>
          <input ngModel type="text" id="registration_form_companyName" name="registration_form[companyName]"
            required="required" class="form-control form-control" placeholder="Nom de votre entreprise...">
        </div>

        <div class="input-group password-div mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-lock"></i></span>
          </div>
          <input ngModel type="{{showPassword1 ? 'text' : 'password' }}" id="registration_form_plainPassword_first"
            name="registration_form[plainPassword][first]" placeholder="Mot de passe..." required="required"
            class="form-control pwd-form-ctrl" [ngClass]="{'is-invalid': pwdHasFocus && !isPwdValid}"
            (keyup)="onPasswordChanged(userForm.value)" (focus)='onPwdFocus()'>
          <a (click)="togglePassword1()" class="password-eye-icon">
            <i class="{{showPassword1 ? 'fas fa-eye' : 'fas fa-eye-slash' }}"></i>
          </a>
        </div>
        <div class="ms-2 pwd-input-validation-bloc" [ngClass]="{'opened': pwdHasFocus && !isPwdValid }">
          <div class="mt-2"></div>
          <div class="register-password-indication">Le mot de passe doit contenir :</div>
          <div class="register-password-indication ms-3" [ngClass]="passwordChecks.minLength ? 'valid' : 'invalid'">
            <i class="fas me-2" [ngClass]="passwordChecks.minLength ? 'fa-check' : 'fa-times'"></i>
            {{ passwordMinLength }} caractères minimum
          </div>
          <div *ngIf="hasSpecialPasswordPolicy" class="register-password-indication ms-3" [ngClass]="passwordChecks.lowerChar ? 'valid' : 'invalid'">
            <i class="fas me-2" [ngClass]="passwordChecks.lowerChar ? 'fa-check' : 'fa-times'"></i>
            1 caractère en minuscule
          </div>
          <div *ngIf="hasSpecialPasswordPolicy" class="register-password-indication ms-3" [ngClass]="passwordChecks.upperChar ? 'valid' : 'invalid'">
            <i class="fas me-2" [ngClass]="passwordChecks.upperChar ? 'fa-check' : 'fa-times'"></i>
            1 caractère en majuscule
          </div>
          <div *ngIf="hasSpecialPasswordPolicy" class="register-password-indication ms-3" [ngClass]="passwordChecks.number ? 'valid' : 'invalid'">
            <i class="fas me-2" [ngClass]="passwordChecks.number ? 'fa-check' : 'fa-times'"></i>
            1 chiffre
          </div>
          <div *ngIf="hasSpecialPasswordPolicy" class="register-password-indication ms-3" [ngClass]="passwordChecks.specialChar ? 'valid' : 'invalid'">
            <i class="fas me-2" [ngClass]="passwordChecks.specialChar ? 'fa-check' : 'fa-times'"></i>
            1 caractère spécial
          </div>
        </div>

        <div class="input-group password-div mt-3">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-lock"></i></span>
          </div>
          <input ngModel type="{{showPassword2 ? 'text' : 'password' }}" id="registration_form_plainPassword_second"
            name="registration_form[plainPassword][second]" placeholder="Répéter le mot de passe..." required="required"
            class="form-control pwd-form-ctrl" (focus)='onPwd2Focus()'
            [ngClass]="{'is-invalid': pwd2HasFocus && (!userForm.value['registration_form[plainPassword][first]'] || userForm.value['registration_form[plainPassword][first]'] !== userForm.value['registration_form[plainPassword][second]'])}">
          <a (click)="togglePassword2()" class="password-eye-icon">
            <i class="{{showPassword2 ? 'fas fa-eye' : 'fas fa-eye-slash' }}"></i>
          </a>
        </div>


        <div class="form-group mt-3">
          <div class="form-check">
            <input ngModel type="checkbox" id="registration_form_agreeTerms" name="registration_form[agreeTerms]"
              required="required" class="form-check-input" value="1">
            <label class="form-check-label required" for="registration_form_agreeTerms"><a
                [ngClass]="[marque.slug, 'text-colored']" [href]="cguFilePath" target="_blank">J'accepte les conditions
                générales d'utilisation</a></label>
          </div>
        </div>
        <div class="form-group mt-3">
          <div style="margin: auto; width: fit-content;">
            <re-captcha [siteKey]="googleRecaptchaSiteKey" (resolved)="recaptchaSuccessResponse($event)"></re-captcha>
          </div>
        </div>

        <button type="submit" [disabled]="processing" [ngClass]="[marque.slug, 'btn', 'btn-lg', 'btn-block', 'mt-3']"
          style="font-size: 15px; font-weight: bold; color: white; width: 100%">{{ 'créer mon compte'| uppercase }}</button>
        <div class="form-group__register">Déjà inscrit?
          <a routerLink="/login" [ngClass]="[marque.slug, 'text-colored']">Login</a>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- GrandLyon Register Page-->
<div *ngIf="ready && hasCustomRegister">
  <div class="page-content">
    <div class="row logo-header-row">
      <div class="col logo-container">
        <img [src]="assetService.getLogo(marque.slug)" class="logo-register-grdlyon-left" alt="logo-kelimpact">
      </div>
      <div class="col logo-container logo-container-right">
        <img *ngIf="marque.slug === 'grandlyon'" src="/assets/images/medias/logo-grandlyon-left.png" class="logo-register-grdlyon-right" alt="logo-grandlyon">
      </div>
    </div>

    <div class="row main-content">
      <div class="col">
        <div class="content-col-left">
          <p class="default-txt">{{getCustomMainText()}}</p>
          <h1>Une plateforme<br/>gratuite & confidentielle</h1>
          <h3 class="info-step">UNE DÉMARCHE EN 4 ÉTAPES :</h3>
          <p class="info-step default-txt">{{getCustomStep1()}}</p>
          <p class="info-step default-txt">{{getCustomStep2()}}</p>
          <p class="info-step default-txt">{{getCustomStep3()}}</p>
          <p class="info-step default-txt">{{getCustomStep4()}}</p>
        </div>
      </div>

      <div class="col">
        <div class="content-col-right">
          <form class="bg-gray" method="post" (ngSubmit)="postForm(userForm.value)" #userForm="ngForm" style="position: relative;">
            <p class="register-text">Inscrivez-vous !</p>
            <img *ngIf="marque?.slug === 'grandlyon'" [src]="getCustomLeftImg()" class="img-register-right" alt="register-grandlyon">
            <div class="row">
              <div class="col-lg col-md-12 ps-0 mt-2">
                <input ngModel type="email" id="registration_form_email" name="registration_form[email]" [value]="email"
                  required="required" class="form-control custom-form-input" placeholder="Votre mail..." value="">
              </div>
              <div class="col-lg col-md-12 ps-0 mt-2">
                <input ngModel type="text" id="registration_form_companyName" name="registration_form[companyName]"
                  required="required" class="form-control custom-form-input" placeholder="Nom de votre entreprise...">
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg col-md-12 ps-0 mt-2">
                <input ngModel type="{{showPassword1 ? 'text' : 'password' }}" id="registration_form_plainPassword_first"
                  name="registration_form[plainPassword][first]" placeholder="Mot de passe..." required="required"
                  class="form-control custom-form-input pwd-form-ctrl" [ngClass]="{'is-invalid': pwdHasFocus && !isPwdValid}"
                  (keyup)="onPasswordChanged(userForm.value)" (focus)='onPwdFocus()'>
              </div>
              <div class="col-lg col-md-12 ps-0 mt-2">
                <input ngModel type="{{showPassword2 ? 'text' : 'password' }}" id="registration_form_plainPassword_second"
                  name="registration_form[plainPassword][second]" placeholder="Répéter le mot de passe..."
                  required="required" class="form-control custom-form-input pwd-form-ctrl" (focus)='onPwd2Focus()'
                  [ngClass]="{'is-invalid': pwd2HasFocus && (!userForm.value['registration_form[plainPassword][first]'] || userForm.value['registration_form[plainPassword][first]'] !== userForm.value['registration_form[plainPassword][second]'])}">
              </div>
            </div>
            <div class="pwd-input-validation-bloc" [ngClass]="{'opened': pwdHasFocus && !isPwdValid }" style="margin-top:-10px;margin-bottom:10px;">
              <div class="register-password-indication">Le mot de passe doit contenir :</div>
              <div class="register-password-indication ms-3" [ngClass]="passwordChecks.minLength ? 'valid' : 'invalid'">
                <i class="fas me-2" [ngClass]="passwordChecks.minLength ? 'fa-check' : 'fa-times'"></i>
                {{ passwordMinLength }} caractères minimum
              </div>
              <div *ngIf="hasSpecialPasswordPolicy" class="register-password-indication ms-3" [ngClass]="passwordChecks.lowerChar ? 'valid' : 'invalid'">
                <i class="fas me-2" [ngClass]="passwordChecks.lowerChar ? 'fa-check' : 'fa-times'"></i>
                1 caractère en minuscule
              </div>
              <div *ngIf="hasSpecialPasswordPolicy" class="register-password-indication ms-3" [ngClass]="passwordChecks.upperChar ? 'valid' : 'invalid'">
                <i class="fas me-2" [ngClass]="passwordChecks.upperChar ? 'fa-check' : 'fa-times'"></i>
                1 caractère en majuscule
              </div>
              <div *ngIf="hasSpecialPasswordPolicy" class="register-password-indication ms-3" [ngClass]="passwordChecks.number ? 'valid' : 'invalid'">
                <i class="fas me-2" [ngClass]="passwordChecks.number ? 'fa-check' : 'fa-times'"></i>
                1 chiffre
              </div>
              <div *ngIf="hasSpecialPasswordPolicy" class="register-password-indication ms-3" [ngClass]="passwordChecks.specialChar ? 'valid' : 'invalid'">
                <i class="fas me-2" [ngClass]="passwordChecks.specialChar ? 'fa-check' : 'fa-times'"></i>
                1 caractère spécial
              </div>
              <div class="mb-2"></div>
            </div>


            <div class="row mt-2">
              <div class="col-lg-6 col-md-12 ps-0 agree-term-container">
                <input ngModel type="checkbox" id="registration_form_agreeTerms" name="registration_form[agreeTerms]"
                  required="required" class="form-check-input" value="1">
                <label class="form-check-label default-txt required" for="registration_form_agreeTerms">
                  <a [ngClass]="[marque.slug, 'text-colored']" [href]="cguFilePath" target="_blank">
                    <span class="default-txt">J'accepte les conditions générales d'utilisation</span>
                  </a>
                </label>
              </div>
              <div class="col-lg-6 col-md-12 ps-0">
                <div class="gcaptcha-container">
                  <re-captcha [siteKey]="googleRecaptchaSiteKey" (resolved)="recaptchaSuccessResponse($event)"></re-captcha>
                </div>
              </div>
            </div>
            <div class="row">
              <button type="submit" [disabled]="processing" [ngClass]="[marque.slug, 'btn', 'btn-lg', 'btn-block']"
              style="font-size: 15px; font-weight: bold; color: white;" class="register-bp">Créer mon compte</button>
            </div>
            <div class="row">
              <a class="default-txt login-link" routerLink="/login">Déjà engagé, connectez-vous à votre espace personnel !</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  <div class="row footer-row">
    <div class="col-lg col-md-12 footer-col-left">
      <a [href]="getAccelerateLink()" target="_blank">
        <span class="default-txt">{{getFooterAccelerateLinkText()}}</span>
        <span style="margin-left: 6px;"><i class="fas fa-long-arrow-alt-right"></i></span>
      </a>
    </div>
    <div class="col-lg col-md-12 footer-col-right">
      <a [href]="getMadeByCreditLink()" target="_blank">
        <img [src]="getPlusIconPath()" class="img-footer-plus" alt="made-by-grand-lyon">
        <span class="default-txt">Mise à disposition par la {{getMadeByCreditsTitle()}}</span>
      </a>
    </div>
  </div>

</div>
<ns-cgu-footer></ns-cgu-footer>